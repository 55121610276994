import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions/index";
import * as API from "../api";
import Helper from "../../helpers/helpers";

export function* loadLorryList() {
  yield takeEvery(actionTypes.LOAD_LORRY_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LOADLORRYLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadLorryList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadLorryListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadLorryListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadLorryListFail());
    }
  });
}

export function* addLorry() {
  yield takeEvery(actionTypes.ADD_LORRY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDLORRY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addLorry(action.data);
      if (response.data.success) {
        yield put(actions.addLorrySuccess());
        
        if(response.data.message)
          yield put(actions.toastSuccess(response.data.message));
        else
          yield put(actions.toastSuccess("New Lorry type created successfully!"));

      } else {
        alert(response.data.message);
        yield put(actions.addLorryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addLorryFail());
    }
  });
}

export function* editLorry() {
  yield takeEvery(actionTypes.EDIT_LORRY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITLORRY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editLorry(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editLorrySuccess());
        
        if(response.data.message)
          yield put(actions.toastSuccess(response.data.message));
        else
          yield put(actions.toastSuccess("Lorry type updated successfully!"));

      } else {
        alert(response.data.message);
        yield put(actions.editLorryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editLorryFail());
    }
  });
}


export function* deleteLorry() {
  yield takeEvery(actionTypes.DELETE_LORRY, function*(action) {

    if(!action.data) action.data = {}
    
    let track_no = Helper.generateTrackNo("DELLORRY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteLorry(action.id, action.data);
      if (response.data.success) {
        yield put(actions.deleteLorrySuccess());
        
        if(response.data.message)
          yield put(actions.toastSuccess(response.data.message));
        else
          yield put(actions.toastSuccess("Dumpster deleted."));
          
      } else {
        alert(response.data.message);
        yield put(actions.deleteLorryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteLorryFail());
    }
  });
}

export function* driverLorryCount() {
  yield takeEvery(actionTypes.DRIVER_LORRY_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LORRYCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.driverLorryCount(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.driverLorryCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.driverLorryCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverLorryCountFail());
    }
  });
}


export function* loadDriverLorryList() {
  yield takeEvery(actionTypes.DRIVER_LORRY_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""
    let status = (action.data.status) ? action.data.status : ""
    let service_state = (action.data.service_state) ? action.data.service_state : ""
    let lorry_type = (action.data.lorry_type) ? action.data.lorry_type : ""

    let track_no = Helper.generateTrackNo(`LORRYLIST${status}${search}${service_state}${lorry_type}`);
    
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverLorryList(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverLorryListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverLorryListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverLorryListFail());
    }
  });
}


export function* driverLorryDetail() {
  yield takeEvery(actionTypes.DRIVER_LORRY_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo(`LORRYDETAIL`);
    
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.driverLorryDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.driverLorryDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.driverLorryDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverLorryDetailFail());
    }
  });
}

export function* approveDriverLorry() {
  yield takeEvery(actionTypes.DRIVER_LORRY_APPROVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPROVELORRY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.approveDriverLorry(action.id, action.data);
      if (response.data.success) {
        yield put(actions.approveDriverLorrySuccess());
        
        if(response.data.message)
          yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.approveDriverLorryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.approveDriverLorryFail());
    }
  });
}

export function* rejectDriverLorry() {
  yield takeEvery(actionTypes.DRIVER_LORRY_REJECT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTLORRY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.rejectDriverLorry(action.id, action.data);
      if (response.data.success) {
        yield put(actions.rejectDriverLorrySuccess());
        
        if(response.data.message)
          yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.rejectDriverLorryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.rejectDriverLorryFail());
    }
  });
}

export function* createDriverLorry() {
  yield takeEvery(actionTypes.DRIVER_LORRY_CREATE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CREATEDRIVERLORRY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.createDriverLorry(action.id, action.data);
      if (response.data.success) {
        yield put(actions.createDriverLorrySuccess());
        
        if(response.data.message)
          yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.createDriverLorryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.createDriverLorryFail());
    }
  });
}

export function* removeDriverLorry() {
  yield takeEvery(actionTypes.DRIVER_LORRY_REMOVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REMOVEDRIVERLORRY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.removeDriverLorry(action.id, action.data);
      if (response.data.success) {
        yield put(actions.removeDriverLorrySuccess());
        
        if(response.data.message)
          yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.removeDriverLorryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeDriverLorryFail());
    }
  });
}

export function* loadDriverLorrySummary() {
  
  yield takeEvery(actionTypes.LOAD_DRIVER_LORRY_SUMMARY, function*(action) {
    
    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LORRYSUMMARY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverLorrySummary(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverLorrySummarySuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverLorrySummaryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverLorrySummaryFail());
    }
  });
}

export default function* lorrySaga() {
  
  yield all([
    fork(loadLorryList),
    fork(addLorry),
    fork(editLorry),
    fork(deleteLorry),
    fork(driverLorryCount),
    fork(loadDriverLorryList),
    fork(driverLorryDetail),
    fork(approveDriverLorry),
    fork(rejectDriverLorry),
    fork(createDriverLorry),
    fork(removeDriverLorry),
    fork(loadDriverLorrySummary)
    
  ]);

}
