import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const cutOffBaseURL = () => {
  return APIHost() + "/admin/payment/cutOff";
};

export const withdrawBaseURL = () => {
  return APIHost() + "/admin/payment/withdraw";
};

export const walletBaseURL = () => {
  return APIHost() + "/admin/payment/wallet";
};

export const loadDriverCutOff = data => {
  const url = `${cutOffBaseURL()}/load`;
  return axios.post(url, data);
};

export const  driverCutOffDetail = (id ,data) => {
  const url = `${cutOffBaseURL()}/load/${id}`;
  return axios.post(url, data);
};

export const  getReimburseReport = (id ,data) => {
  const url = `${cutOffBaseURL()}/reimburse/${id}`;
  return axios.post(url, data);
};

export const driverCutOffApprove = (id ,data) => {
  const url = `${cutOffBaseURL()}/approve/${id}`;
  return axios.post(url, data);
};

export const driverCutOffCount = data => {
  const url = `${cutOffBaseURL()}/count`;
  return axios.post(url, data);
};

export const driverCutOffUpdateReimburse = (id,trans,data) => {
  const url = `${cutOffBaseURL()}/reimburse/update/${id}/${trans}`;
  return axios.post(url, data);
};


/* ===== USER WITHDRAWAL  ===== */
export const loadWithdrawRequest = data => {
  const url = `${withdrawBaseURL()}/load`;
  return axios.post(url, data);
};

export const withdrawRequestDetail = (id ,data) => {
  const url = `${withdrawBaseURL()}/load/${id}`;
  return axios.post(url, data);
};

export const withdrawRequestUpdateStatus = (id,data) => {
  const url = `${withdrawBaseURL()}/update/${id}`;
  return axios.post(url, data);
};

export const withdrawRequestCount = data => {
  const url = `${withdrawBaseURL()}/count`;
  return axios.post(url, data);
};

export const withdrawRequestGenerateWDBatchFile = (id = null, data) => {
  let url = `${withdrawBaseURL()}/generate`;
  if(id)
    url += `/${id}`

  return axios.post(url, data);
};

export const withdrawRequestSetPendingWDToProcessing = data => {
  const url = `${withdrawBaseURL()}/updateProcess`;
  return axios.post(url, data);
};

export const withdrawRequestUploadRespondBatchFile = data => {
  const url = `${withdrawBaseURL()}/respondBankFile`;
  return axios.post(url, data);
};


export const getBatchFile = (data) => {
  const url = `${withdrawBaseURL()}/download/batch`;
  return axios.post(url, data);
};

export const getResponseFile = (data) => {
  const url = `${withdrawBaseURL()}/download/bankResponse`;
  return axios.post(url, data);
};



/* ===== WALLET TRANSACTION ===== */
export const loadWalletTransactionCount = (data) => {
  var url = `${walletBaseURL()}/transaction/count`;
  return axios.post(url,data);
};

export const loadWalletBalance = (id ,data) => {
  const url = `${walletBaseURL()}/balance/${id}`;
  return axios.post(url, data);
};

export const loadWalletTransaction = data => {
  const url = `${walletBaseURL()}/transaction/load`;
  return axios.post(url,data);
};

export const loadWalletAccountList = data => {
  const url = `${walletBaseURL()}/account/list`;
  return axios.post(url,data);
};

export const loadWalletAccountCount = data => {
  const url = `${walletBaseURL()}/account/count`;
  return axios.post(url,data);
};

export const updateWalletVerifyStatus = (id, data) => {
  const url = `${walletBaseURL()}/verify/${id}`;
  return axios.post(url,data);
};

export const walletVerifyUploadDoc = (id, data) => {
  const url = `${walletBaseURL()}/uploadDoc/${id}`;
  return axios.post(url,data);
};

export const updateWalletAllowCredited = (id, data) => {
  const url = `${walletBaseURL()}/allowCredited/${id}`;
  return axios.post(url,data);
};

/* ===== END WALLET TRANSACTION ===== */


/* ===== DRIVER PENDING PAYMENT ===== */
export const loadDriverPendingPayment = data => {
  const url = `${walletBaseURL()}/payToDriver/load`;
  return axios.post(url,data);
};

export const approveDriverPayment = (id, data) => {
  const url = `${walletBaseURL()}/payToDriver/approve/${id}`;
  return axios.post(url,data);
};
/* ===== END DRIVER PENDING PAYMENT ===== */