import * as actionTypes from "./actionTypes";

export const loadDriverCutOff = data => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_LOAD,
    data
  };
};

export const loadDriverCutOffSuccess = data => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_LOAD_SUCCESS,
    data
  };
};

export const loadDriverCutOffFail = () => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_LOAD_FAIL
  };
};


export const driverCutOffDetail = (id, data) => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_DETAIL,
    id,
    data
  };
};

export const driverCutOffDetailSuccess = data => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_DETAIL_SUCCESS,
    data
  };
};

export const driverCutOffDetailFail = () => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_DETAIL_FAIL
  };
};


export const getReimburseReport = (id, data) => {
  return {
    type: actionTypes.GET_REIMBURSE_REPORT,
    id,
    data
  };
};

export const getReimburseReportSuccess = data => {
  return {
    type: actionTypes.GET_REIMBURSE_REPORT_SUCCESS,
    data
  };
};

export const getReimburseReportFail = () => {
  return {
    type: actionTypes.GET_REIMBURSE_REPORT_FAIL
  };
};



export const driverCutOffApprove = (id, data) => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_APPROVE,
    id,
    data
  };
};

export const driverCutOffApproveSuccess = () => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_APPROVE_SUCCESS
  };
};

export const driverCutOffApproveFail = () => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_APPROVE_FAIL
  };
};



export const driverCutOffCount = (data) => {
  
  return {
    type: actionTypes.DRIVER_CUT_OFF_COUNT,
    data
  };
};

export const driverCutOffCountSuccess = data => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_COUNT_SUCCESS,
    data
  };
};

export const driverCutOffCountFail = () => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_COUNT_FAIL
  };
};


export const driverCutOffUpdateReimburse = (id,trans,data) => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_UPDATE_REIMBURSE,
    data,
    trans,
    id
  };
};

export const driverCutOffUpdateReimburseSuccess = () => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_UPDATE_REIMBURSE_SUCCESS
  };
};

export const driverCutOffUpdateReimburseFail = () => {
  return {
    type: actionTypes.DRIVER_CUT_OFF_UPDATE_REIMBURSE_FAIL
  };
};


/* ===== USER WITHDRAWAL  ===== */

export const loadWithdrawRequest = data => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_LOAD,
    data
  };
};

export const loadWithdrawRequestSuccess = data => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_LOAD_SUCCESS,
    data
  };
};

export const loadWithdrawRequestFail = () => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_LOAD_FAIL
  };
};




export const withdrawRequestDetail = (id, data) => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_DETAIL,
    id,
    data
  };
};

export const withdrawRequestDetailSuccess = data => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_DETAIL_SUCCESS,
    data
  };
};

export const withdrawRequestDetailFail = () => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_DETAIL_FAIL
  };
};


export const withdrawRequestUpdateStatus = (id,data) => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_UPDATE_STATUS,
    data,
    id
  };
};

export const withdrawRequestUpdateStatusSuccess = () => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_UPDATE_STATUS_SUCCESS
  };
};

export const withdrawRequestUpdateStatusFail = () => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_UPDATE_STATUS_FAIL
  };
};



export const withdrawRequestCount = (data) => {
  
  return {
    type: actionTypes.WITHDRAW_REQUEST_COUNT,
    data
  };
};

export const withdrawRequestCountSuccess = data => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_COUNT_SUCCESS,
    data
  };
};

export const withdrawRequestCountFail = () => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_COUNT_FAIL
  };
};



export const withdrawRequestGenerateWDBatchFile = (id, data) => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE,
    id,
    data
  };
};

export const withdrawRequestGenerateWDBatchFileSuccess = () => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE_SUCCESS
  };
};

export const withdrawRequestGenerateWDBatchFileFail = () => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE_FAIL
  };
};



export const withdrawRequestSetPendingWDToProcessing = (data) => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_UPDATE_TO_PROCESSING,
    data
  };
};

export const withdrawRequestSetPendingWDToProcessingSuccess = data => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_UPDATE_TO_PROCESSING_SUCCESS,
    data
  };
};

export const withdrawRequestSetPendingWDToProcessingFail = () => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_UPDATE_TO_PROCESSING_FAIL
  };
};



export const withdrawRequestUploadRespondBatchFile = data => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_RESPOND_BATCH_FILE,
    data
  };
};

export const withdrawRequestUploadRespondBatchFileSuccess = () => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_RESPOND_BATCH_FILE_SUCCESS
  };
};

export const withdrawRequestUploadRespondBatchFileFail = () => {
  return {
    type: actionTypes.WITHDRAW_REQUEST_RESPOND_BATCH_FILE_FAIL
  };
};




export const getBatchFile = data => {
  return {
    type: actionTypes.GET_BATCH_FILE,
    data
  };
};

export const getBatchFileSuccess = () => {
  return {
    type: actionTypes.GET_BATCH_FILE_SUCCESS
  };
};

export const getBatchFileFail = () => {
  return {
    type: actionTypes.GET_BATCH_FILE_FAIL
  };
};



export const getResponseFile = data => {
  return {
    type: actionTypes.GET_BANK_RESPONSE_FILE,
    data
  };
};

export const getResponseFileSuccess = () => {
  return {
    type: actionTypes.GET_BANK_RESPONSE_FILE_SUCCESS
  };
};

export const getResponseFileFail = () => {
  return {
    type: actionTypes.GET_BANK_RESPONSE_FILE_FAIL
  };
};

/* ===== WALLET TRANSACTION ===== */
export const loadWalletTransactionCount = (data) => {
  return {
    type: actionTypes.WALLET_TRANSACTION_COUNT,
    data
  };
};

export const loadWalletTransactionCountSuccess = data => {
  return {
    type: actionTypes.WALLET_TRANSACTION_COUNT_SUCCESS,
    data
  };
};

export const loadWalletTransactionCountFail = () => {
  return {
    type: actionTypes.WALLET_TRANSACTION_COUNT_FAIL
  };
};



export const loadWalletBalance = (id, data) => {
  return {
    type: actionTypes.LOAD_WALLET_BALANCE,
    id,
    data
  };
};

export const loadWalletBalanceSuccess = data => {
  return {
    type: actionTypes.LOAD_WALLET_BALANCE_SUCCESS,
    data
  };
};

export const loadWalletBalanceFail = () => {
  return {
    type: actionTypes.LOAD_WALLET_BALANCE_FAIL
  };
};

export const loadWalletTransaction = data => {
  return {
    type: actionTypes.LOAD_WALLET_TRANSACTION,
    data
  };
};

export const loadWalletTransactionSuccess = data => {
  return {
    type: actionTypes.LOAD_WALLET_TRANSACTION_SUCCESS,
    data
  };
};

export const loadWalletTransactionFail = () => {
  return {
    type: actionTypes.LOAD_WALLET_TRANSACTION_FAIL
  };
};



export const loadWalletAccountList = data => {
  return {
    type: actionTypes.LOAD_WALLET_ACCOUNT_LIST,
    data
  };
};

export const loadWalletAccountListSuccess = data => {
  return {
    type: actionTypes.LOAD_WALLET_ACCOUNT_LIST_SUCCESS,
    data
  };
};

export const loadWalletAccountListFail = () => {
  return {
    type: actionTypes.LOAD_WALLET_ACCOUNT_LIST_FAIL
  };
};



export const loadWalletAccountCount = data => {
  return {
    type: actionTypes.LOAD_WALLET_ACCOUNT_COUNT,
    data
  };
};

export const loadWalletAccountCountSuccess = data => {
  return {
    type: actionTypes.LOAD_WALLET_ACCOUNT_COUNT_SUCCESS,
    data
  };
};

export const loadWalletAccountCountFail = () => {
  return {
    type: actionTypes.LOAD_WALLET_ACCOUNT_COUNT_FAIL
  };
};


export const updateWalletVerifyStatus = (id, data) => {
  return {
    type: actionTypes.UPDATE_WALLET_VERIFY_STATUS,
    id,
    data
  };
};

export const updateWalletVerifyStatusSuccess = () => {
  return {
    type: actionTypes.UPDATE_WALLET_VERIFY_STATUS_SUCCESS
  };
};

export const updateWalletVerifyStatusFail = () => {
  return {
    type: actionTypes.UPDATE_WALLET_VERIFY_STATUS_FAIL
  };
};


export const walletVerifyUploadDoc = (id, data) => {
  return {
    type: actionTypes.WALLET_VERIFY_UPLOAD_DOC,
    id,
    data
  };
};

export const walletVerifyUploadDocSuccess = () => {
  return {
    type: actionTypes.WALLET_VERIFY_UPLOAD_DOC_SUCCESS
  };
};

export const walletVerifyUploadDocFail = () => {
  return {
    type: actionTypes.WALLET_VERIFY_UPLOAD_DOC_FAIL
  };
};


export const updateWalletAllowCredited = (id, data) => {
  return {
    type: actionTypes.UPDATE_WALLET_ALLOW_CREDITED,
    id,
    data
  };
};

export const updateWalletAllowCreditedSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_WALLET_ALLOW_CREDITED_SUCCESS,
    data
  };
};

export const updateWalletAllowCreditedFail = () => {
  return {
    type: actionTypes.UPDATE_WALLET_ALLOW_CREDITED_FAIL
  };
};

/* ===== END WALLET TRANSACTION ===== */




/* ===== DRIVER PENDING PAYMENT ===== */

export const loadDriverPendingPayment = data => {
  return {
    type: actionTypes.LOAD_DRIVER_PENDING_PAYMENT,
    data
  };
};

export const loadDriverPendingPaymentSuccess = data => {
  return {
    type: actionTypes.LOAD_DRIVER_PENDING_PAYMENT_SUCCESS,
    data
  };
};

export const loadDriverPendingPaymentFail = () => {
  return {
    type: actionTypes.LOAD_DRIVER_PENDING_PAYMENT_FAIL
  };
};




export const approveDriverPayment = (id,data) => {
  return {
    type: actionTypes.APPROVE_DRIVER_PAYMENT,
    data,
    id
  };
};

export const approveDriverPaymentSuccess = data => {
  return {
    type: actionTypes.APPROVE_DRIVER_PAYMENT_SUCCESS,
    data
  };
};

export const approveDriverPaymentFail = () => {
  return {
    type: actionTypes.APPROVE_DRIVER_PAYMENT_FAIL
  };
};
/* ===== END DRIVER PENDING PAYMENT ===== */