/* ===== APP ===== */
export const COLLPSE_CHANGE = "COLLPSE_CHANGE";
export const COLLPSE_OPEN_DRAWER = "COLLPSE_OPEN_DRAWER";
export const CHANGE_OPEN_KEYS = "CHANGE_OPEN_KEYS";
export const TOGGLE_ALL = "TOGGLE_ALL";
export const CHANGE_CURRENT = "CHANGE_CURRENT";
export const CLOSE_ALL = "CLOSE_ALL";

export const SET_ENVIRONMENT = "SET_ENVIRONMENT";
export const SET_ENVIRONMENT_SUCCESS = "SET_ENVIRONMENT_SUCCESS";
export const SET_ENVIRONMENT_FAIL = "SET_ENVIRONMENT_FAIL";

export const RESET_APP_STATE = "RESET_APP_STATE";
export const NO_INTERNET = "NO_INTERNET";
export const ENABLE_LOADING = "ENABLE_LOADING";
export const DISABLE_LOADING = "DISABLE_LOADING";

export const TOAST_SUCCESS = "TOAST_SUCCESS";
export const TOAST_ERROR = "TOAST_ERROR";

export const LOAD_SECURE_IMAGE = "LOAD_SECURE_IMAGE";
export const LOAD_SECURE_IMAGE_SUCCESS = "LOAD_SECURE_IMAGE_SUCCESS";

export const HANDLE_API_ERROR = "HANDLE_API_ERROR";
export const FIELD_ERROR_HANDLE = "FIELD_ERROR_HANDLE";

/* ===== END APP ===== */

/* ===== AUTH ===== */
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT_CONFIRMATION = "LOGOUT_CONFIRMATION";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const REFRESH_JWT_TOKEN = "REFRESH_JWT_TOKEN";
export const REFRESH_JWT_TOKEN_SUCCESS = "REFRESH_JWT_TOKEN_SUCCESS";
export const REFRESH_JWT_TOKEN_FAIL = "REFRESH_JWT_TOKEN_FAIL";

export const FORCE_LOGOUT = "FORCE_LOGOUT";
export const FORCE_LOGOUT_SUCCESS = "FORCE_LOGOUT_SUCCESS";

export const CHECK_VERSION = "CHECK_VERSION";
export const CHECK_VERSION_SUCCESS = "CHECK_VERSION_SUCCESS";
export const CHECK_VERSION_FAIL = "CHECK_VERSION_FAIL";

export const VERSION_UPDATE_LIST = "VERSION_UPDATE_LIST";
export const VERSION_UPDATE_LIST_SUCCESS = "VERSION_UPDATE_LIST_SUCCESS";
export const VERSION_UPDATE_LIST_FAIL = "VERSION_UPDATE_LIST_FAIL";
/* ===== END AUTH ===== */


/* ===== ADMIN  ===== */

export const ADMIN_CHANGE_PASSWORD = "ADMIN_CHANGE_PASSWORD";
export const ADMIN_CHANGE_PASSWORD_SUCCESS = "ADMIN_CHANGE_PASSWORD_SUCCESS";
export const ADMIN_CHANGE_PASSWORD_FAIL = "ADMIN_CHANGE_PASSWORD_FAIL";

export const ADMIN_UPDATE_DETAIL = "ADMIN_UPDATE_DETAIL";
export const ADMIN_UPDATE_DETAIL_SUCCESS = "ADMIN_UPDATE_DETAIL_SUCCESS";
export const ADMIN_UPDATE_DETAIL_FAIL = "ADMIN_UPDATE_DETAIL_FAIL";

export const LOAD_ADMIN_LIST = "LOAD_ADMIN_LIST";
export const LOAD_ADMIN_LIST_SUCCESS = "LOAD_ADMIN_LIST_SUCCESS";
export const LOAD_ADMIN_LIST_FAIL = "LOAD_ADMIN_LIST_FAIL";

export const LOAD_ADMIN_DETAIL = "LOAD_ADMIN_DETAIL";
export const LOAD_ADMIN_DETAIL_SUCCESS = "LOAD_ADMIN_DETAIL_SUCCESS";
export const LOAD_ADMIN_DETAIL_FAIL = "LOAD_ADMIN_DETAIL_FAIL";

export const CREATE_ADMIN = "CREATE_ADMIN";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAIL = "CREATE_ADMIN_FAIL";

export const EDIT_ADMIN_DETAIL = "EDIT_ADMIN_DETAIL";
export const EDIT_ADMIN_DETAIL_SUCCESS = "EDIT_ADMIN_DETAIL_SUCCESS";
export const EDIT_ADMIN_DETAIL_FAIL = "EDIT_ADMIN_DETAIL_FAIL";

export const EDIT_ADMIN_STATUS = "EDIT_ADMIN_STATUS";
export const EDIT_ADMIN_STATUS_SUCCESS = "EDIT_ADMIN_STATUS_SUCCESS";
export const EDIT_ADMIN_STATUS_FAIL = "EDIT_ADMIN_STATUS_FAIL";

export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL";

export const RESET_ADMIN_PASSWORD = "RESET_ADMIN_PASSWORD";
export const RESET_ADMIN_PASSWORD_SUCCESS = "RESET_ADMIN_PASSWORD_SUCCESS";
export const RESET_ADMIN_PASSWORD_FAIL = "RESET_ADMIN_PASSWORD_FAIL";

export const SER_ADMIN_EMERGENCY_CONTACT = "SER_ADMIN_EMERGENCY_CONTACT";
export const SER_ADMIN_EMERGENCY_CONTACT_SUCCESS = "SER_ADMIN_EMERGENCY_CONTACT_SUCCESS";
export const SER_ADMIN_EMERGENCY_CONTACT_FAIL = "SER_ADMIN_EMERGENCY_CONTACT_FAIL";
/* ===== END  ===== */



/* ===== ADMIN PRIVILEGES ===== */

export const LOAD_ADMIN_PRIVILEGES_LIST = "LOAD_ADMIN_PRIVILEGES_LIST";
export const LOAD_ADMIN_PRIVILEGES_LIST_SUCCESS = "LOAD_ADMIN_PRIVILEGES_LIST_SUCCESS";
export const LOAD_ADMIN_PRIVILEGES_LIST_FAIL = "LOAD_ADMIN_PRIVILEGES_LIST_FAIL";

export const CREATE_ADMIN_ROLE = "CREATE_ADMIN_ROLE";
export const CREATE_ADMIN_ROLE_SUCCESS = "CREATE_ADMIN_ROLE_SUCCESS";
export const CREATE_ADMIN_ROLE_FAIL = "CREATE_ADMIN_ROLE_FAIL";

export const DELETE_ADMIN_ROLE = "DELETE_ADMIN_ROLE";
export const DELETE_ADMIN_ROLE_SUCCESS = "DELETE_ADMIN_ROLE_SUCCESS";
export const DELETE_ADMIN_ROLE_FAIL = "DELETE_ADMIN_ROLE_FAIL";

export const UPDATE_ADMIN_ROLE = "UPDATE_ADMIN_ROLE";
export const UPDATE_ADMIN_ROLE_SUCCESS = "UPDATE_ADMIN_ROLE_SUCCESS";
export const UPDATE_ADMIN_ROLE_FAIL = "UPDATE_ADMIN_ROLE_FAIL";

export const SET_ADMIN_PRIVILEGES = "SET_ADMIN_PRIVILEGES";
export const SET_ADMIN_PRIVILEGES_SUCCESS = "SET_ADMIN_PRIVILEGES_SUCCESS";
export const SET_ADMIN_PRIVILEGES_FAIL = "SET_ADMIN_PRIVILEGES_FAIL";

export const LOAD_ADMIN_ROLE = "LOAD_ADMIN_ROLE";
export const LOAD_ADMIN_ROLE_SUCCESS = "LOAD_ADMIN_ROLE_SUCCESS";
export const LOAD_ADMIN_ROLE_FAIL = "LOAD_ADMIN_ROLE_SUCCESS";
/* ===== END  ===== */

/* ===== DUMPSTER ===== */
export const LOAD_DUMPSTER_MEASUREMENT_LIST = "LOAD_DUMPSTER_MEASUREMENT_LIST";
export const LOAD_DUMPSTER_MEASUREMENT_LIST_SUCCESS = "LOAD_DUMPSTER_MEASUREMENT_LIST_SUCCESS";
export const LOAD_DUMPSTER_MEASUREMENT_LIST_FAIL = "LOAD_DUMPSTER_MEASUREMENT_LIST_FAIL";

export const ADD_DUMPSTER_MEASUREMENT = "ADD_DUMPSTER_MEASUREMENT";
export const ADD_DUMPSTER_MEASUREMENT_SUCCESS = "ADD_DUMPSTER_MEASUREMENT_SUCCESS";
export const ADD_DUMPSTER_MEASUREMENT_FAIL = "ADD_DUMPSTER_MEASUREMENT_FAIL";

export const EDIT_DUMPSTER_MEASUREMENT = "EDIT_DUMPSTER_MEASUREMENT";
export const EDIT_DUMPSTER_MEASUREMENT_SUCCESS = "EDIT_DUMPSTER_MEASUREMENT_SUCCESS";
export const EDIT_DUMPSTER_MEASUREMENT_FAIL = "EDIT_DUMPSTER_MEASUREMENT_FAIL";

export const DELETE_DUMPSTER_MEASUREMENT = "DELETE_DUMPSTER_MEASUREMENT";
export const DELETE_DUMPSTER_MEASUREMENT_SUCCESS = "DELETE_DUMPSTER_MEASUREMENT_SUCCESS";
export const DELETE_DUMPSTER_MEASUREMENT_FAIL = "DELETE_DUMPSTER_MEASUREMENT_FAIL";

export const LOAD_DUMPSTER_SERVICE_LIST = "LOAD_DUMPSTER_SERVICE_LIST";
export const LOAD_DUMPSTER_SERVICE_LIST_SUCCESS = "LOAD_DUMPSTER_SERVICE_LIST_SUCCESS";
export const LOAD_DUMPSTER_SERVICE_LIST_FAIL = "LOAD_DUMPSTER_SERVICE_LIST_FAIL";

export const UPDATE_DUMPSTER_SERVICE = "UPDATE_DUMPSTER_SERVICE";
export const UPDATE_DUMPSTER_SERVICE_SUCCESS = "UPDATE_DUMPSTER_SERVICE_SUCCESS";
export const UPDATE_DUMPSTER_SERVICE_FAIL = "UPDATE_DUMPSTER_SERVICE_FAIL";


export const LOAD_DUMPSTER_LIST = "LOAD_DUMPSTER_LIST";
export const LOAD_DUMPSTER_LIST_SUCCESS = "LOAD_DUMPSTER_LIST_SUCCESS";
export const LOAD_DUMPSTER_LIST_FAIL = "LOAD_DUMPSTER_LIST_FAIL";

export const LOAD_DUMPSTER_DETAIL = "LOAD_DUMPSTER_DETAIL";
export const LOAD_DUMPSTER_DETAIL_SUCCESS = "LOAD_DUMPSTER_DETAIL_SUCCESS";
export const LOAD_DUMPSTER_DETAIL_FAIL = "LOAD_DUMPSTER_DETAIL_FAIL";

export const ADD_DUMPSTER = "ADD_DUMPSTER";
export const ADD_DUMPSTER_SUCCESS = "ADD_DUMPSTER_SUCCESS";
export const ADD_DUMPSTER_FAIL = "ADD_DUMPSTER_FAIL";

export const EDIT_DUMPSTER = "EDIT_DUMPSTER";
export const EDIT_DUMPSTER_SUCCESS = "EDIT_DUMPSTER_SUCCESS";
export const EDIT_DUMPSTER_FAIL = "EDIT_DUMPSTER_FAIL";

export const DELETE_DUMPSTER = "DELETE_DUMPSTER";
export const DELETE_DUMPSTER_SUCCESS = "DELETE_DUMPSTER_SUCCESS";
export const DELETE_DUMPSTER_FAIL = "DELETE_DUMPSTER_FAIL";

export const LOAD_DUMPSTER_EXTRA_FEE = "LOAD_DUMPSTER_EXTRA_FEE";
export const LOAD_DUMPSTER_EXTRA_FEE_SUCCESS = "LOAD_DUMPSTER_EXTRA_FEE_SUCCESS";
export const LOAD_DUMPSTER_EXTRA_FEE_FAIL = "LOAD_DUMPSTER_EXTRA_FEE_FAIL";

export const UPDATE_DUMPSTER_WASTE_PROCESS_COST = "UPDATE_DUMPSTER_WASTE_PROCESS_COST";
export const UPDATE_DUMPSTER_WASTE_PROCESS_COST_SUCCESS = "UPDATE_DUMPSTER_WASTE_PROCESS_COST_SUCCESS";
export const UPDATE_DUMPSTER_WASTE_PROCESS_COST_FAIL = "UPDATE_DUMPSTER_WASTE_PROCESS_COST_FAIL";

export const UPDATE_DUMPSTER_RECYCLING_PROCESS_COST = "UPDATE_DUMPSTER_RECYCLING_PROCESS_COST";
export const UPDATE_DUMPSTER_RECYCLING_PROCESS_COST_SUCCESS = "UPDATE_DUMPSTER_RECYCLING_PROCESS_COST_SUCCESS";
export const UPDATE_DUMPSTER_RECYCLING_PROCESS_COST_FAIL = "UPDATE_DUMPSTER_RECYCLING_PROCESS_COST_FAIL";


export const DRIVER_DUMPSTER_COUNT = "DRIVER_DUMPSTER_COUNT";
export const DRIVER_DUMPSTER_COUNT_SUCCESS = "DRIVER_DUMPSTER_COUNT_SUCCESS";
export const DRIVER_DUMPSTER_COUNT_FAIL = "DRIVER_DUMPSTER_COUNT_FAIL";

export const DRIVER_DUMPSTER_LIST = "DRIVER_DUMPSTER_LIST";
export const DRIVER_DUMPSTER_LIST_SUCCESS = "DRIVER_DUMPSTER_LIST_SUCCESS";
export const DRIVER_DUMPSTER_LIST_FAIL = "DRIVER_DUMPSTER_LIST_FAIL";

export const LOAD_DRIVER_DUMPSTER_SUMMARY = "LOAD_DRIVER_DUMPSTER_SUMMARY";
export const LOAD_DRIVER_DUMPSTER_SUMMARY_SUCCESS = "LOAD_DRIVER_DUMPSTER_SUMMARY_SUCCESS";
export const LOAD_DRIVER_DUMPSTER_SUMMARY_FAIL = "LOAD_DRIVER_DUMPSTER_SUMMARY_FAIL";

/* ===== END DUMPSTER ===== */


/* ===== RECYCLING DUMPSTER ===== */
export const LOAD_RECYCLING_DUMPSTER_LIST = "LOAD_RECYCLING_DUMPSTER_LIST";
export const LOAD_RECYCLING_DUMPSTER_LIST_SUCCESS = "LOAD_RECYCLING_DUMPSTER_LIST_SUCCESS";
export const LOAD_RECYCLING_DUMPSTER_LIST_FAIL = "LOAD_RECYCLING_DUMPSTER_LIST_FAIL";

export const LOAD_RECYCLING_DUMPSTER_DETAIL = "LOAD_RECYCLING_DUMPSTER_DETAIL";
export const LOAD_RECYCLING_DUMPSTER_DETAIL_SUCCESS = "LOAD_RECYCLING_DUMPSTER_DETAIL_SUCCESS";
export const LOAD_RECYCLING_DUMPSTER_DETAIL_FAIL = "LOAD_RECYCLING_DUMPSTER_DETAIL_FAIL";

export const ADD_RECYCLING_DUMPSTER = "ADD_RECYCLING_DUMPSTER";
export const ADD_RECYCLING_DUMPSTER_SUCCESS = "ADD_RECYCLING_DUMPSTER_SUCCESS";
export const ADD_RECYCLING_DUMPSTER_FAIL = "ADD_RECYCLING_DUMPSTER_FAIL";

export const EDIT_RECYCLING_DUMPSTER = "EDIT_RECYCLING_DUMPSTER";
export const EDIT_RECYCLING_DUMPSTER_SUCCESS = "EDIT_RECYCLING_DUMPSTER_SUCCESS";
export const EDIT_RECYCLING_DUMPSTER_FAIL = "EDIT_RECYCLING_DUMPSTER_FAIL";

export const DELETE_RECYCLING_DUMPSTER = "DELETE_RECYCLING_DUMPSTER";
export const DELETE_RECYCLING_DUMPSTER_SUCCESS = "DELETE_RECYCLING_DUMPSTER_SUCCESS";
export const DELETE_RECYCLING_DUMPSTER_FAIL = "DELETE_RECYCLING_DUMPSTER_FAIL";
/* ===== END RECYCLING DUMPSTER ===== */

/* ===== DUMPSTER PRICE===== */
export const LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST = "LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST";
export const LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST_SUCCESS = "LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST_SUCCESS";
export const LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST_FAIL = "LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST_FAIL";

export const LOAD_DUMPSTER_PRICE_LIST = "LOAD_DUMPSTER_PRICE_LIST";
export const LOAD_DUMPSTER_PRICE_LIST_SUCCESS = "LOAD_DUMPSTER_PRICE_LIST_SUCCESS";
export const LOAD_DUMPSTER_PRICE_LIST_FAIL = "LOAD_DUMPSTER_PRICE_LIST_FAIL";

export const GET_DUMPSTER_PRICE_PDF = "GET_DUMPSTER_PRICE_PDF";
export const GET_DUMPSTER_PRICE_PDF_SUCCESS = "GET_DUMPSTER_PRICE_PDF_SUCCESS";
export const GET_DUMPSTER_PRICE_PDF_FAIL = "GET_DUMPSTER_PRICE_PDF_FAIL";

export const SET_DUMPSTER_PRICE = "SET_DUMPSTER_PRICE";
export const SET_DUMPSTER_PRICE_SUCCESS = "SET_DUMPSTER_PRICE_SUCCESS";
export const SET_DUMPSTER_PRICE_FAIL = "SET_DUMPSTER_PRICE_FAIL";

export const ADD_DUMPSTER_PRICE = "ADD_DUMPSTER_PRICE";
export const ADD_DUMPSTER_PRICE_SUCCESS = "ADD_DUMPSTER_PRICE_SUCCESS";
export const ADD_DUMPSTER_PRICE_FAIL = "ADD_DUMPSTER_PRICE_FAIL";

export const EDIT_DUMPSTER_PRICE = "EDIT_DUMPSTER_PRICE";
export const EDIT_DUMPSTER_PRICE_SUCCESS = "EDIT_DUMPSTER_PRICE_SUCCESS";
export const EDIT_DUMPSTER_PRICE_FAIL = "EDIT_DUMPSTER_PRICE_FAIL";


export const LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST = "LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST";
export const LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS = "LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS";
export const LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST_FAIL = "LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST_FAIL";

export const LOAD_DUMPSTER_PRICE_RECYCLING_LIST = "LOAD_DUMPSTER_PRICE_RECYCLING_LIST";
export const LOAD_DUMPSTER_PRICE_RECYCLING_LIST_SUCCESS = "LOAD_DUMPSTER_PRICE_RECYCLING_LIST_SUCCESS";
export const LOAD_DUMPSTER_PRICE_RECYCLING_LIST_FAIL = "LOAD_DUMPSTER_PRICE_RECYCLING_LIST_FAIL";

export const GET_DUMPSTER_PRICE_RECYCLING_PDF = "GET_DUMPSTER_PRICE_RECYCLING_PDF";
export const GET_DUMPSTER_PRICE_RECYCLING_PDF_SUCCESS = "GET_DUMPSTER_PRICE_RECYCLING_PDF_SUCCESS";
export const GET_DUMPSTER_PRICE_RECYCLING_PDF_FAIL = "GET_DUMPSTER_PRICE_RECYCLING_PDF_FAIL";

export const SET_DUMPSTER_PRICE_RECYCLING = "SET_DUMPSTER_PRICE_RECYCLING";
export const SET_DUMPSTER_PRICE_RECYCLING_SUCCESS = "SET_DUMPSTER_PRICE_RECYCLING_SUCCESS";
export const SET_DUMPSTER_PRICE_RECYCLING_FAIL = "SET_DUMPSTER_PRICE_RECYCLING_FAIL";
/* ===== END DUMPSTER PRICE===== */

/* ===== SCHEDULED WASTE ===== */
export const LOAD_SCHEDULED_WASTE_LIST = "LOAD_SCHEDULED_WASTE_LIST";
export const LOAD_SCHEDULED_WASTE_LIST_SUCCESS = "LOAD_SCHEDULED_WASTE_LIST_SUCCESS";
export const LOAD_SCHEDULED_WASTE_LIST_FAIL = "LOAD_SCHEDULED_WASTE_LIST_FAIL";

export const SCHEDULED_WASTE_CATEGORY_CREATE = "SCHEDULED_WASTE_CATEGORY_CREATE";
export const SCHEDULED_WASTE_CATEGORY_CREATE_SUCCESS = "SCHEDULED_WASTE_CATEGORY_CREATE_SUCCESS";
export const SCHEDULED_WASTE_CATEGORY_CREATE_FAIL = "SCHEDULED_WASTE_CATEGORY_CREATE_FAIL";

export const SCHEDULED_WASTE_CATEGORY_UPDATE = "SCHEDULED_WASTE_CATEGORY_UPDATE";
export const SCHEDULED_WASTE_CATEGORY_UPDATE_SUCCESS = "SCHEDULED_WASTE_CATEGORY_UPDATE_SUCCESS";
export const SCHEDULED_WASTE_CATEGORY_UPDATE_FAIL = "SCHEDULED_WASTE_CATEGORY_UPDATE_FAIL";

export const SCHEDULED_WASTE_CATEGORY_DELETE = "SCHEDULED_WASTE_CATEGORY_DELETE";
export const SCHEDULED_WASTE_CATEGORY_DELETE_SUCCESS = "SCHEDULED_WASTE_CATEGORY_DELETE_SUCCESS";
export const SCHEDULED_WASTE_CATEGORY_DELETE_FAIL = "SCHEDULED_WASTE_CATEGORY_DELETE_FAIL";

export const SCHEDULED_WASTE_CATEGORY_SET_ITEM = "SCHEDULED_WASTE_CATEGORY_SET_ITEM";
export const SCHEDULED_WASTE_CATEGORY_SET_ITEM_SUCCESS = "SCHEDULED_WASTE_CATEGORY_SET_ITEM_SUCCESS";
export const SCHEDULED_WASTE_CATEGORY_SET_ITEM_FAIL = "SCHEDULED_WASTE_CATEGORY_SET_ITEM_FAIL";

export const SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST = "SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST";
export const SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST_SUCCESS = "SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST_SUCCESS";
export const SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST_FAIL = "SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST_FAIL";

export const SCHEDULED_WASTE_PRICE_LIST = "SCHEDULED_WASTE_PRICE_LIST";
export const SCHEDULED_WASTE_PRICE_LIST_SUCCESS = "SCHEDULED_WASTE_PRICE_LIST_SUCCESS";
export const SCHEDULED_WASTE_PRICE_LIST_FAIL = "SCHEDULED_WASTE_PRICE_LIST_FAIL";

export const SCHEDULED_WASTE_PRICE_LIST_PDF = "SCHEDULED_WASTE_PRICE_LIST_PDF";
export const SCHEDULED_WASTE_PRICE_LIST_PDF_SUCCESS = "SCHEDULED_WASTE_PRICE_LIST_PDF_SUCCESS";
export const SCHEDULED_WASTE_PRICE_LIST_PDF_FAIL = "SCHEDULED_WASTE_PRICE_LIST_PDF_FAIL";

export const SCHEDULED_WASTE_PRICE_SET = "SCHEDULED_WASTE_PRICE_SET";
export const SCHEDULED_WASTE_PRICE_SET_SUCCESS = "SCHEDULED_WASTE_PRICE_SET_SUCCESS";
export const SCHEDULED_WASTE_PRICE_SET_FAIL = "SCHEDULED_WASTE_PRICE_SET_FAIL";



export const SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST = "SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST";
export const SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST_SUCCESS = "SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST_SUCCESS";
export const SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST_FAIL = "SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST_FAIL";

export const SCHEDULED_WASTE_LORRY_PRICE_LIST = "SCHEDULED_WASTE_LORRY_PRICE_LIST";
export const SCHEDULED_WASTE_LORRY_PRICE_LIST_SUCCESS = "SCHEDULED_WASTE_LORRY_PRICE_LIST_SUCCESS";
export const SCHEDULED_WASTE_LORRY_PRICE_LIST_FAIL = "SCHEDULED_WASTE_LORRY_PRICE_LIST_FAIL";

export const SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF = "SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF";
export const SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF_SUCCESS = "SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF_SUCCESS";
export const SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF_FAIL = "SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF_FAIL";

export const SCHEDULED_WASTE_LORRY_PRICE_SET = "SCHEDULED_WASTE_LORRY_PRICE_SET";
export const SCHEDULED_WASTE_LORRY_PRICE_SET_SUCCESS = "SCHEDULED_WASTE_LORRY_PRICE_SET_SUCCESS";
export const SCHEDULED_WASTE_LORRY_PRICE_SET_FAIL = "SCHEDULED_WASTE_LORRY_PRICE_SET_FAIL";


export const SCHEDULED_PROCESS_COST_SET = "SCHEDULED_PROCESS_COST_SET";
export const SCHEDULED_PROCESS_COST_SET_SUCCESS = "SCHEDULED_PROCESS_COST_SET_SUCCESS";
export const SCHEDULED_PROCESS_COST_SET_FAIL = "SCHEDULED_PROCESS_COST_SET_FAIL";
/* ===== END SCHEDULED WASTE ===== */


/* ===== SCHEDULED RECOVERY COMPANY ===== */
export const LOAD_RECOVERY_COMPANY_LIST = "LOAD_RECOVERY_COMPANY_LIST";
export const LOAD_RECOVERY_COMPANY_LIST_SUCCESS = "LOAD_RECOVERY_COMPANY_LIST_SUCCESS";
export const LOAD_RECOVERY_COMPANY_LIST_FAIL = "LOAD_RECOVERY_COMPANY_LIST_FAIL";

export const LOAD_RECOVERY_COMPANY_DETAIL = "LOAD_RECOVERY_COMPANY_DETAIL";
export const LOAD_RECOVERY_COMPANY_DETAIL_SUCCESS = "LOAD_RECOVERY_COMPANY_DETAIL_SUCCESS";
export const LOAD_RECOVERY_COMPANY_DETAIL_FAIL = "LOAD_RECOVERY_COMPANY_DETAIL_FAIL";

export const RECOVERY_COMPANY_CREATE = "RECOVERY_COMPANY_CREATE";
export const RECOVERY_COMPANY_CREATE_SUCCESS = "RECOVERY_COMPANY_CREATE_SUCCESS";
export const RECOVERY_COMPANY_CREATE_FAIL = "RECOVERY_COMPANY_CREATE_FAIL";

export const RECOVERY_COMPANY_UPDATE_DETAIL = "RECOVERY_COMPANY_UPDATE_DETAIL";
export const RECOVERY_COMPANY_UPDATE_DETAIL_SUCCESS = "RECOVERY_COMPANY_UPDATE_DETAIL_SUCCESS";
export const RECOVERY_COMPANY_UPDATE_DETAIL_FAIL = "RECOVERY_COMPANY_UPDATE_DETAIL_FAIL";

export const RECOVERY_COMPANY_UPDATE_LICENSE = "RECOVERY_COMPANY_UPDATE_LICENSE";
export const RECOVERY_COMPANY_UPDATE_LICENSE_SUCCESS = "RECOVERY_COMPANY_UPDATE_LICENSE_SUCCESS";
export const RECOVERY_COMPANY_UPDATE_LICENSE_FAIL = "RECOVERY_COMPANY_UPDATE_LICENSE_FAIL";

export const RECOVERY_COMPANY_DELETE = "RECOVERY_COMPANY_DELETE";
export const RECOVERY_COMPANY_DELETE_SUCCESS = "RECOVERY_COMPANY_DELETE_SUCCESS";
export const RECOVERY_COMPANY_DELETE_FAIL = "RECOVERY_COMPANY_DELETE_FAIL";
/* ===== END SCHEDULED RECOVERY COMPANY ===== */


/* ===== SCHEDULED DOE REGISTRATION ===== */
export const LOAD_DOE_REGISTRATION_LIST = "LOAD_DOE_REGISTRATION_LIST";
export const LOAD_DOE_REGISTRATION_LIST_SUCCESS = "LOAD_DOE_REGISTRATION_LIST_SUCCESS";
export const LOAD_DOE_REGISTRATION_LIST_FAIL = "LOAD_DOE_REGISTRATION_LIST_FAIL";

export const LOAD_DOE_REGISTRATION_COUNT = "LOAD_DOE_REGISTRATION_COUNT";
export const LOAD_DOE_REGISTRATION_COUNT_SUCCESS = "LOAD_DOE_REGISTRATION_COUNT_SUCCESS";
export const LOAD_DOE_REGISTRATION_COUNT_FAIL = "LOAD_DOE_REGISTRATION_COUNT_FAIL";

export const LOAD_DOE_REGISTRATION_DETAIL = "LOAD_DOE_REGISTRATION_DETAIL";
export const LOAD_DOE_REGISTRATION_DETAIL_SUCCESS = "LOAD_DOE_REGISTRATION_DETAIL_SUCCESS";
export const LOAD_DOE_REGISTRATION_DETAIL_FAIL = "LOAD_DOE_REGISTRATION_DETAIL_FAIL";

export const DOE_REGISTRATION_APPROVE = "DOE_REGISTRATION_APPROVE";
export const DOE_REGISTRATION_APPROVE_SUCCESS = "DOE_REGISTRATION_APPROVE_SUCCESS";
export const DOE_REGISTRATION_APPROVE_FAIL = "DOE_REGISTRATION_APPROVE_FAIL";

export const DOE_REGISTRATION_REJECT = "DOE_REGISTRATION_REJECT";
export const DOE_REGISTRATION_REJECT_SUCCESS = "DOE_REGISTRATION_REJECT_SUCCESS";
export const DOE_REGISTRATION_REJECT_FAIL = "DOE_REGISTRATION_REJECT_FAIL";
/* ===== END SCHEDULED DOE REGISTRATION ===== */

/* ===== SCHEDULED WASTE CONTAINER ===== */
export const LOAD_SCHEDULED_WASTE_CONTAINER_LIST = "LOAD_SCHEDULED_WASTE_CONTAINER_LIST";
export const LOAD_SCHEDULED_WASTE_CONTAINER_LIST_SUCCESS = "LOAD_SCHEDULED_WASTE_CONTAINER_LIST_SUCCESS";
export const LOAD_SCHEDULED_WASTE_CONTAINER_LIST_FAIL = "LOAD_SCHEDULED_WASTE_CONTAINER_LIST_FAIL";

export const CREATE_SCHEDULED_WASTE_CONTAINER = "CREATE_SCHEDULED_WASTE_CONTAINER";
export const CREATE_SCHEDULED_WASTE_CONTAINER_SUCCESS = "CREATE_SCHEDULED_WASTE_CONTAINER_SUCCESS";
export const CREATE_SCHEDULED_WASTE_CONTAINER_FAIL = "CREATE_SCHEDULED_WASTE_CONTAINER_FAIL";

export const UPDATE_SCHEDULED_WASTE_CONTAINER = "UPDATE_SCHEDULED_WASTE_CONTAINER";
export const UPDATE_SCHEDULED_WASTE_CONTAINER_SUCCESS = "UPDATE_SCHEDULED_WASTE_CONTAINER_SUCCESS";
export const UPDATE_SCHEDULED_WASTE_CONTAINER_FAIL = "UPDATE_SCHEDULED_WASTE_CONTAINER_FAIL";

export const REMOVE_SCHEDULED_WASTE_CONTAINER = "REMOVE_SCHEDULED_WASTE_CONTAINER";
export const REMOVE_SCHEDULED_WASTE_CONTAINER_SUCCESS = "REMOVE_SCHEDULED_WASTE_CONTAINER_SUCCESS";
export const REMOVE_SCHEDULED_WASTE_CONTAINER_FAIL = "REMOVE_SCHEDULED_WASTE_CONTAINER_FAIL";
/* ===== END SCHEDULED WASTE CONTAINER ===== */

/* ===== LORRY ===== */
export const LOAD_LORRY_LIST = "LOAD_LORRY_LIST";
export const LOAD_LORRY_LIST_SUCCESS = "LOAD_LORRY_LIST_SUCCESS";
export const LOAD_LORRY_LIST_FAIL = "LOAD_LORRY_LIST_FAIL";

export const ADD_LORRY = "ADD_LORRY";
export const ADD_LORRY_SUCCESS = "ADD_LORRY_SUCCESS";
export const ADD_LORRY_FAIL = "ADD_LORRY_FAIL";

export const EDIT_LORRY = "EDIT_LORRY";
export const EDIT_LORRY_SUCCESS = "EDIT_LORRY_SUCCESS";
export const EDIT_LORRY_FAIL = "EDIT_LORRY_FAIL";

export const DELETE_LORRY = "DELETE_LORRY";
export const DELETE_LORRY_SUCCESS = "DELETE_LORRY_SUCCESS";
export const DELETE_LORRY_FAIL = "DELETE_LORRY_FAIL";

export const DRIVER_LORRY_COUNT = "DRIVER_LORRY_COUNT";
export const DRIVER_LORRY_COUNT_SUCCESS = "DRIVER_LORRY_COUNT_SUCCESS";
export const DRIVER_LORRY_COUNT_FAIL = "DRIVER_LORRY_COUNT_FAIL";

export const DRIVER_LORRY_LIST = "DRIVER_LORRY_LIST";
export const DRIVER_LORRY_LIST_SUCCESS = "DRIVER_LORRY_LIST_SUCCESS";
export const DRIVER_LORRY_LIST_FAIL = "DRIVER_LORRY_LIST_FAIL";

export const DRIVER_LORRY_DETAIL = "DRIVER_LORRY_DETAIL";
export const DRIVER_LORRY_DETAIL_SUCCESS = "DRIVER_LORRY_DETAIL_SUCCESS";
export const DRIVER_LORRY_DETAIL_FAIL = "DRIVER_LORRY_DETAIL_FAIL";

export const DRIVER_LORRY_APPROVE = "DRIVER_LORRY_APPROVE";
export const DRIVER_LORRY_APPROVE_SUCCESS = "DRIVER_LORRY_APPROVE_SUCCESS";
export const DRIVER_LORRY_APPROVE_FAIL = "DRIVER_LORRY_APPROVE_FAIL";

export const DRIVER_LORRY_REJECT = "DRIVER_LORRY_REJECT";
export const DRIVER_LORRY_REJECT_SUCCESS = "DRIVER_LORRY_REJECT_SUCCESS";
export const DRIVER_LORRY_REJECT_FAIL = "DRIVER_LORRY_REJECT_FAIL";

export const DRIVER_LORRY_CREATE = "DRIVER_LORRY_CREATE";
export const DRIVER_LORRY_CREATE_SUCCESS = "DRIVER_LORRY_CREATE_SUCCESS";
export const DRIVER_LORRY_CREATE_FAIL = "DRIVER_LORRY_CREATE_FAIL";

export const DRIVER_LORRY_REMOVE = "DRIVER_LORRY_REMOVE";
export const DRIVER_LORRY_REMOVE_SUCCESS = "DRIVER_LORRY_REMOVE_SUCCESS";
export const DRIVER_LORRY_REMOVE_FAIL = "DRIVER_LORRY_REMOVE_FAIL";

export const LOAD_DRIVER_LORRY_SUMMARY = "LOAD_DRIVER_LORRY_SUMMARY";
export const LOAD_DRIVER_LORRY_SUMMARY_SUCCESS = "LOAD_DRIVER_LORRY_SUMMARY_SUCCESS";
export const LOAD_DRIVER_LORRY_SUMMARY_FAIL = "LOAD_DRIVER_LORRY_SUMMARY_FAIL";

/* ===== END LORRY ===== */


/* ===== GENERAL ===== */
export const LOAD_LANGUAGUES = "LOAD_LANGUAGUES";
export const LOAD_LANGUAGUES_SUCCESS = "LOAD_LANGUAGUES_SUCCESS";
export const LOAD_LANGUAGUES_FAIL = "LOAD_LANGUAGUES_FAIL";

export const LOAD_STATE_DISTRICT_LIST = "LOAD_STATE_DISTRICT_LIST";
export const LOAD_STATE_DISTRICT_LIST_SUCCESS = "LOAD_STATE_DISTRICT_LIST_SUCCESS";
export const LOAD_STATE_DISTRICT_LIST_FAIL = "LOAD_STATE_DISTRICT_LIST_FAIL";

export const LOAD_DISTANCE_LIST = "LOAD_DISTANCE_LIST";
export const LOAD_DISTANCE_LIST_SUCCESS = "LOAD_DISTANCE_LIST_SUCCESS";
export const LOAD_DISTANCE_LIST_FAIL = "LOAD_DISTANCE_LIST_FAIL";

export const LOAD_TIME_SLOT = "LOAD_TIME_SLOT";
export const LOAD_TIME_SLOT_SUCCESS = "LOAD_TIME_SLOT_SUCCESS";
export const LOAD_TIME_SLOT_FAIL = "LOAD_TIME_SLOT_FAIL";

export const LOAD_ORDER_TYPE = "LOAD_ORDER_TYPE";
export const LOAD_ORDER_TYPE_SUCCESS = "LOAD_ORDER_TYPE_SUCCESS";
export const LOAD_ORDER_TYPE_FAIL = "LOAD_ORDER_TYPE_FAIL";

export const SET_ORDER_TYPE = "SET_ORDER_TYPE";
export const SET_ORDER_TYPE_SUCCESS = "SET_ORDER_TYPE_SUCCESS";
export const SET_ORDER_TYPE_FAIL = "SET_ORDER_TYPE_FAIL";

export const REMOVE_ORDER_TYPE = "REMOVE_ORDER_TYPE";
export const REMOVE_ORDER_TYPE_SUCCESS = "REMOVE_ORDER_TYPE_SUCCESS";
export const REMOVE_ORDER_TYPE_FAIL = "REMOVE_ORDER_TYPE_FAIL";

export const SET_DISTANCE = "SET_DISTANCE";
export const SET_DISTANCE_SUCCESS = "SET_DISTANCE_SUCCESS";
export const SET_DISTANCE_FAIL = "SET_DISTANCE_FAIL";

export const REMOVE_DISTANCE = "REMOVE_DISTANCE";
export const REMOVE_DISTANCE_SUCCESS = "REMOVE_DISTANCE_SUCCESS";
export const REMOVE_DISTANCE_FAIL = "REMOVE_DISTANCE_FAIL";

export const LOAD_LANDFILL_COST_TYPE = "LOAD_LANDFILL_COST_TYPE";
export const LOAD_LANDFILL_COST_TYPE_SUCCESS = "LOAD_LANDFILL_COST_TYPE_SUCCESS";
export const LOAD_LANDFILL_COST_TYPE_FAIL = "LOAD_LANDFILL_COST_TYPE_FAIL";


export const DRIVER_POINT_LOAD = "DRIVER_POINT_LOAD";
export const DRIVER_POINT_LOAD_SUCCESS = "DRIVER_POINT_LOAD_SUCCESS";
export const DRIVER_POINT_LOAD_FAIL = "DRIVER_POINT_LOAD_FAIL";

export const DRIVER_POINT_ADD = "DRIVER_POINT_ADD";
export const DRIVER_POINT_ADD_SUCCESS = "DRIVER_POINT_ADD_SUCCESS";
export const DRIVER_POINT_ADD_FAIL = "DRIVER_POINT_ADD_FAIL";

export const DRIVER_POINT_EDIT = "DRIVER_POINT_EDIT";
export const DRIVER_POINT_EDIT_SUCCESS = "DRIVER_POINT_EDIT_SUCCESS";
export const DRIVER_POINT_EDIT_FAIL = "DRIVER_POINT_EDIT_FAIL";

export const DRIVER_POINT_REMOVE = "DRIVER_POINT_REMOVE";
export const DRIVER_POINT_REMOVE_SUCCESS = "DRIVER_POINT_REMOVE_SUCCESS";
export const DRIVER_POINT_REMOVE_FAIL = "DRIVER_POINT_REMOVE_FAIL";


export const LANDFILL_POINT_LOAD = "LANDFILL_POINT_LOAD";
export const LANDFILL_POINT_LOAD_SUCCESS = "LANDFILL_POINT_LOAD_SUCCESS";
export const LANDFILL_POINT_LOAD_FAIL = "LANDFILL_POINT_LOAD_FAIL";

export const LANDFILL_POINT_DETAIL = "LANDFILL_POINT_DETAIL";
export const LANDFILL_POINT_DETAIL_SUCCESS = "LANDFILL_POINT_DETAIL_SUCCESS";
export const LANDFILL_POINT_DETAIL_FAIL = "LANDFILL_POINT_DETAIL_FAIL";

export const LANDFILL_POINT_ADD = "LANDFILL_POINT_ADD";
export const LANDFILL_POINT_ADD_SUCCESS = "LANDFILL_POINT_ADD_SUCCESS";
export const LANDFILL_POINT_ADD_FAIL = "LANDFILL_POINT_ADD_FAIL";

export const LANDFILL_POINT_EDIT = "LANDFILL_POINT_EDIT";
export const LANDFILL_POINT_EDIT_SUCCESS = "LANDFILL_POINT_EDIT_SUCCESS";
export const LANDFILL_POINT_EDIT_FAIL = "LANDFILL_POINT_EDIT_FAIL";

export const LANDFILL_POINT_REMOVE = "LANDFILL_POINT_REMOVE";
export const LANDFILL_POINT_REMOVE_SUCCESS = "LANDFILL_POINT_REMOVE_SUCCESS";
export const LANDFILL_POINT_REMOVE_FAIL = "LANDFILL_POINT_REMOVE_FAIL";

export const EDIT_RECYCLING_SALES_PRICE = "EDIT_RECYCLING_SALES_PRICE";
export const EDIT_RECYCLING_SALES_PRICE_SUCCESS = "EDIT_RECYCLING_SALES_PRICE_SUCCESS";
export const EDIT_RECYCLING_SALES_PRICE_FAIL = "EDIT_RECYCLING_SALES_PRICE_FAIL";


export const LANDFILL_ADJUSTMENT_SET = "LANDFILL_ADJUSTMENT_SET";
export const LANDFILL_ADJUSTMENT_SET_SUCCESS = "LANDFILL_ADJUSTMENT_SET_SUCCESS";
export const LANDFILL_ADJUSTMENT_SET_FAIL = "LANDFILL_ADJUSTMENT_SET_FAIL";

export const LANDFILL_ADJUSTMENT_REMOVE = "LANDFILL_ADJUSTMENT_REMOVE";
export const LANDFILL_ADJUSTMENT_REMOVE_SUCCESS = "LANDFILL_ADJUSTMENT_REMOVE_SUCCESS";
export const LANDFILL_ADJUSTMENT_REMOVE_FAIL = "LANDFILL_ADJUSTMENT_REMOVE_FAIL";


export const LANDFILL_ADJUSTMENT_LOAD = "LANDFILL_ADJUSTMENT_LOAD";
export const LANDFILL_ADJUSTMENT_LOAD_SUCCESS = "LANDFILL_ADJUSTMENT_LOAD_SUCCESS";
export const LANDFILL_ADJUSTMENT_LOAD_FAIL = "LANDFILL_ADJUSTMENT_LOAD_FAIL";

export const LANDFILL_ADJUSTMENT_DETAIL = "LANDFILL_ADJUSTMENT_DETAIL";
export const LANDFILL_ADJUSTMENT_DETAIL_SUCCESS = "LANDFILL_ADJUSTMENT_DETAIL_SUCCESS";
export const LANDFILL_ADJUSTMENT_DETAIL_FAIL = "LANDFILL_ADJUSTMENT_DETAIL_FAIL";


export const WEIGHING_POINT_LOAD = "WEIGHING_POINT_LOAD";
export const WEIGHING_POINT_LOAD_SUCCESS = "WEIGHING_POINT_LOAD_SUCCESS";
export const WEIGHING_POINT_LOAD_FAIL = "WEIGHING_POINT_LOAD_FAIL";

export const WEIGHING_POINT_ADD = "WEIGHING_POINT_ADD";
export const WEIGHING_POINT_ADD_SUCCESS = "WEIGHING_POINT_ADD_SUCCESS";
export const WEIGHING_POINT_ADD_FAIL = "WEIGHING_POINT_ADD_FAIL";

export const WEIGHING_POINT_EDIT = "WEIGHING_POINT_EDIT";
export const WEIGHING_POINT_EDIT_SUCCESS = "WEIGHING_POINT_EDIT_SUCCESS";
export const WEIGHING_POINT_EDIT_FAIL = "WEIGHING_POINT_EDIT_FAIL";

export const WEIGHING_POINT_REMOVE = "WEIGHING_POINT_REMOVE";
export const WEIGHING_POINT_REMOVE_SUCCESS = "WEIGHING_POINT_REMOVE_SUCCESS";
export const WEIGHING_POINT_REMOVE_FAIL = "WEIGHING_POINT_REMOVE_FAIL";


export const SETTING_LOAD = "SETTING_LOAD";
export const SETTING_LOAD_SUCCESS = "SETTING_LOAD_SUCCESS";
export const SETTING_LOAD_FAIL = "SETTING_LOAD_FAIL";

export const GET_SETTING_VALUE = "GET_SETTING_VALUE";
export const GET_SETTING_VALUE_SUCCESS = "GET_SETTING_VALUE_SUCCESS";
export const GET_SETTING_VALUE_FAIL = "GET_SETTING_VALUE_FAIL";

export const SETTING_UPDATE = "SETTING_UPDATE";
export const SETTING_UPDATE_SUCCESS = "SETTING_UPDATE_SUCCESS";
export const SETTING_UPDATE_FAIL = "SETTING_UPDATE_FAIL";

export const LOAD_CARBON_FOOTPRINT = "LOAD_CARBON_FOOTPRINT";
export const LOAD_CARBON_FOOTPRINT_SUCCESS = "LOAD_CARBON_FOOTPRINT_SUCCESS";
export const LOAD_CARBON_FOOTPRINT_FAIL = "LOAD_CARBON_FOOTPRINT_FAIL";

export const SET_CARBON_FOOTPRINT = "SET_CARBON_FOOTPRINT";
export const SET_CARBON_FOOTPRINT_SUCCESS = "SET_CARBON_FOOTPRINT_SUCCESS";
export const SET_CARBON_FOOTPRINT_FAIL = "SET_CARBON_FOOTPRINT_FAIL";

export const LOAD_OVERALL_IMPACT = "LOAD_OVERALL_IMPACT";
export const LOAD_OVERALL_IMPACT_SUCCESS = "LOAD_OVERALL_IMPACT_SUCCESS";
export const LOAD_OVERALL_IMPACT_FAIL = "LOAD_OVERALL_IMPACT_FAIL";

export const LOAD_REFERRAL_COMMISSION = "LOAD_REFERRAL_COMMISSION";
export const LOAD_REFERRAL_COMMISSION_SUCCESS = "LOAD_REFERRAL_COMMISSION_SUCCESS";
export const LOAD_REFERRAL_COMMISSION_FAIL = "LOAD_REFERRAL_COMMISSION_FAIL";

export const SET_REFERRAL_COMMISSION = "SET_REFERRAL_COMMISSION";
export const SET_REFERRAL_COMMISSION_SUCCESS = "SET_REFERRAL_COMMISSION_SUCCESS";
export const SET_REFERRAL_COMMISSION_FAIL = "SET_REFERRAL_COMMISSION_FAIL";

export const REMOVE_REFERRAL_LEVEL = "REMOVE_REFERRAL_LEVEL";
export const REMOVE_REFERRAL_LEVEL_SUCCESS = "REMOVE_REFERRAL_LEVEL_SUCCESS";
export const REMOVE_REFERRAL_LEVEL_FAIL = "REMOVE_REFERRAL_LEVEL_FAIL";

export const LOAD_REFERRAL_COMMISSION_SUMMARY = "LOAD_REFERRAL_COMMISSION_SUMMARY";
export const LOAD_REFERRAL_COMMISSION_SUMMARY_SUCCESS = "LOAD_REFERRAL_COMMISSION_SUMMARY_SUCCESS";
export const LOAD_REFERRAL_COMMISSION_SUMMARY_FAIL = "LOAD_REFERRAL_COMMISSION_SUMMARY_FAIL";

export const LOAD_INVITE_FRIEND_SETTING = "LOAD_INVITE_FRIEND_SETTING";
export const LOAD_INVITE_FRIEND_SETTING_SUCCESS = "LOAD_INVITE_FRIEND_SETTING_SUCCESS";
export const LOAD_INVITE_FRIEND_SETTING_FAIL = "LOAD_INVITE_FRIEND_SETTING_FAIL";

export const SET_INVITE_FRIEND_SETTING = "SET_INVITE_FRIEND_SETTING";
export const SET_INVITE_FRIEND_SETTING_SUCCESS = "SET_INVITE_FRIEND_SETTING_SUCCESS";
export const SET_INVITE_FRIEND_SETTING_FAIL = "SET_INVITE_FRIEND_SETTING_FAIL";

export const LOAD_DRIVER_SUBSCRIPTION_SETTING = "LOAD_DRIVER_SUBSCRIPTION_SETTING";
export const LOAD_DRIVER_SUBSCRIPTION_SETTING_SUCCESS = "LOAD_DRIVER_SUBSCRIPTION_SETTING_SUCCESS";
export const LOAD_DRIVER_SUBSCRIPTION_SETTING_FAIL = "LOAD_DRIVER_SUBSCRIPTION_SETTING_FAIL";

export const SET_DRIVER_SUBSCRIPTION_SETTING = "SET_DRIVER_SUBSCRIPTION_SETTING";
export const SET_DRIVER_SUBSCRIPTION_SETTING_SUCCESS = "SET_DRIVER_SUBSCRIPTION_SETTING_SUCCESS";
export const SET_DRIVER_SUBSCRIPTION_SETTING_FAIL = "SET_DRIVER_SUBSCRIPTION_SETTING_FAIL";

/* ===== END GENERAL ===== */

/* ===== RECYCLING  ===== */
export const LOAD_EXPRESS_RECYCLING_LIST = "LOAD_EXPRESS_RECYCLING_LIST";
export const LOAD_EXPRESS_RECYCLING_LIST_SUCCESS = "LOAD_EXPRESS_RECYCLING_LIST_SUCCESS";
export const LOAD_EXPRESS_RECYCLING_LIST_FAIL = "LOAD_EXPRESS_RECYCLING_LIST_FAIL";

export const LOAD_EXPRESS_RECYCLING_LIST_ALL = "LOAD_EXPRESS_RECYCLING_LIST_ALL";
export const LOAD_EXPRESS_RECYCLING_LIST_ALL_SUCCESS = "LOAD_EXPRESS_RECYCLING_LIST_ALL_SUCCESS";
export const LOAD_EXPRESS_RECYCLING_LIST_ALL_FAIL = "LOAD_EXPRESS_RECYCLING_LIST_ALL_FAIL";

export const SET_EXPRESS_RECYCLING_SEQUENCE = "SET_EXPRESS_RECYCLING_SEQUENCE";
export const SET_EXPRESS_RECYCLING_SEQUENCE_SUCCESS = "SET_EXPRESS_RECYCLING_SEQUENCE_SUCCESS";
export const SET_EXPRESS_RECYCLING_SEQUENCE_FAIL = "SET_EXPRESS_RECYCLING_SEQUENCE_FAIL";

export const LOAD_EXPRESS_RECYCLING_DETAIL = "LOAD_EXPRESS_RECYCLING_DETAIL";
export const LOAD_EXPRESS_RECYCLING_DETAIL_SUCCESS = "LOAD_EXPRESS_RECYCLING_DETAIL_SUCCESS";
export const LOAD_EXPRESS_RECYCLING_DETAIL_FAIL = "LOAD_EXPRESS_RECYCLING_DETAIL_FAIL";

export const LOAD_EXPRESS_RECYCLING_MIX_LIST = "LOAD_EXPRESS_RECYCLING_MIX_LIST";
export const LOAD_EXPRESS_RECYCLING_MIX_LIST_SUCCESS = "LOAD_EXPRESS_RECYCLING_MIX_LIST_SUCCESS";
export const LOAD_EXPRESS_RECYCLING_MIX_LIST_FAIL = "LOAD_EXPRESS_RECYCLING_MIX_LIST_FAIL";

export const LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST = "LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST";
export const LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS = "LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS";
export const LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST_FAIL = "LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST_FAIL";

export const EXPRESS_RECYCLING_PRICE_CONTROL_LOAD = "EXPRESS_RECYCLING_PRICE_CONTROL_LOAD";
export const EXPRESS_RECYCLING_PRICE_CONTROL_LOAD_SUCCESS = "EXPRESS_RECYCLING_PRICE_CONTROL_LOAD_SUCCESS";
export const EXPRESS_RECYCLING_PRICE_CONTROL_LOAD_FAIL = "EXPRESS_RECYCLING_PRICE_CONTROL_LOAD_FAIL";

export const EXPRESS_RECYCLING_PRICE_CONTROL_SET = "EXPRESS_RECYCLING_PRICE_CONTROL_SET";
export const EXPRESS_RECYCLING_PRICE_CONTROL_SET_SUCCESS = "EXPRESS_RECYCLING_PRICE_CONTROL_SET_SUCCESS";
export const EXPRESS_RECYCLING_PRICE_CONTROL_SET_FAIL = "EXPRESS_RECYCLING_PRICE_CONTROL_SET_FAIL";

export const DELETE_EXPRESS_RECYCLING = "DELETE_EXPRESS_RECYCLING";
export const DELETE_EXPRESS_RECYCLING_SUCCESS = "DELETE_EXPRESS_RECYCLING_SUCCESS";
export const DELETE_EXPRESS_RECYCLING_FAIL = "DELETE_EXPRESS_RECYCLING_FAIL";

export const GET_EXPRESS_RECYCLING_PRICE_PDF = "GET_EXPRESS_RECYCLING_PRICE_PDF";
export const GET_EXPRESS_RECYCLING_PRICE_PDF_SUCCESS = "GET_EXPRESS_RECYCLING_PRICE_PDF_SUCCESS";
export const GET_EXPRESS_RECYCLING_PRICE_PDF_FAIL = "GET_EXPRESS_RECYCLING_PRICE_PDF_FAIL";

export const GET_EXPRESS_RECYCLING_PRICE_POST_PDF = "GET_EXPRESS_RECYCLING_PRICE_POST_PDF";
export const GET_EXPRESS_RECYCLING_PRICE_POST_PDF_SUCCESS = "GET_EXPRESS_RECYCLING_PRICE_POST_PDF_SUCCESS";
export const GET_EXPRESS_RECYCLING_PRICE_POST_PDF_FAIL = "GET_EXPRESS_RECYCLING_PRICE_POST_PDF_FAIL";

export const EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST = "EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST";
export const EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_SUCCESS = "EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_SUCCESS";
export const EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_FAIL = "EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_FAIL";

export const EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT = "EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT";
export const EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_SUCCESS = "EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_SUCCESS";
export const EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_FAIL = "EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_FAIL";

export const EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL = "EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL";
export const EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_SUCCESS = "EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_SUCCESS";
export const EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_FAIL = "EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_FAIL";
/* ===== END EXPRESS  ===== */

/* ===== RECYCLING  ===== */
export const COUNT_NEW_RECYCLING_PRICE = "COUNT_NEW_RECYCLING_PRICE";
export const COUNT_NEW_RECYCLING_PRICE_SUCCESS = "COUNT_NEW_RECYCLING_PRICE_SUCCESS";
export const COUNT_NEW_RECYCLING_PRICE_FAIL = "COUNT_NEW_RECYCLING_PRICE_FAIL";

export const LOAD_RECYCLING_LIST = "LOAD_RECYCLING_LIST";
export const LOAD_RECYCLING_LIST_SUCCESS = "LOAD_RECYCLING_LIST_SUCCESS";
export const LOAD_RECYCLING_LIST_FAIL = "LOAD_RECYCLING_LIST_FAIL";

export const LOAD_RECYCLING_LIST_ALL = "LOAD_RECYCLING_LIST_ALL";
export const LOAD_RECYCLING_LIST_ALL_SUCCESS = "LOAD_RECYCLING_LIST_ALL_SUCCESS";
export const LOAD_RECYCLING_LIST_ALL_FAIL = "LOAD_RECYCLING_LIST_ALL_FAIL";

export const SET_RECYCLING_SEQUENCE = "SET_RECYCLING_SEQUENCE";
export const SET_RECYCLING_SEQUENCE_SUCCESS = "SET_RECYCLING_SEQUENCE_SUCCESS";
export const SET_RECYCLING_SEQUENCE_FAIL = "SET_RECYCLING_SEQUENCE_FAIL";

export const LOAD_RECYCLING_DETAIL = "LOAD_RECYCLING_DETAIL";
export const LOAD_RECYCLING_DETAIL_SUCCESS = "LOAD_RECYCLING_DETAIL_SUCCESS";
export const LOAD_RECYCLING_DETAIL_FAIL = "LOAD_RECYCLING_DETAIL_FAIL";

export const LOAD_RECYCLING_MIX_LIST = "LOAD_RECYCLING_MIX_LIST";
export const LOAD_RECYCLING_MIX_LIST_SUCCESS = "LOAD_RECYCLING_MIX_LIST_SUCCESS";
export const LOAD_RECYCLING_MIX_LIST_FAIL = "LOAD_RECYCLING_MIX_LIST_FAIL";

export const LOAD_RECYCLING_CATEGORY = "LOAD_RECYCLING_CATEGORY";
export const LOAD_RECYCLING_CATEGORY_SUCCESS = "LOAD_RECYCLING_CATEGORY_SUCCESS";
export const LOAD_RECYCLING_CATEGORY_FAIL = "LOAD_RECYCLING_CATEGORY_FAIL";

export const LOAD_RECYCLING_CATEGORY_ALL = "LOAD_RECYCLING_CATEGORY_ALL";
export const LOAD_RECYCLING_CATEGORY_ALL_SUCCESS = "LOAD_RECYCLING_CATEGORY_ALL_SUCCESS";
export const LOAD_RECYCLING_CATEGORY_ALL_FAIL = "LOAD_RECYCLING_CATEGORY_ALL_FAIL";

export const ADD_RECYCLING_CATEGORY = "ADD_RECYCLING_CATEGORY";
export const ADD_RECYCLING_CATEGORY_SUCCESS = "ADD_RECYCLING_CATEGORY_SUCCESS";
export const ADD_RECYCLING_CATEGORY_FAIL = "ADD_RECYCLING_CATEGORY_FAIL";

export const EDIT_RECYCLING_CATEGORY = "EDIT_RECYCLING_CATEGORY";
export const EDIT_RECYCLING_CATEGORY_SUCCESS = "EDIT_RECYCLING_CATEGORY_SUCCESS";
export const EDIT_RECYCLING_CATEGORY_FAIL = "EDIT_RECYCLING_CATEGORY_FAIL";

export const DELETE_RECYCLING_CATEGORY = "DELETE_RECYCLING_CATEGORY";
export const DELETE_RECYCLING_CATEGORY_SUCCESS = "DELETE_RECYCLING_CATEGORY_SUCCESS";
export const DELETE_RECYCLING_CATEGORY_FAIL = "DELETE_RECYCLING_CATEGORY_FAIL";

export const SET_RECYCLING_CATEGORY_SEQUENCE = "SET_RECYCLING_CATEGORY_SEQUENCE";
export const SET_RECYCLING_CATEGORY_SEQUENCE_SUCCESS = "SET_RECYCLING_CATEGORY_SEQUENCE_SUCCESS";
export const SET_RECYCLING_CATEGORY_SEQUENCE_FAIL = "SET_RECYCLING_CATEGORY_SEQUENCE_FAIL";


export const LOAD_RECYCLING_EFFECTIVE_DATE_LIST = "LOAD_RECYCLING_EFFECTIVE_DATE_LIST";
export const LOAD_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS = "LOAD_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS";
export const LOAD_RECYCLING_EFFECTIVE_DATE_LIST_FAIL = "LOAD_RECYCLING_EFFECTIVE_DATE_LIST_FAIL";



export const RECYCLING_PRICE_CONTROL_LOAD = "RECYCLING_PRICE_CONTROL_LOAD";
export const RECYCLING_PRICE_CONTROL_LOAD_SUCCESS = "RECYCLING_PRICE_CONTROL_LOAD_SUCCESS";
export const RECYCLING_PRICE_CONTROL_LOAD_FAIL = "RECYCLING_PRICE_CONTROL_LOAD_FAIL";

export const RECYCLING_PRICE_CONTROL_SET = "RECYCLING_PRICE_CONTROL_SET";
export const RECYCLING_PRICE_CONTROL_SET_SUCCESS = "RECYCLING_PRICE_CONTROL_SET_SUCCESS";
export const RECYCLING_PRICE_CONTROL_SET_FAIL = "RECYCLING_PRICE_CONTROL_SET_FAIL";

export const DELETE_RECYCLING = "DELETE_RECYCLING";
export const DELETE_RECYCLING_SUCCESS = "DELETE_RECYCLING_SUCCESS";
export const DELETE_RECYCLING_FAIL = "DELETE_RECYCLING_FAIL";


export const LOAD_RECYCLING_EXTRA_FEE_LIST = "LOAD_RECYCLING_EXTRA_FEE_LIST";
export const LOAD_RECYCLING_EXTRA_FEE_LIST_SUCCESS = "LOAD_RECYCLING_EXTRA_FEE_LIST_SUCCESS";
export const LOAD_RECYCLING_EXTRA_FEE_LIST_FAIL = "LOAD_RECYCLING_EXTRA_FEE_LIST_FAIL";

export const ADD_RECYCLING_EXTRA_FEE = "ADD_RECYCLING_EXTRA_FEE";
export const ADD_RECYCLING_EXTRA_FEE_SUCCESS = "ADD_RECYCLING_EXTRA_FEE_SUCCESS";
export const ADD_RECYCLING_EXTRA_FEE_FAIL = "ADD_RECYCLING_EXTRA_FEE_FAIL";

export const EDIT_RECYCLING_EXTRA_FEE_ITEM = "EDIT_RECYCLING_EXTRA_FEE_ITEM";
export const EDIT_RECYCLING_EXTRA_FEE_ITEM_SUCCESS = "EDIT_RECYCLING_EXTRA_FEE_ITEM_SUCCESS";
export const EDIT_RECYCLING_EXTRA_FEE_ITEM_FAIL = "EDIT_RECYCLING_EXTRA_FEE_ITEM_FAIL";

export const UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE = "UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE";
export const UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE_SUCCESS = "UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE_SUCCESS";
export const UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE_FAIL = "UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE_FAIL";

export const DELETE_RECYCLING_EXTRA_FEE = "DELETE_RECYCLING_EXTRA_FEE";
export const DELETE_RECYCLING_EXTRA_FEE_SUCCESS = "DELETE_RECYCLING_EXTRA_FEE_SUCCESS";
export const DELETE_RECYCLING_EXTRA_FEE_FAIL = "DELETE_RECYCLING_EXTRA_FEE_FAIL";

export const LOAD_RECYCLING_LOADING_FEE_LIST = "LOAD_RECYCLING_LOADING_FEE_LIST";
export const LOAD_RECYCLING_LOADING_FEE_LIST_SUCCESS = "LOAD_RECYCLING_LOADING_FEE_LIST_SUCCESS";
export const LOAD_RECYCLING_LOADING_FEE_LIST_FAIL = "LOAD_RECYCLING_LOADING_FEE_LIST_FAIL";

export const SET_RECYCLING_LOADING_FEE = "SET_RECYCLING_LOADING_FEE";
export const SET_RECYCLING_LOADING_FEE_SUCCESS = "SET_RECYCLING_LOADING_FEE_SUCCESS";
export const SET_RECYCLING_LOADING_FEE_FAIL = "SET_RECYCLING_LOADING_FEE_FAIL";


export const GET_RECYCLING_PRICE_PDF = "GET_RECYCLING_PRICE_PDF";
export const GET_RECYCLING_PRICE_PDF_SUCCESS = "GET_RECYCLING_PRICE_PDF_SUCCESS";
export const GET_RECYCLING_PRICE_PDF_FAIL = "GET_RECYCLING_PRICE_PDF_FAIL";

export const GET_RECYCLING_PRICE_POST_PDF = "GET_RECYCLING_PRICE_POST_PDF";
export const GET_RECYCLING_PRICE_POST_PDF_SUCCESS = "GET_RECYCLING_PRICE_POST_PDF_SUCCESS";
export const GET_RECYCLING_PRICE_POST_PDF_FAIL = "GET_RECYCLING_PRICE_POST_PDF_FAIL";

export const LOAD_RECYCLING_SALES_PRICE_LIST = "LOAD_RECYCLING_SALES_PRICE_LIST";
export const LOAD_RECYCLING_SALES_PRICE_LIST_SUCCESS = "LOAD_RECYCLING_SALES_PRICE_LIST_SUCCESS";
export const LOAD_RECYCLING_SALES_PRICE_LIST_FAIL = "LOAD_RECYCLING_SALES_PRICE_LIST_FAIL";

export const GET_RECYCLING_SALES_PRICE_PDF = "GET_RECYCLING_SALES_PRICE_PDF";
export const GET_RECYCLING_SALES_PRICE_PDF_SUCCESS = "GET_RECYCLING_SALES_PRICE_PDF_SUCCESS";
export const GET_RECYCLING_SALES_PRICE_PDF_FAIL = "GET_RECYCLING_SALES_PRICE_PDF_FAIL";

export const RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST = "RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST";
export const RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_SUCCESS = "RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_SUCCESS";
export const RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_FAIL = "RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_FAIL";

export const RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT = "RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT";
export const RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_SUCCESS = "RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_SUCCESS";
export const RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_FAIL = "RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_FAIL";

export const RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL = "RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL";
export const RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_SUCCESS = "RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_SUCCESS";
export const RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_FAIL = "RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_FAIL";

/* ===== END RECYCLING ===== */


/* ===== USER  ===== */
export const LOAD_USER_LIST = "LOAD_USER_LIST";
export const LOAD_USER_LIST_SUCCESS = "LOAD_USER_LIST_SUCCESS";
export const LOAD_USER_LIST_FAIL = "LOAD_USER_LIST_FAIL";

export const USER_COUNT = "USER_COUNT";
export const USER_COUNT_SUCCESS = "USER_COUNT_SUCCESS";
export const USER_COUNT_FAIL = "USER_COUNT_FAIL";

export const LOAD_USER_DETAIL = "LOAD_USER_DETAIL";
export const LOAD_USER_DETAIL_SUCCESS = "LOAD_USER_DETAIL_SUCCESS";
export const LOAD_USER_DETAIL_FAIL = "LOAD_USER_DETAIL_FAIL";

export const SWITCH_USER_TO_ORGANIZATION_TYPE = "SWITCH_USER_TO_ORGANIZATION_TYPE";
export const SWITCH_USER_TO_ORGANIZATION_TYPE_SUCCESS = "SWITCH_USER_TO_ORGANIZATION_TYPE_SUCCESS";
export const SWITCH_USER_TO_ORGANIZATION_TYPE_FAIL = "SWITCH_USER_TO_ORGANIZATION_TYPE_FAIL";

export const RECOVERY_USER = "RECOVERY_USER";
export const RECOVERY_USER_SUCCESS = "RECOVERY_USER_SUCCESS";
export const RECOVERY_USER_FAIL = "RECOVERY_USER_FAIL";

export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const EDIT_USER_DETAIL = "EDIT_USER_DETAIL";
export const EDIT_USER_DETAIL_SUCCESS = "EDIT_USER_DETAIL_SUCCESS";
export const EDIT_USER_DETAIL_FAIL = "EDIT_USER_DETAIL_FAIL";

export const REMOVE_USER = "REMOVE_USER";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const REMOVE_USER_FAIL = "REMOVE_USER_FAIL";

export const EDIT_USER_STATUS = "EDIT_USER_STATUS";
export const EDIT_USER_STATUS_SUCCESS = "EDIT_USER_STATUS_SUCCESS";
export const EDIT_USER_STATUS_FAIL = "EDIT_USER_STATUS_FAIL";

export const EDIT_USER_SHOW_VERI_CODE_STATUS = "EDIT_USER_SHOW_VERI_CODE_STATUS";
export const EDIT_USER_SHOW_VERI_CODE_STATUS_SUCCESS = "EDIT_USER_SHOW_VERI_CODE_STATUS_SUCCESS";
export const EDIT_USER_SHOW_VERI_CODE_STATUS_FAIL = "EDIT_USER_SHOW_VERI_CODE_STATUS_FAIL";

export const EDIT_USER_VERIFY_STATUS = "EDIT_USER_VERIFY_STATUS";
export const EDIT_USER_VERIFY_STATUS_SUCCESS = "EDIT_USER_VERIFY_STATUS_SUCCESS";
export const EDIT_USER_VERIFY_STATUS_FAIL = "EDIT_USER_VERIFY_STATUS_FAIL";

export const LOAD_USER_SUMMARY = "LOAD_USER_SUMMARY";
export const LOAD_USER_SUMMARY_SUCCESS = "LOAD_USER_SUMMARY_SUCCESS";
export const LOAD_USER_SUMMARY_FAIL = "LOAD_USER_SUMMARY_FAIL";

export const SET_USER_BILLING_ADDRESS = "SET_USER_BILLING_ADDRESS";
export const SET_USER_BILLING_ADDRESS_SUCCESS = "SET_USER_BILLING_ADDRESS_SUCCESS";
export const SET_USER_BILLING_ADDRESS_FAIL = "SET_USER_BILLING_ADDRESS_FAIL";

export const LOAD_USER_CREDIT_TERM_REQEUST_LIST = "LOAD_USER_CREDIT_TERM_REQEUST_LIST";
export const LOAD_USER_CREDIT_TERM_REQEUST_LIST_SUCCESS = "LOAD_USER_CREDIT_TERM_REQEUST_LIST_SUCCESS";
export const LOAD_USER_CREDIT_TERM_REQEUST_LIST_FAIL = "LOAD_USER_CREDIT_TERM_REQEUST_LIST_FAIL";

export const APPROVE_USER_CREDIT_TERM_REQEUST = "APPROVE_USER_CREDIT_TERM_REQEUST";
export const APPROVE_USER_CREDIT_TERM_REQEUST_SUCCESS = "APPROVE_USER_CREDIT_TERM_REQEUST_SUCCESS";
export const APPROVE_USER_CREDIT_TERM_REQEUST_FAIL = "APPROVE_USER_CREDIT_TERM_REQEUST_FAIL";

export const REJECT_USER_CREDIT_TERM_REQEUST = "REJECT_USER_CREDIT_TERM_REQEUST";
export const REJECT_USER_CREDIT_TERM_REQEUST_SUCCESS = "REJECT_USER_CREDIT_TERM_REQEUST_SUCCESS";
export const REJECT_USER_CREDIT_TERM_REQEUST_FAIL = "REJECT_USER_CREDIT_TERM_REQEUST_FAIL";

export const CANCEL_USER_CREDIT_TERM_REQEUST = "CANCEL_USER_CREDIT_TERM_REQEUST";
export const CANCEL_USER_CREDIT_TERM_REQEUST_SUCCESS = "CANCEL_USER_CREDIT_TERM_REQEUST_SUCCESS";
export const CANCEL_USER_CREDIT_TERM_REQEUST_FAIL = "CANCEL_USER_CREDIT_TERM_REQEUST_FAIL";

export const SET_USER_NEGO_PRICE = "SET_USER_NEGO_PRICE";
export const SET_USER_NEGO_PRICE_SUCCESS = "SET_USER_NEGO_PRICE_SUCCESS";
export const SET_USER_NEGO_PRICE_FAIL = "SET_USER_NEGO_PRICE_FAIL";

/* ===== END  ===== */

/* ===== WALLET TRANSACTION  ===== */
export const WALLET_TRANSACTION_COUNT = "WALLET_TRANSACTION_COUNT";
export const WALLET_TRANSACTION_COUNT_SUCCESS = "WALLET_TRANSACTION_COUNT_SUCCESS";
export const WALLET_TRANSACTION_COUNT_FAIL = "WALLET_TRANSACTION_COUNT_FAIL";

export const LOAD_WALLET_BALANCE = "LOAD_WALLET_BALANCE";
export const LOAD_WALLET_BALANCE_SUCCESS = "LOAD_WALLET_BALANCE_SUCCESS";
export const LOAD_WALLET_BALANCE_FAIL = "LOAD_WALLET_BALANCE_FAIL";

export const LOAD_WALLET_TRANSACTION = "LOAD_WALLET_TRANSACTION";
export const LOAD_WALLET_TRANSACTION_SUCCESS = "LOAD_WALLET_TRANSACTION_SUCCESS";
export const LOAD_WALLET_TRANSACTION_FAIL = "LOAD_WALLET_TRANSACTION_FAIL";

export const LOAD_WALLET_ACCOUNT_LIST = "LOAD_WALLET_ACCOUNT_LIST";
export const LOAD_WALLET_ACCOUNT_LIST_SUCCESS = "LOAD_WALLET_ACCOUNT_LIST_SUCCESS";
export const LOAD_WALLET_ACCOUNT_LIST_FAIL = "LOAD_WALLET_ACCOUNT_LIST_FAIL";

export const LOAD_WALLET_ACCOUNT_COUNT = "LOAD_WALLET_ACCOUNT_COUNT";
export const LOAD_WALLET_ACCOUNT_COUNT_SUCCESS = "LOAD_WALLET_ACCOUNT_COUNT_SUCCESS";
export const LOAD_WALLET_ACCOUNT_COUNT_FAIL = "LOAD_WALLET_ACCOUNT_COUNT_FAIL";

export const UPDATE_WALLET_VERIFY_STATUS = "UPDATE_WALLET_VERIFY_STATUS";
export const UPDATE_WALLET_VERIFY_STATUS_SUCCESS = "UPDATE_WALLET_VERIFY_STATUS_SUCCESS";
export const UPDATE_WALLET_VERIFY_STATUS_FAIL = "UPDATE_WALLET_VERIFY_STATUS_FAIL";

export const WALLET_VERIFY_UPLOAD_DOC = "WALLET_VERIFY_UPLOAD_DOC";
export const WALLET_VERIFY_UPLOAD_DOC_SUCCESS = "WALLET_VERIFY_UPLOAD_DOC_SUCCESS";
export const WALLET_VERIFY_UPLOAD_DOC_FAIL = "WALLET_VERIFY_UPLOAD_DOC_FAIL";

export const UPDATE_WALLET_ALLOW_CREDITED = "UPDATE_WALLET_ALLOW_CREDITED";
export const UPDATE_WALLET_ALLOW_CREDITED_SUCCESS = "UPDATE_WALLET_ALLOW_CREDITED_SUCCESS";
export const UPDATE_WALLET_ALLOW_CREDITED_FAIL = "UPDATE_WALLET_ALLOW_CREDITED_FAIL";

export const LOAD_DRIVER_PENDING_PAYMENT = "LOAD_DRIVER_PENDING_PAYMENT";
export const LOAD_DRIVER_PENDING_PAYMENT_SUCCESS = "LOAD_DRIVER_PENDING_PAYMENT_SUCCESS";
export const LOAD_DRIVER_PENDING_PAYMENT_FAIL = "LOAD_DRIVER_PENDING_PAYMENT_FAIL";

export const APPROVE_DRIVER_PAYMENT = "APPROVE_DRIVER_PAYMENT";
export const APPROVE_DRIVER_PAYMENT_SUCCESS = "APPROVE_DRIVER_PAYMENT_SUCCESS";
export const APPROVE_DRIVER_PAYMENT_FAIL = "APPROVE_DRIVER_PAYMENT_FAIL";
/* ===== END  WALLET TRANSACTION ===== */

/* ===== USER ORGANIZATION ===== */
export const LOAD_USER_ORGANIZATION_LIST = "LOAD_USER_ORGANIZATION_LIST";
export const LOAD_USER_ORGANIZATION_LIST_SUCCESS = "LOAD_USER_ORGANIZATION_LIST_SUCCESS";
export const LOAD_USER_ORGANIZATION_LIST_FAIL = "LOAD_USER_ORGANIZATION_LIST_FAIL";

export const RESET_USER_ORGANIZATION_LIST = "RESET_USER_ORGANIZATION_LIST";
export const RESET_USER_ORGANIZATION_LIST_SUCCESS = "RESET_USER_ORGANIZATION_LIST_SUCCESS";

export const USER_ORGANIZATION_COUNT = "USER_ORGANIZATION_COUNT";
export const USER_ORGANIZATION_COUNT_SUCCESS = "USER_ORGANIZATION_COUNT_SUCCESS";
export const USER_ORGANIZATION_COUNT_FAIL = "USER_ORGANIZATION_COUNT_FAIL";

export const APPROVE_USER_ORGANIZATION = "APPROVE_USER_ORGANIZATION";
export const APPROVE_USER_ORGANIZATION_SUCCESS = "APPROVE_USER_ORGANIZATION_SUCCESS";
export const APPROVE_USER_ORGANIZATION_FAIL = "APPROVE_USER_ORGANIZATION_FAIL";

export const REJECT_USER_ORGANIZATION = "REJECT_USER_ORGANIZATION";
export const REJECT_USER_ORGANIZATION_SUCCESS = "REJECT_USER_ORGANIZATION_SUCCESS";
export const REJECT_USER_ORGANIZATION_FAIL = "REJECT_USER_ORGANIZATION_FAIL";

export const LOAD_USER_ORGANIZATION_DETAIL = "LOAD_USER_ORGANIZATION_DETAIL";
export const LOAD_USER_ORGANIZATION_DETAIL_SUCCESS = "LOAD_USER_ORGANIZATION_DETAIL_SUCCESS";
export const LOAD_USER_ORGANIZATION_DETAIL_FAIL = "LOAD_USER_ORGANIZATION_DETAIL_FAIL";

export const ADD_USER_ORGANIZATION = "ADD_USER_ORGANIZATION";
export const ADD_USER_ORGANIZATION_SUCCESS = "ADD_USER_ORGANIZATION_SUCCESS";
export const ADD_USER_ORGANIZATION_FAIL = "ADD_USER_ORGANIZATION_FAIL";

export const EDIT_USER_ORGANIZATION = "EDIT_USER_ORGANIZATION";
export const EDIT_USER_ORGANIZATION_SUCCESS = "EDIT_USER_ORGANIZATION_SUCCESS";
export const EDIT_USER_ORGANIZATION_FAIL = "EDIT_USER_ORGANIZATION_FAIL";

export const DELETE_USER_ORGANIZATION = "DELETE_USER_ORGANIZATION";
export const DELETE_USER_ORGANIZATION_SUCCESS = "DELETE_USER_ORGANIZATION_SUCCESS";
export const DELETE_USER_ORGANIZATION_FAIL = "DELETE_USER_ORGANIZATION_FAIL";


export const USER_ORGANIZATION_LANDFILL_DISTANCE = "USER_ORGANIZATION_LANDFILL_DISTANCE";
export const USER_ORGANIZATION_LANDFILL_DISTANCE_SUCCESS = "USER_ORGANIZATION_LANDFILL_DISTANCE_SUCCESS";
export const USER_ORGANIZATION_LANDFILL_DISTANCE_FAIL = "USER_ORGANIZATION_LANDFILL_DISTANCE_FAIL";

export const SET_USER_ORGANIZATION_NEGO_PRICE = "SET_USER_ORGANIZATION_NEGO_PRICE";
export const SET_USER_ORGANIZATION_NEGO_PRICE_SUCCESS = "SET_USER_ORGANIZATION_NEGO_PRICE_SUCCESS";
export const SET_USER_ORGANIZATION_NEGO_PRICE_FAIL = "SET_USER_ORGANIZATION_NEGO_PRICE_FAIL";

export const SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING = "SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING";
export const SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING_SUCCESS = "SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING_SUCCESS";
export const SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING_FAIL = "SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING_FAIL";

export const LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST = "LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST";
export const LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST_SUCCESS = "LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST_SUCCESS";
export const LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST_FAIL = "LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST_FAIL";

export const LOAD_NEGO_DUMP_WASTE_PRICE_LIST = "LOAD_NEGO_DUMP_WASTE_PRICE_LIST";
export const LOAD_NEGO_DUMP_WASTE_PRICE_LIST_SUCCESS = "LOAD_NEGO_DUMP_WASTE_PRICE_LIST_SUCCESS";
export const LOAD_NEGO_DUMP_WASTE_PRICE_LIST_FAIL = "LOAD_NEGO_DUMP_WASTE_PRICE_LIST_FAIL";

export const LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT = "LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT";
export const LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT_SUCCESS = "LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT_SUCCESS";
export const LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT_FAIL = "LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT_FAIL";

export const LOAD_NEGO_DUMP_WASTE_PRICE_PDF = "LOAD_NEGO_DUMP_WASTE_PRICE_PDF";
export const LOAD_NEGO_DUMP_WASTE_PRICE_PDF_SUCCESS = "LOAD_NEGO_DUMP_WASTE_PRICE_PDF_SUCCESS";
export const LOAD_NEGO_DUMP_WASTE_PRICE_PDF_FAIL = "LOAD_NEGO_DUMP_WASTE_PRICE_PDF_FAIL";

export const LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST = "LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST";
export const LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST_SUCCESS = "LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST_SUCCESS";
export const LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST_FAIL = "LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST_FAIL";

export const LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT = "LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT";
export const LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT_SUCCESS = "LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT_SUCCESS";
export const LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT_FAIL = "LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT_FAIL";

export const LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF = "LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF";
export const LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF_SUCCESS = "LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF_SUCCESS";
export const LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF_FAIL = "LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF_FAIL";

export const LOAD_NEGO_RECYCLING_PRICE_LIST = "LOAD_NEGO_RECYCLING_PRICE_LIST";
export const LOAD_NEGO_RECYCLING_PRICE_LIST_SUCCESS = "LOAD_NEGO_RECYCLING_PRICE_LIST_SUCCESS";
export const LOAD_NEGO_RECYCLING_PRICE_LIST_FAIL = "LOAD_NEGO_RECYCLING_PRICE_LIST_FAIL";

export const LOAD_NEGO_RECYCLING_PRICE_PDF = "LOAD_NEGO_RECYCLING_PRICE_PDF";
export const LOAD_NEGO_RECYCLING_PRICE_PDF_SUCCESS = "LOAD_NEGO_RECYCLING_PRICE_PDF_SUCCESS";
export const LOAD_NEGO_RECYCLING_PRICE_PDF_FAIL = "LOAD_NEGO_RECYCLING_PRICE_PDF_FAIL";

export const LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST = "LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST";
export const LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST_SUCCESS = "LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST_SUCCESS";
export const LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST_FAIL = "LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST_FAIL";

export const LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT = "LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT";
export const LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT_SUCCESS = "LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT_SUCCESS";
export const LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT_FAIL = "LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT_FAIL";

export const LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF = "LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF";
export const LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF_SUCCESS = "LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF_SUCCESS";
export const LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF_FAIL = "LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF_FAIL";



export const RESET_NEGO_DUMP_WASTE_PRICE_LIST = "RESET_NEGO_DUMP_WASTE_PRICE_LIST";
export const RESET_NEGO_DUMP_RECYCLING_PRICE_LIST = "RESET_NEGO_DUMP_RECYCLING_PRICE_LIST";
export const RESET_NEGO_RECYCLING_PRICE_LIST = "RESET_NEGO_RECYCLING_PRICE_LIST";

export const NEW_QUOTATION_COUNT = "NEW_QUOTATION_COUNT";
export const NEW_QUOTATION_COUNT_SUCCESS = "NEW_QUOTATION_COUNT_SUCCESS";
export const NEW_QUOTATION_COUNT_FAIL = "NEW_QUOTATION_COUNT_FAIL";

export const DUMPWASTE_QUOTATION_LIST = "DUMPWASTE_QUOTATION_LIST";
export const DUMPWASTE_QUOTATION_LIST_SUCCESS = "DUMPWASTE_QUOTATION_LIST_SUCCESS";
export const DUMPWASTE_QUOTATION_LIST_FAIL = "DUMPWASTE_QUOTATION_LIST_FAIL";

export const DUMPRECYCLING_QUOTATION_LIST = "DUMPRECYCLING_QUOTATION_LIST";
export const DUMPRECYCLING_QUOTATION_LIST_SUCCESS = "DUMPRECYCLING_QUOTATION_LIST_SUCCESS";
export const DUMPRECYCLING_QUOTATION_LIST_FAIL = "DUMPRECYCLING_QUOTATION_LIST_FAIL";

export const DUMPEXPRESS_QUOTATION_LIST = "DUMPEXPRESS_QUOTATION_LIST";
export const DUMPEXPRESS_QUOTATION_LIST_SUCCESS = "DUMPEXPRESS_QUOTATION_LIST_SUCCESS";
export const DUMPEXPRESS_QUOTATION_LIST_FAIL = "DUMPEXPRESS_QUOTATION_LIST_FAIL";

export const TERMINATE_QUOTATION = "TERMINATE_QUOTATION";
export const TERMINATE_QUOTATION_SUCCESS = "TERMINATE_QUOTATION_SUCCESS";
export const TERMINATE_QUOTATION_FAIL = "TERMINATE_QUOTATION_FAIL";

/* ===== END ORGANIZATION ===== */

/* ===== DRIVER  ===== */
export const LOAD_DRIVER_LIST = "LOAD_DRIVER_LIST";
export const LOAD_DRIVER_LIST_SUCCESS = "LOAD_DRIVER_LIST_SUCCESS";
export const LOAD_DRIVER_LIST_FAIL = "LOAD_DRIVER_LIST_FAIL";

export const DRIVER_COUNT = "DRIVER_COUNT";
export const DRIVER_COUNT_SUCCESS = "DRIVER_COUNT_SUCCESS";
export const DRIVER_COUNT_FAIL = "DRIVER_COUNT_FAIL";

export const LOAD_DRIVER_DETAIL = "LOAD_DRIVER_DETAIL";
export const LOAD_DRIVER_DETAIL_SUCCESS = "LOAD_DRIVER_DETAIL_SUCCESS";
export const LOAD_DRIVER_DETAIL_FAIL = "LOAD_DRIVER_DETAIL_FAIL";

export const RECOVERY_DRIVER = "RECOVERY_DRIVER";
export const RECOVERY_DRIVER_SUCCESS = "RECOVERY_DRIVER_SUCCESS";
export const RECOVERY_DRIVER_FAIL = "RECOVERY_DRIVER_FAIL";

export const UPLOAD_DRIVER_DOC = "UPLOAD_DRIVER_DOC";
export const UPLOAD_DRIVER_DOC_SUCCESS = "UPLOAD_DRIVER_DOC_SUCCESS";
export const UPLOAD_DRIVER_DOC_FAIL = "UPLOAD_DRIVER_DOC_FAIL";

export const DRIVER_DOCUMENTATION_COUNT = "DRIVER_DOCUMENTATION_COUNT";
export const DRIVER_DOCUMENTATION_COUNT_SUCCESS = "DRIVER_DOCUMENTATION_COUNT_SUCCESS";
export const DRIVER_DOCUMENTATION_COUNT_FAIL = "DRIVER_DOCUMENTATION_COUNT_FAIL"

export const DRIVER_DOCUMENTATION_LIST = "DRIVER_DOCUMENTATION_LIST";
export const DRIVER_DOCUMENTATION_LIST_SUCCESS = "DRIVER_DOCUMENTATION_LIST_SUCCESS";
export const DRIVER_DOCUMENTATION_LIST_FAIL = "DRIVER_DOCUMENTATION_LIST_FAIL";

export const RESET_DRIVER_DOCUMENTATION_LIST = "RESET_DRIVER_DOCUMENTATION_LIST";

export const APPROVE_DRIVER_DOCUMENTATION = "APPROVE_DRIVER_DOCUMENTATION";
export const APPROVE_DRIVER_DOCUMENTATION_SUCCESS = "APPROVE_DRIVER_DOCUMENTATION_SUCCESS";
export const APPROVE_DRIVER_DOCUMENTATION_FAIL = "APPROVE_DRIVER_DOCUMENTATION_FAIL";

export const REJECT_DRIVER_DOCUMENTATION = "REJECT_DRIVER_DOCUMENTATION";
export const REJECT_DRIVER_DOCUMENTATION_SUCCESS = "REJECT_DRIVER_DOCUMENTATION_SUCCESS";
export const REJECT_DRIVER_DOCUMENTATION_FAIL = "REJECT_DRIVER_DOCUMENTATION_FAIL";

export const APPLY_SCHEDULED_WASTE_SERVICE = "APPLY_SCHEDULED_WASTE_SERVICE";
export const APPLY_SCHEDULED_WASTE_SERVICE_SUCCESS = "APPLY_SCHEDULED_WASTE_SERVICE_SUCCESS";
export const APPLY_SCHEDULED_WASTE_SERVICE_FAIL = "APPLY_SCHEDULED_WASTE_SERVICE_FAIL";

export const ADD_DRIVER = "ADD_DRIVER";
export const ADD_DRIVER_SUCCESS = "ADD_DRIVER_SUCCESS";
export const ADD_DRIVER_FAIL = "ADD_DRIVER_FAIL";

export const EDIT_DRIVER_DETAIL = "EDIT_DRIVER_DETAIL";
export const EDIT_DRIVER_DETAIL_SUCCESS = "EDIT_DRIVER_DETAIL_SUCCESS";
export const EDIT_DRIVER_DETAIL_FAIL = "EDIT_DRIVER_DETAIL_FAIL";

export const REMOVE_DRIVER = "REMOVE_DRIVER";
export const REMOVE_DRIVER_SUCCESS = "REMOVE_DRIVER_SUCCESS";
export const REMOVE_DRIVER_FAIL = "REMOVE_DRIVER_FAIL";

export const EDIT_DRIVER_STATUS = "EDIT_DRIVER_STATUS";
export const EDIT_DRIVER_STATUS_SUCCESS = "EDIT_DRIVER_STATUS_SUCCESS";
export const EDIT_DRIVER_STATUS_FAIL = "EDIT_DRIVER_STATUS_FAIL";

export const EDIT_DRIVER_SHOW_VERI_CODE_STATUS = "EDIT_DRIVER_SHOW_VERI_CODE_STATUS";
export const EDIT_DRIVER_SHOW_VERI_CODE_STATUS_SUCCESS = "EDIT_DRIVER_SHOW_VERI_CODE_STATUS_SUCCESS";
export const EDIT_DRIVER_SHOW_VERI_CODE_STATUS_FAIL = "EDIT_DRIVER_SHOW_VERI_CODE_STATUS_FAIL";

export const EDIT_DRIVER_SUBSCRIPTION_STATUS = "EDIT_DRIVER_SUBSCRIPTION_STATUS";
export const EDIT_DRIVER_SUBSCRIPTION_STATUS_SUCCESS = "EDIT_DRIVER_SUBSCRIPTION_STATUS_SUCCESS";
export const EDIT_DRIVER_SUBSCRIPTION_STATUS_FAIL = "EDIT_DRIVER_SUBSCRIPTION_STATUS_FAIL";

export const EDIT_DRIVER_VERIFY_STATUS = "EDIT_DRIVER_VERIFY_STATUS";
export const EDIT_DRIVER_VERIFY_STATUS_SUCCESS = "EDIT_DRIVER_VERIFY_STATUS_SUCCESS";
export const EDIT_DRIVER_VERIFY_STATUS_FAIL = "EDIT_DRIVER_VERIFY_STATUS_FAIL";

export const REJECT_DRIVER_VERIFY_STATUS = "REJECT_DRIVER_VERIFY_STATUS";
export const REJECT_DRIVER_VERIFY_STATUS_SUCCESS = "REJECT_DRIVER_VERIFY_STATUS_SUCCESS";
export const REJECT_DRIVER_VERIFY_STATUS_FAIL = "REJECT_DRIVER_VERIFY_STATUS_FAIL";

export const DRIVER_TRACK_LIST = "DRIVER_TRACK_LIST";
export const DRIVER_TRACK_LIST_SUCCESS = "DRIVER_TRACK_LIST_SUCCESS";
export const DRIVER_TRACK_LIST_FAIL = "TDRIVER_TRACK_LIST_FAIL";

export const DRIVER_TRACK = "DRIVER_TRACK";
export const DRIVER_TRACK_SUCCESS = "DRIVER_TRACK_SUCCESS";
export const DRIVER_TRACK_FAIL = "DRIVER_TRACK_FAIL";

export const LOAD_DRIVER_SUMMARY = "LOAD_DRIVER_SUMMARY";
export const LOAD_DRIVER_SUMMARY_SUCCESS = "LOAD_DRIVER_SUMMARY_SUCCESS";
export const LOAD_DRIVER_SUMMARY_FAIL = "LOAD_DRIVER_SUMMARY_FAIL";

export const LOAD_DRIVER_INCENTIVE = "LOAD_DRIVER_INCENTIVE";
export const LOAD_DRIVER_INCENTIVE_SUCCESS = "LOAD_DRIVER_INCENTIVE_SUCCESS";
export const LOAD_DRIVER_INCENTIVE_FAIL = "LOAD_DRIVER_INCENTIVE_FAIL";

export const LOAD_DRIVER_LORRY_DUMPSTER = "LOAD_DRIVER_LORRY_DUMPSTER";
export const LOAD_DRIVER_LORRY_DUMPSTER_SUCCESS = "LOAD_DRIVER_LORRY_DUMPSTER_SUCCESS";
export const LOAD_DRIVER_LORRY_DUMPSTER_FAIL = "LOAD_DRIVER_LORRY_DUMPSTER_FAIL";

export const LOAD_USER_ORDER_HISTORY = "LOAD_USER_ORDER_HISTORY";
export const LOAD_USER_ORDER_HISTORY_SUCCESS = "LOAD_USER_ORDER_HISTORY_SUCCESS";
export const LOAD_USER_ORDER_HISTORY_FAIL = "LOAD_USER_ORDER_HISTORY_FAIL";

/* ===== END  ===== */

/* ===== DRIVER TRANSPORT LICENSE ===== */
export const LOAD_DRIVER_TRANSPORT_LICENSE_LIST = "LOAD_DRIVER_TRANSPORT_LICENSE_LIST";
export const LOAD_DRIVER_TRANSPORT_LICENSE_LIST_SUCCESS = "LOAD_DRIVER_TRANSPORT_LICENSE_LIST_SUCCESS";
export const LOAD_DRIVER_TRANSPORT_LICENSE_LIST_FAIL = "LOAD_DRIVER_TRANSPORT_LICENSE_LIST_FAIL";

export const DRIVER_TRANSPORT_LICENSE_COUNT = "DRIVER_TRANSPORT_LICENSE_COUNT";
export const DRIVER_TRANSPORT_LICENSE_COUNT_SUCCESS = "DRIVER_TRANSPORT_LICENSE_COUNT_SUCCESS";
export const DRIVER_TRANSPORT_LICENSE_COUNT_FAIL = "DRIVER_TRANSPORT_LICENSE_COUNT_FAIL";

export const DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED = "DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED";
export const DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED_SUCCESS = "DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED_SUCCESS";
export const DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED_FAIL = "DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED_FAIL";

export const REJECT_DRIVER_TRANSPORT_LICENSE = "REJECT_DRIVER_TRANSPORT_LICENSE";
export const REJECT_DRIVER_TRANSPORT_LICENSE_SUCCESS = "REJECT_DRIVER_TRANSPORT_LICENSE_SUCCESS";
export const REJECT_DRIVER_TRANSPORT_LICENSE_FAIL = "REJECT_DRIVER_TRANSPORT_LICENSE_FAIL";

export const DRIVER_SCHEDULED_WASTE_SET_RECOVERER = "DRIVER_SCHEDULED_WASTE_SET_RECOVERER";
export const DRIVER_SCHEDULED_WASTE_SET_RECOVERER_SUCCESS = "DRIVER_SCHEDULED_WASTE_SET_RECOVERER_SUCCESS";
export const DRIVER_SCHEDULED_WASTE_SET_RECOVERER_FAIL = "DRIVER_SCHEDULED_WASTE_SET_RECOVERER_FAIL";
/* ===== END  ===== */


/* ===== GRADER  ===== */
export const LOAD_GRADER_LIST = "LOAD_GRADER_LIST";
export const LOAD_GRADER_LIST_SUCCESS = "LOAD_GRADER_LIST_SUCCESS";
export const LOAD_GRADER_LIST_FAIL = "LOAD_GRADER_LIST_FAIL";

export const GRADER_COUNT = "GRADER_COUNT";
export const GRADER_COUNT_SUCCESS = "GRADER_COUNT_SUCCESS";
export const GRADER_COUNT_FAIL = "GRADER_COUNT_FAIL";

export const LOAD_GRADER_DETAIL = "LOAD_GRADER_DETAIL";
export const LOAD_GRADER_DETAIL_SUCCESS = "LOAD_GRADER_DETAIL_SUCCESS";
export const LOAD_GRADER_DETAIL_FAIL = "LOAD_GRADER_DETAIL_FAIL";

export const EDIT_GRADER_DETAIL = "EDIT_GRADER_DETAIL";
export const EDIT_GRADER_DETAIL_SUCCESS = "EDIT_GRADER_DETAIL_SUCCESS";
export const EDIT_GRADER_DETAIL_FAIL = "EDIT_GRADER_DETAIL_FAIL";

export const REMOVE_GRADER = "REMOVE_GRADER";
export const REMOVE_GRADER_SUCCESS = "REMOVE_GRADER_SUCCESS";
export const REMOVE_GRADER_FAIL = "REMOVE_GRADER_FAIL";

export const RECOVERY_GRADER = "RECOVERY_GRADER";
export const RECOVERY_GRADER_SUCCESS = "RECOVERY_GRADER_SUCCESS";
export const RECOVERY_GRADER_FAIL = "RECOVERY_GRADER_FAIL";

export const EDIT_GRADER_STATUS = "EDIT_GRADER_STATUS";
export const EDIT_GRADER_STATUS_SUCCESS = "EDIT_GRADER_STATUS_SUCCESS";
export const EDIT_GRADER_STATUS_FAIL = "EDIT_GRADER_STATUS_FAIL";

export const PROCEED_DEACTIVATE_CONFIRM = "PROCEED_DEACTIVATE_CONFIRM";
export const PROCEED_UPDATE_ORDER_LANDFILL = "PROCEED_UPDATE_ORDER_LANDFILL";
export const DIMISS_UPDATE_ORDER_LANDFILL = "DIMISS_UPDATE_ORDER_LANDFILL";

export const EDIT_GRADER_VERIFY_STATUS = "EDIT_GRADER_VERIFY_STATUS";
export const EDIT_GRADER_VERIFY_STATUS_SUCCESS = "EDIT_GRADER_VERIFY_STATUS_SUCCESS";
export const EDIT_GRADER_VERIFY_STATUS_FAIL = "EDIT_GRADER_VERIFY_STATUS_FAIL";

export const REJECT_GRADER_VERIFY_STATUS = "REJECT_GRADER_VERIFY_STATUS";
export const REJECT_GRADER_VERIFY_STATUS_SUCCESS = "REJECT_GRADER_VERIFY_STATUS_SUCCESS";
export const REJECT_GRADER_VERIFY_STATUS_FAIL = "REJECT_GRADER_VERIFY_STATUS_FAIL";

export const UPLOAD_GRADER_DOC = "UPLOAD_GRADER_DOC";
export const UPLOAD_GRADER_DOC_SUCCESS = "UPLOAD_GRADER_DOC_SUCCESS";
export const UPLOAD_GRADER_DOC_FAIL = "UPLOAD_GRADER_DOC_FAIL";

export const GRADER_DOCUMENTATION_COUNT = "GRADER_DOCUMENTATION_COUNT";
export const GRADER_DOCUMENTATION_COUNT_SUCCESS = "GRADER_DOCUMENTATION_COUNT_SUCCESS";
export const GRADER_DOCUMENTATION_COUNT_FAIL = "GRADER_DOCUMENTATION_COUNT_FAIL"

export const GRADER_DOCUMENTATION_LIST = "GRADER_DOCUMENTATION_LIST";
export const GRADER_DOCUMENTATION_LIST_SUCCESS = "GRADER_DOCUMENTATION_LIST_SUCCESS";
export const GRADER_DOCUMENTATION_LIST_FAIL = "GRADER_DOCUMENTATION_LIST_FAIL";

export const RESET_GRADER_DOCUMENTATION_LIST = "RESET_GRADER_DOCUMENTATION_LIST";

export const APPROVE_GRADER_DOCUMENTATION = "APPROVE_GRADER_DOCUMENTATION";
export const APPROVE_GRADER_DOCUMENTATION_SUCCESS = "APPROVE_GRADER_DOCUMENTATION_SUCCESS";
export const APPROVE_GRADER_DOCUMENTATION_FAIL = "APPROVE_GRADER_DOCUMENTATION_FAIL";

export const REJECT_GRADER_DOCUMENTATION = "REJECT_GRADER_DOCUMENTATION";
export const REJECT_GRADER_DOCUMENTATION_SUCCESS = "REJECT_GRADER_DOCUMENTATION_SUCCESS";
export const REJECT_GRADER_DOCUMENTATION_FAIL = "REJECT_GRADER_DOCUMENTATION_FAIL";



export const LOAD_GRADER_ZONE_LIST = "LOAD_GRADER_ZONE_LIST";
export const LOAD_GRADER_ZONE_LIST_SUCCESS = "LOAD_GRADER_ZONE_LIST_SUCCESS";
export const LOAD_GRADER_ZONE_LIST_FAIL = "LOAD_GRADER_ZONE_LIST_FAIL";

export const GRADER_ZONE_COUNT = "GRADER_ZONE_COUNT";
export const GRADER_ZONE_COUNT_SUCCESS = "GRADER_ZONE_COUNT_SUCCESS";
export const GRADER_ZONE_COUNT_FAIL = "GRADER_ZONE_COUNT_FAIL";

export const LOAD_EXPRESS_PACKING_HISTORY_LIST = "LOAD_EXPRESS_PACKING_HISTORY_LIST";
export const LOAD_EXPRESS_PACKING_HISTORY_LIST_SUCCESS = "LOAD_EXPRESS_PACKING_HISTORY_LIST_SUCCESS";
export const LOAD_EXPRESS_PACKING_HISTORY_LIST_FAIL = "LOAD_EXPRESS_PACKING_HISTORY_LIST_FAIL";

export const LOAD_EXPRESS_PACKING_LIST_DETAIL = "LOAD_EXPRESS_PACKING_LIST_DETAIL";
export const LOAD_EXPRESS_PACKING_LIST_DETAIL_SUCCESS = "LOAD_EXPRESS_PACKING_LIST_DETAIL_SUCCESS";
export const LOAD_EXPRESS_PACKING_LIST_DETAIL_FAIL = "LOAD_EXPRESS_PACKING_LIST_DETAIL_FAIL";

export const GET_EXPRESS_PACKING_LIST_PDF = "GET_EXPRESS_PACKING_LIST_PDF";
export const GET_EXPRESS_PACKING_LIST_PDF_SUCCESS = "GET_EXPRESS_PACKING_LIST_PDF_SUCCESS";
export const GET_EXPRESS_PACKING_LIST_PDF_FAIL = "GET_EXPRESS_PACKING_LIST_PDF_FAIL";

export const GET_EXPRESS_PACKING_LIST_STACK = "GET_EXPRESS_PACKING_LIST_STACK";
export const GET_EXPRESS_PACKING_LIST_STACK_SUCCESS = "GET_EXPRESS_PACKING_LIST_STACK_SUCCESS";
export const GET_EXPRESS_PACKING_LIST_STACK_FAIL = "GET_EXPRESS_PACKING_LIST_STACK_FAIL";

export const LOAD_RECYCLING_PACKING_HISTORY_LIST = "LOAD_RECYCLING_PACKING_HISTORY_LIST";
export const LOAD_RECYCLING_PACKING_HISTORY_LIST_SUCCESS = "LOAD_RECYCLING_PACKING_HISTORY_LIST_SUCCESS";
export const LOAD_RECYCLING_PACKING_HISTORY_LIST_FAIL = "LOAD_RECYCLING_PACKING_HISTORY_LIST_FAIL";

export const LOAD_RECYCLING_PACKING_LIST_DETAIL = "LOAD_RECYCLING_PACKING_LIST_DETAIL";
export const LOAD_RECYCLING_PACKING_LIST_DETAIL_SUCCESS = "LOAD_RECYCLING_PACKING_LIST_DETAIL_SUCCESS";
export const LOAD_RECYCLING_PACKING_LIST_DETAIL_FAIL = "LOAD_RECYCLING_PACKING_LIST_DETAIL_FAIL";

export const GET_RECYCLING_PACKING_LIST_PDF = "GET_RECYCLING_PACKING_LIST_PDF";
export const GET_RECYCLING_PACKING_LIST_PDF_SUCCESS = "GET_RECYCLING_PACKING_LIST_PDF_SUCCESS";
export const GET_RECYCLING_PACKING_LIST_PDF_FAIL = "GET_RECYCLING_PACKING_LIST_PDF_FAIL";

export const GET_RECYCLING_PACKING_LIST_STACK = "GET_RECYCLING_PACKING_LIST_STACK";
export const GET_RECYCLING_PACKING_LIST_STACK_SUCCESS = "GET_RECYCLING_PACKING_LIST_STACK_SUCCESS";
export const GET_RECYCLING_PACKING_LIST_STACK_FAIL = "GET_RECYCLING_PACKING_LIST_STACK_FAIL";

/* ===== END  ===== */

/* ===== ORDER  ===== */
export const LOAD_ORDER_LIST = "LOAD_ORDER_LIST";
export const LOAD_ORDER_LIST_SUCCESS = "LOAD_ORDER_LIST_SUCCESS";
export const LOAD_ORDER_LIST_FAIL = "LOAD_ORDER_LIST_FAIL";

export const ORDER_COUNT = "ORDER_COUNT";
export const ORDER_COUNT_SUCCESS = "ORDER_COUNT_SUCCESS";
export const ORDER_COUNT_FAIL = "ORDER_COUNT_FAIL";

export const LOAD_ORDER_DETAIL = "LOAD_ORDER_DETAIL";
export const LOAD_ORDER_DETAIL_SUCCESS = "LOAD_ORDER_DETAIL_SUCCESS";
export const LOAD_ORDER_DETAIL_FAIL = "LOAD_ORDER_DETAIL_FAIL";

export const LOAD_ORDER_DETAIL_TRIP_GROUP = "LOAD_ORDER_DETAIL_TRIP_GROUP";
export const LOAD_ORDER_DETAIL_TRIP_GROUP_SUCCESS = "LOAD_ORDER_DETAIL_TRIP_GROUP_SUCCESS";
export const LOAD_ORDER_DETAIL_TRIP_GROUP_FAIL = "LOAD_ORDER_DETAIL_TRIP_GROUP_FAIL";

export const LOAD_ORDER_DETAIL_TRIP_LIST = "LOAD_ORDER_DETAIL_TRIP_LIST";
export const LOAD_ORDER_DETAIL_TRIP_LIST_SUCCESS = "LOAD_ORDER_DETAIL_TRIP_LIST_SUCCESS";
export const LOAD_ORDER_DETAIL_TRIP_LIST_FAIL = "LOAD_ORDER_DETAIL_TRIP_LIST_FAIL";

export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";

export const CANCEL_ORDER = "CANCEL_DETAIL";
export const CANCEL_ORDER_SUCCESS = "CANCEL_DETAIL_SUCCESS";
export const CANCEL_ORDER_FAIL = "CANCEL_DETAIL_FAIL";

export const GET_ORDER_CANCELLATION_DETAIL = "GET_ORDER_CANCELLATION_DETAIL";
export const GET_ORDER_CANCELLATION_DETAIL_SUCCESS = "GET_ORDER_CANCELLATION_DETAIL_SUCCESS";
export const GET_ORDER_CANCELLATION_DETAIL_FAIL = "GET_ORDER_CANCELLATION_DETAIL_FAIL";

export const ORDER_ASSIGN_DRIVER = "ORDER_ASSIGN_DRIVER";
export const ORDER_ASSIGN_DRIVER_SUCCESS = "ORDER_ASSIGN_DRIVER_SUCCESS";
export const ORDER_ASSIGN_DRIVER_FAIL = "ORDER_ASSIGN_DRIVER_FAIL";

export const LOAD_ORDER_TRIP_LIST = "LOAD_ORDER_TRIP_LIST";
export const LOAD_ORDER_TRIP_LIST_SUCCESS = "LOAD_ORDER_TRIP_LIST_SUCCESS";
export const LOAD_ORDER_TRIP_LIST_FAIL = "LOAD_ORDER_TRIP_LIST_FAIL";

export const ORDER_TRIP_COUNT = "ORDER_TRIP_COUNT";
export const ORDER_TRIP_COUNT_SUCCESS = "ORDER_TRIP_COUNT_SUCCESS";
export const ORDER_TRIP_COUNT_FAIL = "ORDER_TRIP_COUNT_FAIL";

export const LOAD_DRIVER_TRIP_LIST = "LOAD_DRIVER_TRIP_LIST";
export const LOAD_DRIVER_TRIP_LIST_SUCCESS = "LOAD_DRIVER_TRIP_LIST_SUCCESS";
export const LOAD_DRIVER_TRIP_LIST_FAIL = "LOAD_DRIVER_TRIP_LIST_FAIL";

export const UPDATE_TRIP_PLACEMENT = "UPDATE_TRIP_PLACEMENT";
export const UPDATE_TRIP_PLACEMENT_SUCCESS = "UPDATE_TRIP_PLACEMENT_SUCCESS";
export const UPDATE_TRIP_PLACEMENT_FAIL = "UPDATE_TRIP_PLACEMENT_FAIL";

export const UPDATE_TRIP_FILLING = "UPDATE_TRIP_FILLING";
export const UPDATE_TRIP_FILLING_SUCCESS = "UPDATE_TRIP_FILLING_SUCCESS";
export const UPDATE_TRIP_FILLING_FAIL = "UPDATE_TRIP_FILLING_FAIL";

export const ROLLBACK_TRIP_FILLING = "ROLLBACK_TRIP_FILLING";
export const ROLLBACK_TRIP_FILLING_SUCCESS = "ROLLBACK_TRIP_FILLING_SUCCESS";
export const ROLLBACK_TRIP_FILLING_FAIL = "ROLLBACK_TRIP_FILLING_FAIL";

export const UPDATE_TRIP_COLLECTION = "UPDATE_TRIP_COLLECTION";
export const UPDATE_TRIP_COLLECTION_SUCCESS = "UPDATE_TRIP_COLLECTION_SUCCESS";
export const UPDATE_TRIP_COLLECTION_FAIL = "UPDATE_TRIP_COLLECTION_FAIL";

export const UPDATE_TRIP_DISPOSAL = "UPDATE_TRIP_DISPOSAL";
export const UPDATE_TRIP_DISPOSAL_SUCCESS = "UPDATE_TRIP_DISPOSAL_SUCCESS";
export const UPDATE_TRIP_DISPOSAL_FAIL = "UPDATE_TRIP_DISPOSAL_FAIL";

export const REUPLOAD_DISPOSAL_SLIP = "REUPLOAD_DISPOSAL_SLIP";
export const REUPLOAD_DISPOSAL_SLIP_SUCCESS = "REUPLOAD_DISPOSAL_SLIP_SUCCESS";
export const REUPLOAD_DISPOSAL_SLIP_FAIL = "REUPLOAD_DISPOSAL_SLIP_FAIL";

export const REUPLOAD_TRIP_PROOF = "REUPLOAD_TRIP_PROOF";
export const REUPLOAD_TRIP_PROOF_SUCCESS = "REUPLOAD_TRIP_PROOF_SUCCESS";
export const REUPLOAD_TRIP_PROOF_FAIL = "REUPLOAD_TRIP_PROOF_FAIL";

export const REUPLOAD_TRIP_WEIGHING_SLIP = "REUPLOAD_TRIP_WEIGHING_SLIP";
export const REUPLOAD_TRIP_WEIGHING_SLIP_SUCCESS = "REUPLOAD_TRIP_WEIGHING_SLIP_SUCCESS";
export const REUPLOAD_TRIP_WEIGHING_SLIP_FAIL = "REUPLOAD_TRIP_WEIGHING_SLIP_FAIL";

export const SKIP_SIGNATURE = "SKIP_SIGNATURE";
export const SKIP_SIGNATURE_SUCCESS = "SKIP_SIGNATURE_SUCCESS";
export const SKIP_SIGNATURE_FAIL = "SKIP_SIGNATURE_FAIL";

export const UPDATE_DUMPSTER_EMPTY = "UPDATE_DUMPSTER_EMPTY";
export const UPDATE_DUMPSTER_EMPTY_SUCCESS = "UPDATE_DUMPSTER_EMPTY_SUCCESS";
export const UPDATE_DUMPSTER_EMPTY_FAIL = "UPDATE_DUMPSTER_EMPTY_FAIL";

export const UPDATE_TRIP_LOADING_COLLECTION = "UPDATE_TRIP_LOADING_COLLECTION";
export const UPDATE_TRIP_LOADING_COLLECTION_SUCCESS = "UPDATE_TRIP_LOADING_COLLECTION_SUCCESS";
export const UPDATE_TRIP_LOADING_COLLECTION_FAIL = "UPDATE_TRIP_LOADING_COLLECTION_FAIL";

export const TRACK_DRIVER = "TRACK_DRIVER";
export const TRACK_DRIVER_SUCCESS = "TRACK_DRIVER_SUCCESS";
export const TRACK_DRIVER_FAIL = "TRACK_DRIVER_FAIL";

export const TRIP_SET_ARRIVED = "TRIP_SET_ARRIVED";
export const TRIP_SET_ARRIVED_SUCCESS = "TRIP_SET_ARRIVED_SUCCESS";
export const TRIP_SET_ARRIVED_FAIL = "TRIP_SET_ARRIVED_FAIL";

export const TRIP_UPDATE_GOING_NOW = "TRIP_UPDATE_GOING_NOW";
export const TRIP_UPDATE_GOING_NOW_SUCCESS = "TRIP_UPDATE_GOING_NOW_SUCCESS";
export const TRIP_UPDATE_GOING_NOW_FAIL = "TRIP_UPDATE_GOING_NOW_FAIL";

export const TRIP_UPDATE_GOING_NOW_ADVANCE = "TRIP_UPDATE_GOING_NOW_ADVANCE";
export const TRIP_UPDATE_GOING_NOW_ADVANCE_SUCCESS = "TRIP_UPDATE_GOING_NOW_ADVANCE_SUCCESS";
export const TRIP_UPDATE_GOING_NOW_ADVANCE_FAIL = "TRIP_UPDATE_GOING_NOW_ADVANCE_FAIL";

export const TRIP_ROLLBACK_GOING_NOW = "TRIP_ROLLBACK_GOING_NOW";
export const TRIP_ROLLBACK_GOING_NOW_SUCCESS = "TRIP_ROLLBACK_GOING_NOW_SUCCESS";
export const TRIP_ROLLBACK_GOING_NOW_FAIL = "TRIP_ROLLBACK_GOING_NOW_FAIL";

export const TRIP_SET_STARTED = "TRIP_SET_STARTED";
export const TRIP_SET_STARTED_SUCCESS = "TRIP_SET_STARTED_SUCCESS";
export const TRIP_SET_STARTED_FAIL = "TRIP_SET_STARTED_FAIL";

export const EDIT_TRIP_WEIGHING_FIGURE = "EDIT_TRIP_WEIGHING_FIGURE";
export const EDIT_TRIP_WEIGHING_FIGURE_SUCCESS = "EDIT_TRIP_WEIGHING_FIGURE_SUCCESS";
export const EDIT_TRIP_WEIGHING_FIGURE_FAIL = "EDIT_TRIP_WEIGHING_FIGURE_FAIL";

export const RESET_COLLECTION_TRIP = "RESET_COLLECTION_TRIP";
export const RESET_COLLECTION_TRIP_SUCCESS = "RESET_COLLECTION_TRIP_SUCCESS";
export const RESET_COLLECTION_TRIP_FAIL = "RESET_COLLECTION_TRIP_FAIL";

export const ORDER_TRACK_REPORT = "ORDER_TRACK_REPORT";
export const ORDER_TRACK_REPORT_SUCCESS = "ORDER_TRACK_REPORT_SUCCESS";
export const ORDER_TRACK_REPORT_FAIL = "ORDER_TRACK_REPORT_FAIL";

export const ORDER_TRIP_STORE_RECEIPT = "ORDER_TRIP_STORE_RECEIPT";
export const ORDER_TRIP_STORE_RECEIPT_SUCCESS = "ORDER_TRIP_STORE_RECEIPT_SUCCESS";
export const ORDER_TRIP_STORE_RECEIPT_FAIL = "ORDER_TRIP_STORE_RECEIPT_FAIL";

export const ORDER_TRIP_STORE_RECEIPT_STACK = "ORDER_TRIP_STORE_RECEIPT_STACK";
export const ORDER_TRIP_STORE_RECEIPT_STACK_SUCCESS = "ORDER_TRIP_STORE_RECEIPT_STACK_SUCCESS";
export const ORDER_TRIP_STORE_RECEIPT_STACK_FAIL = "ORDER_TRIP_STORE_RECEIPT_STACK_FAIL";

export const ORDER_TRIP_WASTE_RECEIPT = "ORDER_TRIP_WASTE_RECEIPT";
export const ORDER_TRIP_WASTE_RECEIPT_SUCCESS = "ORDER_TRIP_WASTE_RECEIPT_SUCCESS";
export const ORDER_TRIP_WASTE_RECEIPT_FAIL = "ORDER_TRIP_WASTE_RECEIPT_FAIL";

export const ORDER_TRIP_WASTE_RECEIPT_STACK = "ORDER_TRIP_WASTE_RECEIPT_STACK";
export const ORDER_TRIP_WASTE_RECEIPT_STACK_SUCCESS = "ORDER_TRIP_WASTE_RECEIPT_STACK_SUCCESS";
export const ORDER_TRIP_WASTE_RECEIPT_STACK_FAIL = "ORDER_TRIP_WASTE_RECEIPT_STACK_FAIL";

export const ORDER_WASTE_SUMMARY = "ORDER_WASTE_SUMMARY";
export const ORDER_WASTE_SUMMARY_SUCCESS = "ORDER_WASTE_SUMMARY_SUCCESS";
export const ORDER_WASTE_SUMMARY_FAIL = "ORDER_WASTE_SUMMARY_FAIL";

export const LOAD_TRANSACTION_LIST = "LOAD_TRANSACTION_LIST";
export const LOAD_TRANSACTION_LIST_SUCCESS = "LOAD_TRANSACTION_LIST_SUCCESS";
export const LOAD_TRANSACTION_LIST_FAIL = "LOAD_TRANSACTION_LIST_FAIL";

export const LOAD_DUITNOW_TRANSACTION_LIST = "LOAD_DUITNOW_TRANSACTION_LIST";
export const LOAD_DUITNOW_TRANSACTION_LIST_SUCCESS = "LOAD_DUITNOW_TRANSACTION_LIST_SUCCESS";
export const LOAD_DUITNOW_TRANSACTION_LIST_FAIL = "LOAD_DUITNOW_TRANSACTION_LIST_FAIL";

export const LOAD_ORDER_SUMMARY = "LOAD_ORDER_SUMMARY";
export const LOAD_ORDER_SUMMARY_SUCCESS = "LOAD_ORDER_SUMMARY_SUCCESS";
export const LOAD_ORDER_SUMMARY_FAIL = "LOAD_ORDER_SUMMARY_FAIL";

export const LOAD_ORDER_SUMMARY_CHART = "LOAD_ORDER_SUMMARY_CHART";
export const LOAD_ORDER_SUMMARY_CHART_SUCCESS = "LOAD_ORDER_SUMMARY_CHART_SUCCESS";
export const LOAD_ORDER_SUMMARY_CHART_FAIL = "LOAD_ORDER_SUMMARY_CHART_FAIL";

export const LOAD_ORDER_SALE_SUMMARY = "LOAD_ORDER_SALE_SUMMARY";
export const LOAD_ORDER_SALE_SUMMARY_SUCCESS = "LOAD_ORDER_SALE_SUMMARY_SUCCESS";
export const LOAD_ORDER_SALE_SUMMARY_FAIL = "LOAD_ORDER_SALE_SUMMARY_FAIL";


export const LOAD_ADJUSTMENT_AGREEMENT_LIST = "LOAD_ADJUSTMENT_AGREEMENT_LIST";
export const LOAD_ADJUSTMENT_AGREEMENT_LIST_SUCCESS = "LOAD_ADJUSTMENT_AGREEMENT_LIST_SUCCESS";
export const LOAD_ADJUSTMENT_AGREEMENT_LIST_FAIL = "LOAD_ADJUSTMENT_AGREEMENT_LIST_FAIL";

export const LOAD_ADJUSTMENT_AGREEMENT_DETAIL = "LOAD_ADJUSTMENT_AGREEMENT_DETAIL";
export const LOAD_ADJUSTMENT_AGREEMENT_DETAIL_SUCCESS = "LOAD_ADJUSTMENT_AGREEMENT_DETAIL_SUCCESS";
export const LOAD_ADJUSTMENT_AGREEMENT_DETAIL_FAIL = "LOAD_ADJUSTMENT_AGREEMENT_DETAIL_FAIL";

export const AGREEMENT_PROCESS_REFUND = "AGREEMENT_PROCESS_REFUND";
export const AGREEMENT_PROCESS_REFUND_SUCCESS = "AGREEMENT_PROCESS_REFUND_SUCCESS";
export const AGREEMENT_PROCESS_REFUND_FAIL = "AGREEMENT_PROCESS_REFUND_FAIL";

export const LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST = "LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST";
export const LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST_SUCCESS = "LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST_SUCCESS";
export const LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST_FAIL = "LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST_FAIL";



export const CANCEL_ORDER_REFUND_LIST = "CANCEL_ORDER_REFUND_LIST";
export const CANCEL_ORDER_REFUND_LIST_SUCCESS = "CANCEL_ORDER_REFUND_LIST_SUCCESS";
export const CANCEL_ORDER_REFUND_LIST_FAIL = "CANCEL_ORDER_REFUND_LIST_FAIL";

export const CANCEL_ORDER_REFUND_DETAIL = "CANCEL_ORDER_REFUND_DETAIL";
export const CANCEL_ORDER_REFUND_DETAIL_SUCCESS = "CANCEL_ORDER_REFUND_DETAIL_SUCCESS";
export const CANCEL_ORDER_REFUND_DETAIL_FAIL = "CANCEL_ORDER_REFUND_DETAIL_FAIL";

export const CANCEL_ORDER_PROCESS_REFUND = "CANCEL_ORDER_PROCESS_REFUND";
export const CANCEL_ORDER_PROCESS_REFUND_SUCCESS = "CANCEL_ORDER_PROCESS_REFUND_SUCCESS";
export const CANCEL_ORDER_PROCESS_REFUND_FAIL = "CANCEL_ORDER_PROCESS_REFUND_FAIL";


export const REASSIGN_TRIP = "REASSIGN_TRIP";
export const REASSIGN_TRIP_SUCCESS = "REASSIGN_TRIP_SUCCESS";
export const REASSIGN_TRIP_FAIL = "REASSIGN_TRIP_FAIL";

export const MAKE_IT_TODAY_TRIP = "MAKE_IT_TODAY_TRIP";
export const MAKE_IT_TODAY_TRIP_SUCCESS = "MAKE_IT_TODAY_TRIP_SUCCESS";
export const MAKE_IT_TODAY_TRIP_FAIL = "MAKE_IT_TODAY_TRIP_FAIL";



export const BILLING_LIST = "BILLING_LIST";
export const BILLING_LIST_SUCCESS = "BILLING_LIST_SUCCESS";
export const BILLING_LIST_FAIL = "BILLING_LIST_FAIL";

export const ORDER_INVOICE = "ORDER_INVOICE";
export const ORDER_INVOICE_SUCCESS = "ORDER_INVOICE_SUCCESS";
export const ORDER_INVOICE_FAIL = "ORDER_INVOICE_FAIL";

export const ORDER_INVOICE_STACK = "ORDER_INVOICE_STACK";
export const ORDER_INVOICE_STACK_SUCCESS = "ORDER_INVOICE_STACK_SUCCESS";
export const ORDER_INVOICE_STACK_FAIL = "ORDER_INVOICE_STACK_FAIL";

export const AGREEMENT_DEBIT_CREDIT_NOTE_LOAD = "AGREEMENT_DEBIT_CREDIT_NOTE_LOAD";
export const AGREEMENT_DEBIT_CREDIT_NOTE_LOAD_SUCCESS = "AGREEMENT_DEBIT_CREDIT_NOTE_LOAD_SUCCESS";
export const AGREEMENT_DEBIT_CREDIT_NOTE_LOAD_FAIL = "AGREEMENT_DEBIT_CREDIT_NOTE_LOAD_FAIL";

export const AGREEMENT_DEBIT_CREDIT_NOTE_STACK = "AGREEMENT_DEBIT_CREDIT_NOTE_STACK";
export const AGREEMENT_DEBIT_CREDIT_NOTE_STACK_SUCCESS = "AGREEMENT_DEBIT_CREDIT_NOTE_STACK_SUCCESS";
export const AGREEMENT_DEBIT_CREDIT_NOTE_STACK_FAIL = "AGREEMENT_DEBIT_CREDIT_NOTE_STACK_FAIL";


export const SPECIAL_INVOICE_LIST = "SPECIAL_INVOICE_LIST";
export const SPECIAL_INVOICE_LIST_SUCCESS = "SPECIAL_INVOICE_LIST_SUCCESS";
export const SPECIAL_INVOICE_LIST_FAIL = "SPECIAL_INVOICE_LIST_FAIL";

export const GET_SPECIAL_INVOICE = "GET_SPECIAL_INVOICE";
export const GET_SPECIAL_INVOICE_SUCCESS = "GET_SPECIAL_INVOICE_SUCCESS";
export const GET_SPECIAL_INVOICE_FAIL = "GET_SPECIAL_INVOICE_FAIL";

export const GET_SPECIAL_INVOICE_STACK = "GET_SPECIAL_INVOICE_STACK";
export const GET_SPECIAL_INVOICE_STACK_SUCCESS = "GET_SPECIAL_INVOICE_STACK_SUCCESS";
export const GET_SPECIAL_INVOICE_STACK_FAIL = "GET_SPECIAL_INVOICE_STACK_FAIL";


export const PURCHASE_BILL_LIST = "PURCHASE_BILL_LIST";
export const PURCHASE_BILL_LIST_SUCCESS = "PURCHASE_BILL_LIST_SUCCESS";
export const PURCHASE_BILL_LIST_FAIL = "PURCHASE_BILL_LIST_FAIL";

export const PURCHASE_BILL_DUMP_RECYCLING = "PURCHASE_BILL_DUMP_RECYCLING";
export const PURCHASE_BILL_DUMP_RECYCLING_SUCCESS = "PURCHASE_BILL_DUMP_RECYCLING_SUCCESS";
export const PURCHASE_BILL_DUMP_RECYCLING_FAIL = "PURCHASE_BILL_DUMP_RECYCLING_FAIL";

export const PURCHASE_BILL_DUMP_RECYCLING_STACK = "PURCHASE_BILL_DUMP_RECYCLING_STACK";
export const PURCHASE_BILL_DUMP_RECYCLING_STACK_SUCCESS = "PURCHASE_BILL_DUMP_RECYCLING_STACK_SUCCESS";
export const PURCHASE_BILL_DUMP_RECYCLING_STACK_FAIL = "PURCHASE_BILL_DUMP_RECYCLING_STACK_FAIL";

export const PURCHASE_BILL_DUMP_EXPRESS = "PURCHASE_BILL_DUMP_EXPRESS";
export const PURCHASE_BILL_DUMP_EXPRESS_SUCCESS = "PURCHASE_BILL_DUMP_EXPRESS_SUCCESS";
export const PURCHASE_BILL_DUMP_EXPRESS_FAIL = "PURCHASE_BILL_DUMP_EXPRESS_FAIL";

export const PURCHASE_BILL_DUMP_EXPRESS_STACK = "PURCHASE_BILL_DUMP_EXPRESS_STACK";
export const PURCHASE_BILL_DUMP_EXPRESS_STACK_SUCCESS = "PURCHASE_BILL_DUMP_EXPRESS_STACK_SUCCESS";
export const PURCHASE_BILL_DUMP_EXPRESS_STACK_FAIL = "PURCHASE_BILL_DUMP_EXPRESS_STACK_FAIL";

export const PURCHASE_BILL_STATEMENT = "PURCHASE_BILL_STATEMENT";
export const PURCHASE_BILL_STATEMENT_SUCCESS = "PURCHASE_BILL_STATEMENT_SUCCESS";
export const PURCHASE_BILL_STATEMENT_FAIL = "PURCHASE_BILL_STATEMENT_FAIL";


export const STORE_RECEIPT_LIST = "STORE_RECEIPT_LIST";
export const STORE_RECEIPT_LIST_SUCCESS = "STORE_RECEIPT_LIST_SUCCESS";
export const STORE_RECEIPT_LIST_FAIL = "STORE_RECEIPT_LIST_FAIL";

export const GET_STORE_RECEIPT_DUMP_EXPRESS_STACK = "GET_STORE_RECEIPT_DUMP_EXPRESS_STACK";
export const GET_STORE_RECEIPT_DUMP_EXPRESS_STACK_SUCCESS = "GET_STORE_RECEIPT_DUMP_EXPRESS_STACK_SUCCESS";
export const GET_STORE_RECEIPT_DUMP_EXPRESS_STACK_FAIL = "GET_STORE_RECEIPT_DUMP_EXPRESS_STACK_FAIL";

export const GET_STORE_RECEIPT_DUMP_RECYCLING_STACK = "GET_STORE_RECEIPT_DUMP_RECYCLING_STACK";
export const GET_STORE_RECEIPT_DUMP_RECYCLING_STACK_SUCCESS = "GET_STORE_RECEIPT_DUMP_RECYCLING_STACK_SUCCESS";
export const GET_STORE_RECEIPT_DUMP_RECYCLING_STACK_FAIL = "GET_STORE_RECEIPT_DUMP_RECYCLING_STACK_FAIL";

export const WASTE_RECEIPT_LIST = "WASTE_RECEIPT_LIST";
export const WASTE_RECEIPT_LIST_SUCCESS = "WASTE_RECEIPT_LIST_SUCCESS";
export const WASTE_RECEIPT_LIST_FAIL = "WASTE_RECEIPT_LIST_FAIL";

export const GET_WASTE_RECEIPT_STACK = "GET_WASTE_RECEIPT_STACK";
export const GET_WASTE_RECEIPT_STACK_SUCCESS = "GET_WASTE_RECEIPT_STACK_SUCCESS";
export const GET_WASTE_RECEIPT_STACK_FAIL = "GET_WASTE_RECEIPT_STACK_FAIL";

export const RECYCLABLE_SALES_INVOICE_LIST = "RECYCLABLE_SALES_INVOICE_LIST";
export const RECYCLABLE_SALES_INVOICE_LIST_SUCCESS = "RECYCLABLE_SALES_INVOICE_LIST_SUCCESS";
export const RECYCLABLE_SALES_INVOICE_LIST_FAIL = "RECYCLABLE_SALES_INVOICE_LIST_FAIL";

export const RECYCLABLE_SALES_INVOICE = "RECYCLABLE_SALES_INVOICE";
export const RECYCLABLE_SALES_INVOICE_SUCCESS = "RECYCLABLE_SALES_INVOICE_SUCCESS";
export const RECYCLABLE_SALES_INVOICE_FAIL = "RECYCLABLE_SALES_INVOICE_FAIL";

export const RECYCLABLE_SALES_INVOICE_STACK = "RECYCLABLE_SALES_INVOICE_STACK";
export const RECYCLABLE_SALES_INVOICE_STACK_SUCCESS = "RECYCLABLE_SALES_INVOICE_STACK_SUCCESS";
export const RECYCLABLE_SALES_INVOICE_STACK_FAIL = "RECYCLABLE_SALES_INVOICE_STACK_FAIL";

export const BOOKING_FEE_LIST = "BOOKING_FEE_LIST";
export const BOOKING_FEE_LIST_SUCCESS = "BOOKING_FEE_LIST_SUCCESS";
export const BOOKING_FEE_LIST_FAIL = "BOOKING_FEE_LIST_FAIL";

export const PROCESS_FEE_LIST = "PROCESS_FEE_LIST";
export const PROCESS_FEE_LIST_SUCCESS = "PROCESS_FEE_LIST_SUCCESS";
export const PROCESS_FEE_LIST_FAIL = "PROCESS_FEE_LIST_FAIL";

export const LANDFILL_FEE_LIST = "LANDFILL_FEE_LIST";
export const LANDFILL_FEE_LIST_SUCCESS = "LANDFILL_FEE_LIST_SUCCESS";
export const LANDFILL_FEE_LIST_FAIL = "LANDFILL_FEE_LIST_FAIL";

export const USER_LOADING_FEE_LIST = "USER_LOADING_FEE_LIST";
export const USER_LOADING_FEE_LIST_SUCCESS = "USER_LOADING_FEE_LIST_SUCCESS";
export const USER_LOADING_FEE_LIST_FAIL = "USER_LOADING_FEE_LIST_FAIL";

export const DRIVER_LOADING_PAYMENT_LIST = "DRIVER_LOADING_PAYMENT_LIST";
export const DRIVER_LOADING_PAYMENT_LIST_SUCCESS = "DRIVER_LOADING_PAYMENT_LIST_SUCCESS";
export const DRIVER_LOADING_PAYMENT_LIST_FAIL = "DRIVER_LOADING_PAYMENT_LIST_FAIL";

export const SORTING_FEE_LIST = "SORTING_FEE_LIST";
export const SORTING_FEE_LIST_SUCCESS = "SORTING_FEE_LIST_SUCCESS";
export const SORTING_FEE_LIST_FAIL = "SORTING_FEE_LIST_FAIL";

export const OVERSIZE_FEE_LIST = "OVERSIZE_FEE_LIST";
export const OVERSIZE_FEE_LIST_SUCCESS = "OVERSIZE_FEE_LIST_SUCCESS";
export const OVERSIZE_FEE_LIST_FAIL = "OVERSIZE_FEE_LIST_FAIL";

export const RECYCLABLE_PURCHASE_SUMMARY_LIST = "RECYCLABLE_PURCHASE_SUMMARY_LIST";
export const RECYCLABLE_PURCHASE_SUMMARY_LIST_SUCCESS = "RECYCLABLE_PURCHASE_SUMMARY_LIST_SUCCESS";
export const RECYCLABLE_PURCHASE_SUMMARY_LIST_FAIL = "RECYCLABLE_PURCHASE_SUMMARY_LIST_FAIL";


export const COLLECTION_REQUEST_COUNT = "COLLECTION_REQUEST_COUNT";
export const COLLECTION_REQUEST_COUNT_SUCCESS = "COLLECTION_REQUEST_COUNT_SUCCESS";
export const COLLECTION_REQUEST_COUNT_FAIL = "COLLECTION_REQUEST_COUNT_FAIL";

export const COLLECTION_REQUEST_LOAD = "COLLECTION_REQUEST_LOAD";
export const COLLECTION_REQUEST_LOAD_SUCCESS = "COLLECTION_REQUEST_LOAD_SUCCESS";
export const COLLECTION_REQUEST_LOAD_FAIL = "COLLECTION_REQUEST_LOAD_FAIL";

export const COLLECTION_REQUEST_APPROVE = "COLLECTION_REQUEST_APPROVE";
export const COLLECTION_REQUEST_APPROVE_SUCCESS = "COLLECTION_REQUEST_APPROVE_SUCCESS";
export const COLLECTION_REQUEST_APPROVE_FAIL = "COLLECTION_REQUEST_APPROVE_FAIL";

export const COLLECTION_REQUEST_REJECT = "COLLECTION_REQUEST_REJECT";
export const COLLECTION_REQUEST_REJECT_SUCCESS = "COLLECTION_REQUEST_REJECT_SUCCESS";
export const COLLECTION_REQUEST_REJECT_FAIL = "COLLECTION_REQUEST_REJECT_FAIL";

export const FIND_DRIVER_SIMULATE = "FIND_DRIVER_SIMULATE";
export const FIND_DRIVER_SIMULATE_SUCCESS = "FIND_DRIVER_SIMULATE_SUCCESS";
export const FIND_DRIVER_SIMULATE_FAIL = "FIND_DRIVER_SIMULATE_FAIL";


export const FILLING_EXTENSION_REQUEST_COUNT = "FILLING_EXTENSION_REQUEST_COUNT";
export const FILLING_EXTENSION_REQUEST_COUNT_SUCCESS = "FILLING_EXTENSION_REQUEST_COUNT_SUCCESS";
export const FILLING_EXTENSION_REQUEST_COUNT_FAIL = "FILLING_EXTENSION_REQUEST_COUNT_FAIL";

export const FILLING_EXTENSION_REQUEST_LOAD = "FILLING_EXTENSION_REQUEST_LOAD";
export const FILLING_EXTENSION_REQUEST_LOAD_SUCCESS = "FILLING_EXTENSION_REQUEST_LOAD_SUCCESS";
export const FILLING_EXTENSION_REQUEST_LOAD_FAIL = "FILLING_EXTENSION_REQUEST_LOAD_FAIL";

export const FILLING_EXTENSION_REQUEST_DETAIL = "FILLING_EXTENSION_REQUEST_DETAIL";
export const FILLING_EXTENSION_REQUEST_DETAIL_SUCCESS = "FILLING_EXTENSION_REQUEST_DETAIL_SUCCESS";
export const FILLING_EXTENSION_REQUEST_DETAIL_FAIL = "FILLING_EXTENSION_REQUEST_DETAIL_FAIL";

export const FILLING_EXTENSION_REQUEST_APPROVE = "FILLING_EXTENSION_REQUEST_APPROVE";
export const FILLING_EXTENSION_REQUEST_APPROVE_SUCCESS = "FILLING_EXTENSION_REQUEST_APPROVE_SUCCESS";
export const FILLING_EXTENSION_REQUEST_APPROVE_FAIL = "FILLING_EXTENSION_REQUEST_APPROVE_FAIL";

export const FILLING_EXTENSION_REQUEST_REJECT = "FILLING_EXTENSION_REQUEST_REJECT";
export const FILLING_EXTENSION_REQUEST_REJECT_SUCCESS = "FILLING_EXTENSION_REQUEST_REJECT_SUCCESS";
export const FILLING_EXTENSION_REQUEST_REJECT_FAIL = "FILLING_EXTENSION_REQUEST_REJECT_FAIL";



export const MADE_IT_TODAY_REQUEST_COUNT = "MADE_IT_TODAY_REQUEST_COUNT";
export const MADE_IT_TODAY_REQUEST_COUNT_SUCCESS = "MADE_IT_TODAY_REQUEST_COUNT_SUCCESS";
export const MADE_IT_TODAY_REQUEST_COUNT_FAIL = "MADE_IT_TODAY_REQUEST_COUNT_FAIL";

export const MADE_IT_TODAY_REQUEST_LOAD = "MADE_IT_TODAY_REQUEST_LOAD";
export const MADE_IT_TODAY_REQUEST_LOAD_SUCCESS = "MADE_IT_TODAY_REQUEST_LOAD_SUCCESS";
export const MADE_IT_TODAY_REQUEST_LOAD_FAIL = "MADE_IT_TODAY_REQUEST_LOAD_FAIL";

export const MADE_IT_TODAY_REQUEST_DETAIL = "MADE_IT_TODAY_REQUEST_DETAIL";
export const MADE_IT_TODAY_REQUEST_DETAIL_SUCCESS = "MADE_IT_TODAY_REQUEST_DETAIL_SUCCESS";
export const MADE_IT_TODAY_REQUEST_DETAIL_FAIL = "MADE_IT_TODAY_REQUEST_DETAIL_FAIL";

export const MADE_IT_TODAY_REQUEST_APPROVE = "MADE_IT_TODAY_REQUEST_APPROVE";
export const MADE_IT_TODAY_REQUEST_APPROVE_SUCCESS = "MADE_IT_TODAY_REQUEST_APPROVE_SUCCESS";
export const MADE_IT_TODAY_REQUEST_APPROVE_FAIL = "MADE_IT_TODAY_REQUEST_APPROVE_FAIL";

export const MADE_IT_TODAY_REQUEST_REJECT = "MADE_IT_TODAY_REQUEST_REJECT";
export const MADE_IT_TODAY_REQUEST_REJECT_SUCCESS = "MADE_IT_TODAY_REQUEST_REJECT_SUCCESS";
export const MADE_IT_TODAY_REQUEST_REJECT_FAIL = "MADE_IT_TODAY_REQUEST_REJECT_FAIL";


export const ADD_ON_TRIP_REQUEST_COUNT = "ADD_ON_TRIP_REQUEST_COUNT";
export const ADD_ON_TRIP_REQUEST_COUNT_SUCCESS = "ADD_ON_TRIP_REQUEST_COUNT_SUCCESS";
export const ADD_ON_TRIP_REQUEST_COUNT_FAIL = "ADD_ON_TRIP_REQUEST_COUNT_FAIL";

export const ADD_ON_TRIP_REQUEST_LOAD = "ADD_ON_TRIP_REQUEST_LOAD";
export const ADD_ON_TRIP_REQUEST_LOAD_SUCCESS = "ADD_ON_TRIP_REQUEST_LOAD_SUCCESS";
export const ADD_ON_TRIP_REQUEST_LOAD_FAIL = "ADD_ON_TRIP_REQUEST_LOAD_FAIL";

export const ADD_ON_TRIP_REQUEST_DETAIL = "ADD_ON_TRIP_REQUEST_DETAIL";
export const ADD_ON_TRIP_REQUEST_DETAIL_SUCCESS = "ADD_ON_TRIP_REQUEST_DETAIL_SUCCESS";
export const ADD_ON_TRIP_REQUEST_DETAIL_FAIL = "ADD_ON_TRIP_REQUEST_DETAIL_FAIL";

export const ADD_ON_TRIP_REQUEST_APPROVE = "ADD_ON_TRIP_REQUEST_APPROVE";
export const ADD_ON_TRIP_REQUEST_APPROVE_SUCCESS = "ADD_ON_TRIP_REQUEST_APPROVE_SUCCESS";
export const ADD_ON_TRIP_REQUEST_APPROVE_FAIL = "ADD_ON_TRIP_REQUEST_APPROVE_FAIL";

export const ADD_ON_TRIP_REQUEST_REJECT = "ADD_ON_TRIP_REQUEST_REJECT";
export const ADD_ON_TRIP_REQUEST_REJECT_SUCCESS = "ADD_ON_TRIP_REQUEST_REJECT_SUCCESS";
export const ADD_ON_TRIP_REQUEST_REJECT_FAIL = "ADD_ON_TRIP_REQUEST_REJECT_FAIL";

export const MPRM_PROCESS_LIST = "MPRM_PROCESS_LIST";
export const MPRM_PROCESS_LIST_SUCCESS = "MPRM_PROCESS_LIST_SUCCESS";
export const MPRM_PROCESS_LIST_FAIL = "MPRM_PROCESS_LIST_FAIL";

export const MPRM_PROCESS_DETAIL = "MPRM_PROCESS_DETAIL";
export const MPRM_PROCESS_DETAIL_SUCCESS = "MPRM_PROCESS_DETAIL_SUCCESS";
export const MPRM_PROCESS_DETAIL_FAIL = "MPRM_PROCESS_DETAIL_FAIL";

export const MPRM_PROCESS_RETRY = "MPRM_PROCESS_RETRY";
export const MPRM_PROCESS_RETRY_SUCCESS = "MPRM_PROCESS_RETRY_SUCCESS";
export const MPRM_PROCESS_RETRY_FAIL = "MPRM_PROCESS_RETRY_FAIL";

export const MPRM_PROCESS_RESET = "MPRM_PROCESS_RESET";
export const MPRM_PROCESS_RESET_SUCCESS = "MPRM_PROCESS_RESET_SUCCESS";
export const MPRM_PROCESS_RESET_FAIL = "MPRM_PROCESS_RESET_FAIL";
/* ===== END  ===== */

/* ===== EXPRESS ===== */

export const LOAD_EXPRESS_ORDER_COUNT = "LOAD_EXPRESS_ORDER_COUNT";
export const LOAD_EXPRESS_ORDER_COUNT_SUCCESS = "LOAD_EXPRESS_ORDER_COUNT_SUCCESS";
export const LOAD_EXPRESS_ORDER_COUNT_FAIL = "LOAD_EXPRESS_ORDER_COUNT_FAIL";

export const LOAD_EXPRESS_ORDER_LIST = "LOAD_EXPRESS_ORDER_LIST";
export const LOAD_EXPRESS_ORDER_LIST_SUCCESS = "LOAD_EXPRESS_ORDER_LIST_SUCCESS";
export const LOAD_EXPRESS_ORDER_LIST_FAIL = "LOAD_EXPRESS_ORDER_LIST_FAIL";

export const LOAD_EXPRESS_ORDER_DETAIL = "LOAD_EXPRESS_ORDER_DETAIL";
export const LOAD_EXPRESS_ORDER_DETAIL_SUCCESS = "LOAD_EXPRESS_ORDER_DETAIL_SUCCESS";
export const LOAD_EXPRESS_ORDER_DETAIL_FAIL = "LOAD_EXPRESS_ORDER_DETAIL_FAIL";

export const CANCEL_EXPRESS_ORDER = "CANCEL_EXPRESS_ORDER";
export const CANCEL_EXPRESS_ORDER_SUCCESS = "CANCEL_EXPRESS_ORDER_SUCCESS";
export const CANCEL_EXPRESS_ORDER_FAIL = "CANCEL_EXPRESS_ORDER_FAIL";

export const EXPRESS_STORE_RECEIPT = "EXPRESS_STORE_RECEIPT";
export const EXPRESS_STORE_RECEIPT_SUCCESS = "EXPRESS_STORE_RECEIPT_SUCCESS";
export const EXPRESS_STORE_RECEIPT_FAIL = "EXPRESS_STORE_RECEIPT_FAIL";

export const LOAD_EXPRESS_SUMMARY = "LOAD_EXPRESS_SUMMARY";
export const LOAD_EXPRESS_SUMMARY_SUCCESS = "LOAD_EXPRESS_SUMMARY_SUCCESS";
export const LOAD_EXPRESS_SUMMARY_FAIL = "LOAD_EXPRESS_SUMMARY_FAIL";

export const LOAD_EXPRESS_SUMMARY_CHART = "LOAD_EXPRESS_SUMMARY_CHART";
export const LOAD_EXPRESS_SUMMARY_CHART_SUCCESS = "LOAD_EXPRESS_SUMMARY_CHART_SUCCESS";
export const LOAD_EXPRESS_SUMMARY_CHART_FAIL = "LOAD_EXPRESS_SUMMARY_CHART_FAIL";

export const LOAD_EXPRESS_SALE_SUMMARY = "LOAD_EXPRESS_SALE_SUMMARY";
export const LOAD_EXPRESS_SALE_SUMMARY_SUCCESS = "LOAD_EXPRESS_SALE_SUMMARY_SUCCESS";
export const LOAD_EXPRESS_SALE_SUMMARY_FAIL = "LOAD_EXPRESS_SALE_SUMMARY_FAIL";

export const LOAD_GRADER_DAILY_SETTLEMENT = "LOAD_GRADER_DAILY_SETTLEMENT";
export const LOAD_GRADER_DAILY_SETTLEMENT_SUCCESS = "LOAD_GRADER_DAILY_SETTLEMENT_SUCCESS";
export const LOAD_GRADER_DAILY_SETTLEMENT_FAIL = "LOAD_GRADER_DAILY_SETTLEMENT_FAIL";

/* ===== END EXPRESS ===== */


/* =====REPORT ===== */
export const MONTHLY_WASTE_SUMMARY = "MONTHLY_WASTE_SUMMARY";
export const MONTHLY_WASTE_SUMMARY_SUCCESS = "MONTHLY_WASTE_SUMMARY_SUCCESS";
export const MONTHLY_WASTE_SUMMARY_FAIL = "MONTHLY_WASTE_SUMMARY_FAIL";

export const MONTHLY_WASTE_SUMMARY_LOAD = "MONTHLY_WASTE_SUMMARY_LOAD";
export const MONTHLY_WASTE_SUMMARY_LOAD_SUCCESS = "MONTHLY_WASTE_SUMMARY_LOAD_SUCCESS";
export const MONTHLY_WASTE_SUMMARY_LOAD_FAIL = "MONTHLY_WASTE_SUMMARY_LOAD_FAIL";


export const MONTHLY_LANDFILL_SUMMARY = "MONTHLY_LANDFILL_SUMMARY";
export const MONTHLY_LANDFILL_SUMMARY_SUCCESS = "MONTHLY_LANDFILL_SUMMARY_SUCCESS";
export const MONTHLY_LANDFILL_SUMMARY_FAIL = "MONTHLY_LANDFILL_SUMMARY_FAIL";

export const MONTHLY_LANDFILL_SUMMARY_LOAD = "MONTHLY_LANDFILL_SUMMARY_LOAD";
export const MONTHLY_LANDFILL_SUMMARY_LOAD_SUCCESS = "MONTHLY_LANDFILL_SUMMARY_LOAD_SUCCESS";
export const MONTHLY_LANDFILL_SUMMARY_LOAD_FAIL = "MONTHLY_LANDFILL_SUMMARY_LOAD_FAIL";

export const MONTHLY_STOCK_SUMMARY = "MONTHLY_STOCK_SUMMARY";
export const MONTHLY_STOCK_SUMMARY_SUCCESS = "MONTHLY_STOCK_SUMMARY_SUCCESS";
export const MONTHLY_STOCK_SUMMARY_FAIL = "MONTHLY_STOCK_SUMMARY_FAIL";

export const MONTHLY_STOCK_SUMMARY_LOAD = "MONTHLY_STOCK_SUMMARY_LOAD";
export const MONTHLY_STOCK_SUMMARY_LOAD_SUCCESS = "MONTHLY_STOCK_SUMMARY_LOAD_SUCCESS";
export const MONTHLY_STOCK_SUMMARY_LOAD_FAIL = "MONTHLY_STOCK_SUMMARY_LOAD_FAIL";

export const MONTHLY_WASTE_STOCK_SUMMARY = "MONTHLY_WASTE_STOCK_SUMMARY";
export const MONTHLY_WASTE_STOCK_SUMMARY_SUCCESS = "MONTHLY_WASTE_STOCK_SUMMARY_SUCCESS";
export const MONTHLY_WASTE_STOCK_SUMMARY_FAIL = "MONTHLY_WASTE_STOCK_SUMMARY_FAIL";

export const MONTHLY_WASTE_STOCK_SUMMARY_LOAD = "MONTHLY_WASTE_STOCK_SUMMARY_LOAD";
export const MONTHLY_WASTE_STOCK_SUMMARY_LOAD_SUCCESS = "MONTHLY_WASTE_STOCK_SUMMARY_LOAD_SUCCESS";
export const MONTHLY_WASTE_STOCK_SUMMARY_LOAD_FAIL = "MONTHLY_WASTE_STOCK_SUMMARY_LOAD_FAIL";

export const MONTHLY_DRIVER_TRIP_SUMMARY = "MONTHLY_DRIVER_TRIP_SUMMARY";
export const MONTHLY_DRIVER_TRIP_SUMMARY_SUCCESS = "MONTHLY_DRIVER_TRIP_SUMMARY_SUCCESS";
export const MONTHLY_DRIVER_TRIP_SUMMARY_FAIL = "MONTHLY_DRIVER_TRIP_SUMMARY_FAIL";

export const RESET_DRIVER_TRIP_SUMMARY = "RESET_DRIVER_TRIP_SUMMARY";
export const RESET_DRIVER_TRIP_SUMMARY_SUCCESS = "RESET_DRIVER_TRIP_SUMMARY_SUCCESS";

export const ORDER_DISTANCE_SUMMARY = "ORDER_DISTANCE_SUMMARY";
export const ORDER_DISTANCE_SUMMARY_SUCCESS = "ORDER_DISTANCE_SUMMARY_SUCCESS";
export const ORDER_DISTANCE_SUMMARY_FAIL = "ORDER_DISTANCE_SUMMARY_FAIL";

/* ===== END  ===== */

/* ===== DRIVER REIMBURSE CUT OFF===== */
export const DRIVER_CUT_OFF_LOAD = "DRIVER_CUT_OFF_LOAD";
export const DRIVER_CUT_OFF_LOAD_SUCCESS = "DRIVER_CUT_OFF_LOAD_SUCCESS";
export const DRIVER_CUT_OFF_LOAD_FAIL = "DRIVER_CUT_OFF_LOAD_FAIL";

export const DRIVER_CUT_OFF_DETAIL = "DRIVER_CUT_OFF_DETAIL";
export const DRIVER_CUT_OFF_DETAIL_SUCCESS = "DRIVER_CUT_OFF_DETAIL_SUCCESS";
export const DRIVER_CUT_OFF_DETAIL_FAIL = "DRIVER_CUT_OFF_DETAIL_FAIL";

export const GET_REIMBURSE_REPORT = "GET_REIMBURSE_REPORT";
export const GET_REIMBURSE_REPORT_SUCCESS = "GET_REIMBURSE_REPORT_SUCCESS";
export const GET_REIMBURSE_REPORT_FAIL = "GET_REIMBURSE_REPORT_FAIL";

export const DRIVER_CUT_OFF_APPROVE = "DRIVER_CUT_OFF_APPROVE";
export const DRIVER_CUT_OFF_APPROVE_SUCCESS = "DRIVER_CUT_OFF_APPROVE_SUCCESS";
export const DRIVER_CUT_OFF_APPROVE_FAIL = "DRIVER_CUT_OFF_APPROVE_FAIL";

export const DRIVER_CUT_OFF_COUNT = "DRIVER_CUT_OFF_COUNT";
export const DRIVER_CUT_OFF_COUNT_SUCCESS = "DRIVER_CUT_OFF_COUNT_SUCCESS";
export const DRIVER_CUT_OFF_COUNT_FAIL = "DRIVER_CUT_OFF_COUNT_FAIL";

export const DRIVER_CUT_OFF_UPDATE_REIMBURSE = "DRIVER_CUT_OFF_UPDATE_REIMBURSE";
export const DRIVER_CUT_OFF_UPDATE_REIMBURSE_SUCCESS = "DRIVER_CUT_OFF_UPDATE_REIMBURSE_SUCCESS";
export const DRIVER_CUT_OFF_UPDATE_REIMBURSE_FAIL = "DRIVER_CUT_OFF_UPDATE_REIMBURSE_FAIL";

/* ===== END  ===== */

/* ===== WITHDRAWAL REQUEST ===== */
export const WITHDRAW_REQUEST_LOAD = "WITHDRAW_REQUEST_LOAD";
export const WITHDRAW_REQUEST_LOAD_SUCCESS = "WITHDRAW_REQUEST_LOAD_SUCCESS";
export const WITHDRAW_REQUEST_LOAD_FAIL = "WITHDRAW_REQUEST_LOAD_FAIL";

export const WITHDRAW_REQUEST_DETAIL = "WITHDRAW_REQUEST_DETAIL";
export const WITHDRAW_REQUEST_DETAIL_SUCCESS = "WITHDRAW_REQUEST_DETAIL_SUCCESS";
export const WITHDRAW_REQUEST_DETAIL_FAIL = "WITHDRAW_REQUEST_DETAIL_FAIL";

export const WITHDRAW_REQUEST_UPDATE_STATUS = "WITHDRAW_REQUEST_UPDATE_STATUS";
export const WITHDRAW_REQUEST_UPDATE_STATUS_SUCCESS = "WITHDRAW_REQUEST_UPDATE_STATUS_SUCCESS";
export const WITHDRAW_REQUEST_UPDATE_STATUS_FAIL = "WITHDRAW_REQUEST_UPDATE_STATUS_FAIL";

export const WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE = "WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE";
export const WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE_SUCCESS = "WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE_SUCCESS";
export const WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE_FAIL = "WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE_FAIL";

export const WITHDRAW_REQUEST_COUNT = "WITHDRAW_REQUEST_COUNT";
export const WITHDRAW_REQUEST_COUNT_SUCCESS = "WITHDRAW_REQUEST_COUNT_SUCCESS";
export const WITHDRAW_REQUEST_COUNT_FAIL = "WITHDRAW_REQUEST_COUNT_FAIL";

export const WITHDRAW_REQUEST_UPDATE_TO_PROCESSING = "WITHDRAW_REQUEST_UPDATE_TO_PROCESSING";
export const WITHDRAW_REQUEST_UPDATE_TO_PROCESSING_SUCCESS = "WITHDRAW_REQUEST_UPDATE_TO_PROCESSING_SUCCESS";
export const WITHDRAW_REQUEST_UPDATE_TO_PROCESSING_FAIL = "WITHDRAW_REQUEST_UPDATE_TO_PROCESSING_FAIL";

export const WITHDRAW_REQUEST_RESPOND_BATCH_FILE = "WITHDRAW_REQUEST_RESPOND_BATCH_FILE";
export const WITHDRAW_REQUEST_RESPOND_BATCH_FILE_SUCCESS = "WITHDRAW_REQUEST_RESPOND_BATCH_FILE_SUCCESS";
export const WITHDRAW_REQUEST_RESPOND_BATCH_FILE_FAIL = "WITHDRAW_REQUEST_RESPOND_BATCH_FILE_FAIL";



export const GET_BATCH_FILE = "GET_BATCH_FILE";
export const GET_BATCH_FILE_SUCCESS = "GET_BATCH_FILE_SUCCESS";
export const GET_BATCH_FILE_FAIL = "GET_BATCH_FILE_FAIL";

export const GET_BANK_RESPONSE_FILE = "GET_BANK_RESPONSE_FILE";
export const GET_BANK_RESPONSE_FILE_SUCCESS = "GET_BANK_RESPONSE_FILE_SUCCESS";
export const GET_BANK_RESPONSE_FILE_FAIL = "GET_BANK_RESPONSE_FILE_FAIL";

/* ===== END  ===== */


/* ===== GRADING  ===== */
export const LOAD_GRADING_LIST = "LOAD_GRADING_LIST";
export const LOAD_GRADING_LIST_SUCCESS = "LOAD_GRADING_LIST_SUCCESS";
export const LOAD_GRADING_LIST_FAIL = "LOAD_GRADING_LIST_FAIL";

export const LOAD_GRADING_COUNT = "LOAD_GRADING_COUNT";
export const LOAD_GRADING_COUNT_SUCCESS = "LOAD_GRADING_COUNT_SUCCESS";
export const LOAD_GRADING_COUNT_FAIL = "LOAD_GRADING_COUNT_FAIL";

export const LOAD_PENDING_GRADING_LIST = "LOAD_PENDING_GRADING_LIST";
export const LOAD_PENDING_GRADING_LIST_SUCCESS = "LOAD_PENDING_GRADING_LIST_SUCCESS";
export const LOAD_PENDING_GRADING_LIST_FAIL = "LOAD_PENDING_GRADING_LIST_FAIL";

export const LOAD_PENDING_GRADING_COUNT = "LOAD_PENDING_GRADING_COUNT";
export const LOAD_PENDING_GRADING_COUNT_SUCCESS = "LOAD_PENDING_GRADING_COUNT_SUCCESS";
export const LOAD_PENDING_GRADING_COUNT_FAIL = "LOAD_PENDING_GRADING_COUNT_FAIL";

export const LOAD_PENDING_WASTE_GRADING_LIST = "LOAD_PENDING_WASTE_GRADING_LIST";
export const LOAD_PENDING_WASTE_GRADING_LIST_SUCCESS = "LOAD_PENDING_WASTE_GRADING_LIST_SUCCESS";
export const LOAD_PENDING_WASTE_GRADING_LIST_FAIL = "LOAD_PENDING_WASTE_GRADING_LIST_FAIL";

export const LOAD_PENDING_WASTE_GRADING_COUNT = "LOAD_PENDING_WASTE_GRADING_COUNT";
export const LOAD_PENDING_WASTE_GRADING_COUNT_SUCCESS = "LOAD_PENDING_WASTE_GRADING_COUNT_SUCCESS";
export const LOAD_PENDING_WASTE_GRADING_COUNT_FAIL = "LOAD_PENDING_WASTE_GRADING_COUNT_FAIL";
/* ===== END GRADING ===== */

/* ===== VERIFICATION CODE  ===== */
export const LOAD_VERIFICATION_CODE = "LOAD_VERIFICATION_CODE";
export const LOAD_VERIFICATION_CODE_SUCCESS = "LOAD_VERIFICATION_CODE_SUCCESS";
export const LOAD_VERIFICATION_CODE_FAIL = "LOAD_VERIFICATION_CODE_FAIL";

/* ===== END  ===== */

/* ===== FEEDBACK  ===== */
export const LOAD_FEEDBACK_SUBJECT_LIST = "LOAD_FEEDBACK_SUBJECT_LIST";
export const LOAD_FEEDBACK_SUBJECT_LIST_SUCCESS = "LOAD_FEEDBACK_SUBJECT_LIST_SUCCESS";
export const LOAD_FEEDBACK_SUBJECT_LIST_FAIL = "LOAD_FEEDBACK_SUBJECT_LIST_FAIL";

export const LOAD_FEEDBACK_LIST = "LOAD_FEEDBACK_LIST";
export const LOAD_FEEDBACK_LIST_SUCCESS = "LOAD_FEEDBACK_LIST_SUCCESS";
export const LOAD_FEEDBACK_LIST_FAIL = "LOAD_FEEDBACK_LIST_FAIL";

export const LOAD_FEEDBACK_DETAIL = "LOAD_FEEDBACK_DETAIL";
export const LOAD_FEEDBACK_DETAIL_SUCCESS = "LOAD_FEEDBACK_DETAIL_SUCCESS";
export const LOAD_FEEDBACK_DETAIL_FAIL = "LOAD_FEEDBACK_DETAIL_FAIL";

export const GET_FEEDBACK_COUNT = "GET_FEEDBACK_COUNT";
export const GET_FEEDBACK_COUNT_SUCCESS = "GET_FEEDBACK_COUNT_SUCCESS";
export const GET_FEEDBACK_COUNT_FAIL = "GET_FEEDBACK_COUNT_FAIL";

export const RESOLVE_FEEDBACK = "RESOLVE_FEEDBACK";
export const RESOLVE_FEEDBACK_SUCCESS = "RESOLVE_FEEDBACK_SUCCESS";
export const RESOLVE_FEEDBACK_FAIL = "RESOLVE_FEEDBACK_FAIL";
/* ===== END FEEDBACK ===== */

/* ===== MERCHANT REGISTRATION  ===== */
export const LOAD_MERCHANT_REGISTRATION_LIST = "LOAD_MERCHANT_REGISTRATION_LIST";
export const LOAD_MERCHANT_REGISTRATION_LIST_SUCCESS = "LOAD_MERCHANT_REGISTRATION_LIST_SUCCESS";
export const LOAD_MERCHANT_REGISTRATION_LIST_FAIL = "LOAD_MERCHANT_REGISTRATION_LIST_FAIL";

export const LOAD_MERCHANT_REGISTRATION_DETAIL = "LOAD_MERCHANT_REGISTRATION_DETAIL";
export const LOAD_MERCHANT_REGISTRATION_DETAIL_SUCCESS = "LOAD_MERCHANT_REGISTRATION_DETAIL_SUCCESS";
export const LOAD_MERCHANT_REGISTRATION_DETAIL_FAIL = "LOAD_MERCHANT_REGISTRATION_DETAIL_FAIL";


export const GET_MERCHANT_REGISTRATION_COUNT = "GET_MERCHANT_REGISTRATION_COUNT";
export const GET_MERCHANT_REGISTRATION_COUNT_SUCCESS = "GET_MERCHANT_REGISTRATION_COUNT_SUCCESS";
export const GET_MERCHANT_REGISTRATION_COUNT_FAIL = "GET_MERCHANT_REGISTRATION_COUNT_FAIL";

export const VERIFY_MERCHANT_REGISTRATION = "VERIFY_MERCHANT_REGISTRATION";
export const VERIFY_MERCHANT_REGISTRATION_SUCCESS = "VERIFY_MERCHANT_REGISTRATION_SUCCESS";
export const VERIFY_MERCHANT_REGISTRATION_FAIL = "VERIFY_MERCHANT_REGISTRATION_FAIL";

export const REJECT_MERCHANT_REGISTRATION = "REJECT_MERCHANT_REGISTRATION";
export const REJECT_MERCHANT_REGISTRATION_SUCCESS = "REJECT_MERCHANT_REGISTRATION_SUCCESS";
export const REJECT_MERCHANT_REGISTRATION_FAIL = "REJECT_MERCHANT_REGISTRATION_FAIL";
/* ===== END MERCHANT REGISTRATION ===== */

/* ===== PROMOTION  ===== */
export const LOAD_PROMOTION_LIST = "LOAD_PROMOTION_LIST";
export const LOAD_PROMOTION_LIST_SUCCESS = "LOAD_PROMOTION_LIST_SUCCESS";
export const LOAD_PROMOTION_LIST_FAIL = "LOAD_PROMOTION_LIST_FAIL";

export const ADD_PROMOTION = "ADD_PROMOTION";
export const ADD_PROMOTION_SUCCESS = "ADD_PROMOTION_SUCCESS";
export const ADD_PROMOTION_FAIL = "ADD_PROMOTION_FAIL";

export const EDIT_PROMOTION = "EDIT_PROMOTION";
export const EDIT_PROMOTION_SUCCESS = "EDIT_PROMOTION_SUCCESS";
export const EDIT_PROMOTION_FAIL = "EDIT_PROMOTION_FAIL";

export const DELETE_PROMOTION = "DELETE_PROMOTION";
export const DELETE_PROMOTION_SUCCESS = "DELETE_PROMOTION_SUCCESS";
export const DELETE_PROMOTION_FAIL = "DELETE_PROMOTION_FAIL";
/* ===== END PROMOTION ===== */

/* ===== NOTIFICATION  ===== */
export const LOAD_NOTIFICATION_LIST = "LOAD_NOTIFICATION_LIST";
export const LOAD_NOTIFICATION_LIST_SUCCESS = "LOAD_NOTIFICATION_LIST_SUCCESS";
export const LOAD_NOTIFICATION_LIST_FAIL = "LOAD_NOTIFICATION_LIST_FAIL";

export const LOAD_NOTIFICATION_DETAIL = "LOAD_NOTIFICATION_DETAIL";
export const LOAD_NOTIFICATION_DETAIL_SUCCESS = "LOAD_NOTIFICATION_DETAIL_SUCCESS";
export const LOAD_NOTIFICATION_DETAIL_FAIL = "LOAD_NOTIFICATION_DETAIL_FAIL";

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const CREATE_NOTIFICATION_FAIL = "CREATE_NOTIFICATION_FAIL";

export const EDIT_NOTIFICATION = "EDIT_NOTIFICATION";
export const EDIT_NOTIFICATION_SUCCESS = "EDIT_NOTIFICATION_SUCCESS";
export const EDIT_NOTIFICATION_FAIL = "EDIT_NOTIFICATION_FAIL";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";
/* ===== END  ===== */

/* ===== WHATSAPP NOTIFICATION  ===== */
export const LOAD_WHATSAPP_NOTIFICATION_LIST = "LOAD_WHATSAPP_NOTIFICATION_LIST";
export const LOAD_WHATSAPP_NOTIFICATION_LIST_SUCCESS = "LOAD_WHATSAPP_NOTIFICATION_LIST_SUCCESS";
export const LOAD_WHATSAPP_NOTIFICATION_LIST_FAIL = "LOAD_WHATSAPP_NOTIFICATION_LIST_FAIL";

export const LOAD_WHATSAPP_NOTIFICATION_DETAIL = "LOAD_WHATSAPP_NOTIFICATION_DETAIL";
export const LOAD_WHATSAPP_NOTIFICATION_DETAIL_SUCCESS = "LOAD_WHATSAPP_NOTIFICATION_DETAIL_SUCCESS";
export const LOAD_WHATSAPP_NOTIFICATION_DETAIL_FAIL = "LOAD_WHATSAPP_NOTIFICATION_DETAIL_FAIL";

export const CREATE_WHATSAPP_NOTIFICATION = "CREATE_WHATSAPP_NOTIFICATION";
export const CREATE_WHATSAPP_NOTIFICATION_SUCCESS = "CREATE_WHATSAPP_NOTIFICATION_SUCCESS";
export const CREATE_WHATSAPP_NOTIFICATION_FAIL = "CREATE_WHATSAPP_NOTIFICATION_FAIL";

export const EDIT_WHATSAPP_NOTIFICATION = "EDIT_WHATSAPP_NOTIFICATION";
export const EDIT_WHATSAPP_NOTIFICATION_SUCCESS = "EDIT_WHATSAPP_NOTIFICATION_SUCCESS";
export const EDIT_WHATSAPP_NOTIFICATION_FAIL = "EDIT_WHATSAPP_NOTIFICATION_FAIL";

export const DELETE_WHATSAPP_NOTIFICATION = "DELETE_WHATSAPP_NOTIFICATION";
export const DELETE_WHATSAPP_NOTIFICATION_SUCCESS = "DELETE_WHATSAPP_NOTIFICATION_SUCCESS";
export const DELETE_WHATSAPP_NOTIFICATION_FAIL = "DELETE_WHATSAPP_NOTIFICATION_FAIL";

export const SEND_WHATSAPP_FOR_GRADING = "SEND_WHATSAPP_FOR_GRADING";
export const SEND_WHATSAPP_FOR_GRADING_SUCCESS = "SEND_WHATSAPP_FOR_GRADING_SUCCESS";
export const SEND_WHATSAPP_FOR_GRADING_FAIL = "SEND_WHATSAPP_FOR_GRADING_FAIL";
/* ===== END  ===== */


/* ===== GOOGLE PLACE ===== */
export const LOAD_GOOGLE_PLACE_AUTOCOMPLETE = "LOAD_GOOGLE_PLACE_AUTOCOMPLETE";
export const LOAD_GOOGLE_PLACE_AUTOCOMPLETE_SUCCESS = "LOAD_GOOGLE_PLACE_AUTOCOMPLETE_SUCCESS";
export const LOAD_GOOGLE_PLACE_AUTOCOMPLETE_FAIL = "LOAD_GOOGLE_PLACE_AUTOCOMPLETE_FAIL";
/* ===== END GOOGLE PLACE ===== */


/* ===== FIREBASE ===== */

export const UPDATE_PENDING_COUNT = "UPDATE_PENDING_COUNT";
export const UPDATE_PENDING_COUNT_SUCCESS = "UPDATE_PENDING_COUNT_SUCCESS";
export const UPDATE_PENDING_COUNT_FAIL = "UPDATE_PENDING_COUNT_FAIL";

export const UPDATE_LATEST_TIME = "UPDATE_LATEST_TIME";
export const UPDATE_LATEST_TIME_SUCCESS = "UPDATE_LATEST_TIME_SUCCESS";
export const UPDATE_LATEST_TIME_FAIL = "UPDATE_LATEST_TIME_FAIL";


export const UPDATE_ORDER_LOG = "UPDATE_ORDER_LOG";
export const UPDATE_ORDER_LOG_SUCCESS = "UPDATE_ORDER_LOG_SUCCESS";
export const UPDATE_ORDER_LOG_FAIL = "UPDATE_ORDER_LOG_FAIL";

export const UPDATE_NEW_ORDER_COUNT = "UPDATE_NEW_ORDER_COUNT";
export const UPDATE_NEW_ORDER_COUNT_SUCCESS = "UPDATE_NEW_ORDER_COUNT_SUCCESS";
export const UPDATE_NEW_ORDER_COUNT_FAIL = "UPDATE_NEW_ORDER_COUNT_FAIL";

export const UPDATE_NO_DRIVER_ACCEPT_COUNT = "UPDATE_NO_DRIVER_ACCEPT_COUNT";
export const UPDATE_NO_DRIVER_ACCEPT_COUNT_SUCCESS = "UPDATE_NO_DRIVER_ACCEPT_COUNT_SUCCESS";
export const UPDATE_NO_DRIVER_ACCEPT_COUNT_FAIL = "UPDATE_NO_DRIVER_ACCEPT_COUNT_FAIL";


export const UPDATE_TRIP_LOG = "UPDATE_TRIP_LOG";
export const UPDATE_TRIP_LOG_SUCCESS = "UPDATE_TRIP_LOG_SUCCESS";
export const UPDATE_TRIP_LOG_FAIL = "UPDATE_TRIP_LOG_FAIL";

export const UPDATE_UPCOMING_TRIP = "UPDATE_UPCOMING_TRIP";
export const UPDATE_UPCOMING_TRIP_SUCCESS = "UPDATE_UPCOMING_TRIP_SUCCESS";
export const UPDATE_UPCOMING_TRIP_FAIL = "UPDATE_UPCOMING_TRIP_FAIL";

export const UPDATE_DRIVER_NO_COMING_COUNT = "UPDATE_DRIVER_NO_COMING_COUNT";
export const UPDATE_DRIVER_NO_COMING_COUNT_SUCCESS = "UPDATE_DRIVER_NO_COMING_COUNT_SUCCESS";
export const UPDATE_DRIVER_NO_COMING_COUNT_FAIL = "UPDATE_DRIVER_NO_COMING_COUNT_FAIL";
/* ===== END FIREBASE ===== */

