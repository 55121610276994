import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  driverCutOffList: {
    data: [],
    driver: null,
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  driverCutOffCount: {},
  driverCutOffDetail: {},
  reimburseReport: null,
  isDriverCutOffApproveSuccess: false,
  isDriverCutOffReimburseUpdateSuccess: false,
  withdrawRequestList: {
    data: [],
    user: null,
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  withdrawRequestCount: {},
  withdrawRequestDetail: {},
  isWithdrawRequestStatusUpdateSuccess: false,
  isWithdrawRequestUpdateToProcessingSuccess: false,
  isWithdrawRequestResponseBatchFileSuccess: false,
  isWithdrawRequestGenerateBatchFileSuccess: false,
  walletTransaction: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  walletBalance: {},
  isLoadWalletBalDone: false,
  walletTransactionCount: {},
  walletAccountList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },  
  walletAccountCount: {},
  isUpdateWalletVerifyStatusSuccess: false,
  isUpdateWalletVerifyStatusFail: false,
  isWalletVerifyUploadDocSuccess: false,
  isWalletVerifyUploadDocFail: false,
  isUpdateWalletAllowCreditedSuccess: false,
  isUpdateWalletAllowCreditedFail: false,
  walletResetConfirmation: null,
  driverPendingPayment: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isApproveDriverPaymentSuccess: false,
};

export default function paymentReducer(state = initState, action) {

  switch (action.type) {
    case actionTypes.DRIVER_CUT_OFF_LOAD: {
      let driverCutOffList = cloneDeep(state.driverCutOffList);
      driverCutOffList.exportSuccess = false

      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        driverCutOffList.offset = action.data.offset;
        driverCutOffList.limit = action.data.limit;
      }

      driverCutOffList.driver = null
      return {
        ...state,
        driverCutOffList
      };
    }

    case actionTypes.DRIVER_CUT_OFF_LOAD_SUCCESS: {
      let driverCutOffList = cloneDeep(state.driverCutOffList);

      if(!action.data.data.export)
      {
        driverCutOffList.exportSuccess = false
        driverCutOffList.data = action.data.data;
        driverCutOffList.meta = {
          page: 1 + state.driverCutOffList.offset / state.driverCutOffList.limit,
          pageSize: state.driverCutOffList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.driverCutOffList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        driverCutOffList.exportSuccess = true
      }
      
      driverCutOffList.driver = action.data.driver
      return {
        ...state,
        driverCutOffList
      };
    }

    case actionTypes.DRIVER_CUT_OFF_LOAD_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.DRIVER_CUT_OFF_DETAIL: {
      
      return {
        ...state,
        driverCutOffDetail: {}
      };
    }

    case actionTypes.DRIVER_CUT_OFF_DETAIL_SUCCESS: {
    
      return {
        ...state,
        driverCutOffDetail:action.data
      };
    }

    case actionTypes.DRIVER_CUT_OFF_DETAIL_FAIL: {
      return {
        ...state
      };
    }

    
    
    case actionTypes.GET_REIMBURSE_REPORT: {
      
      return {
        ...state,
        reimburseReport: null
      };
    }

    case actionTypes.GET_REIMBURSE_REPORT_SUCCESS: {
    
      return {
        ...state,
        reimburseReport:action.data
      };
    }

    case actionTypes.GET_REIMBURSE_REPORT_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.DRIVER_CUT_OFF_APPROVE: {      
      return {
        ...state,
        isDriverCutOffApproveSuccess: false
      };
    }

    case actionTypes.DRIVER_CUT_OFF_APPROVE_SUCCESS: {
      return {
        ...state,
        isDriverCutOffApproveSuccess: true
      };
    }

    case actionTypes.DRIVER_CUT_OFF_APPROVE_FAIL: {
      return {
        ...state,
        isDriverCutOffApproveSuccess: false
      };
    }

    
    case actionTypes.DRIVER_CUT_OFF_COUNT: {
      
      return {
        ...state,
        driverCutOffCount: {}
      };
    }

    case actionTypes.DRIVER_CUT_OFF_COUNT_SUCCESS: {
    
      return {
        ...state,
        driverCutOffCount:action.data
      };
    }

    case actionTypes.DRIVER_CUT_OFF_COUNT_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.DRIVER_CUT_OFF_UPDATE_REIMBURSE: {      
      return {
        ...state,
        isDriverCutOffReimburseUpdateSuccess: false
      };
    }

    case actionTypes.DRIVER_CUT_OFF_UPDATE_REIMBURSE_SUCCESS: {
      return {
        ...state,
        isDriverCutOffReimburseUpdateSuccess: true
      };
    }

    case actionTypes.DRIVER_CUT_OFF_UPDATE_REIMBURSE_FAIL: {
      return {
        ...state,
        isDriverCutOffReimburseUpdateSuccess: false
      };
    }

    /* ===== USER WITHDRAWAL  ===== */
    case actionTypes.WITHDRAW_REQUEST_LOAD: {
      let withdrawRequestList = cloneDeep(state.withdrawRequestList);
      withdrawRequestList.exportSuccess = false

      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        withdrawRequestList.offset = action.data.offset;
        withdrawRequestList.limit = action.data.limit;
      }
      withdrawRequestList.user = null

      return {
        ...state,
        withdrawRequestList
      };
    }

    case actionTypes.WITHDRAW_REQUEST_LOAD_SUCCESS: {
      let withdrawRequestList = cloneDeep(state.withdrawRequestList);
      
      if(!action.data.data.export)
      {
        withdrawRequestList.exportSuccess = false
        withdrawRequestList.data = action.data.data;
        withdrawRequestList.meta = {
          page: 1 + state.withdrawRequestList.offset / state.withdrawRequestList.limit,
          pageSize: state.withdrawRequestList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.withdrawRequestList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        withdrawRequestList.exportSuccess = true
      }

      withdrawRequestList.user = action.data.user
      return {
        ...state,
        withdrawRequestList
      };
    }

    case actionTypes.WITHDRAW_REQUEST_LOAD_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.WITHDRAW_REQUEST_DETAIL: {
      
      return {
        ...state,
        withdrawRequestDetail: {}
      };
    }

    case actionTypes.WITHDRAW_REQUEST_DETAIL_SUCCESS: {
    
      return {
        ...state,
        withdrawRequestDetail:action.data
      };
    }

    case actionTypes.WITHDRAW_REQUEST_DETAIL_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.WITHDRAW_REQUEST_UPDATE_STATUS: {      
      return {
        ...state,
        isWithdrawRequestStatusUpdateSuccess: false
      };
    }

    case actionTypes.WITHDRAW_REQUEST_UPDATE_STATUS_SUCCESS: {
      return {
        ...state,
        isWithdrawRequestStatusUpdateSuccess: true
      };
    }

    case actionTypes.WITHDRAW_REQUEST_UPDATE_STATUS_FAIL: {
      return {
        ...state,
        isWithdrawRequestStatusUpdateSuccess: false
      };
    }

    
    case actionTypes.WITHDRAW_REQUEST_COUNT: {
      
      return {
        ...state,
        withdrawRequestCount: {}
      };
    }

    case actionTypes.WITHDRAW_REQUEST_COUNT_SUCCESS: {
    
      return {
        ...state,
        withdrawRequestCount:action.data
      };
    }

    case actionTypes.WITHDRAW_REQUEST_COUNT_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE: {      
      return {
        ...state,
        isWithdrawRequestGenerateBatchFileSuccess: false
      };
    }

    case actionTypes.WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE_SUCCESS: {
      return {
        ...state,
        isWithdrawRequestGenerateBatchFileSuccess: true
      };
    }

    case actionTypes.WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE_FAIL: {
      return {
        ...state,
        isWithdrawRequestGenerateBatchFileSuccess: false
      };
    }
    

    case actionTypes.WITHDRAW_REQUEST_UPDATE_TO_PROCESSING: {      
      return {
        ...state,
        isWithdrawRequestUpdateToProcessingSuccess: false
      };
    }

    case actionTypes.WITHDRAW_REQUEST_UPDATE_TO_PROCESSING_SUCCESS: {
      return {
        ...state,
        isWithdrawRequestUpdateToProcessingSuccess: true
      };
    }

    case actionTypes.WITHDRAW_REQUEST_UPDATE_TO_PROCESSING_FAIL: {
      return {
        ...state,
        isWithdrawRequestUpdateToProcessingSuccess: false
      };
    }
    

    case actionTypes.WITHDRAW_REQUEST_RESPOND_BATCH_FILE: {      
      return {
        ...state,
        isWithdrawRequestResponseBatchFileSuccess: false
      };
    }

    case actionTypes.WITHDRAW_REQUEST_RESPOND_BATCH_FILE_SUCCESS: {
      return {
        ...state,
        isWithdrawRequestResponseBatchFileSuccess: true
      };
    }

    case actionTypes.WITHDRAW_REQUEST_RESPOND_BATCH_FILE_FAIL: {
      return {
        ...state,
        isWithdrawRequestResponseBatchFileSuccess: false
      };
    }

    
    
    
    /* ===== WALLET TRANSACTION ===== */
    case actionTypes.WALLET_TRANSACTION_COUNT: {
      
      return {
        ...state,
        walletTransactionCount: {}
      };
    }

    case actionTypes.WALLET_TRANSACTION_COUNT_SUCCESS: {
    
      return {
        ...state,
        walletTransactionCount:action.data
      };
    }

    case actionTypes.WALLET_TRANSACTION_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_WALLET_BALANCE: {
      
      return {
        ...state,
        walletBalance: {},
        isLoadWalletBalDone: false,
      };
    }

    case actionTypes.LOAD_WALLET_BALANCE_SUCCESS: {
    
      return {
        ...state,
        walletBalance:action.data,
        isLoadWalletBalDone: true,
      };
    }

    case actionTypes.LOAD_WALLET_BALANCE_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_WALLET_TRANSACTION: {
      let walletTransaction = cloneDeep(state.walletTransaction);
      walletTransaction.exportSuccess = false

      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        walletTransaction.offset = action.data.offset;
        walletTransaction.limit = action.data.limit;
      }

      return {
        ...state,
        walletTransaction
      };
    }


    case actionTypes.LOAD_WALLET_TRANSACTION_SUCCESS: {
      let walletTransaction = cloneDeep(state.walletTransaction);

      if(!action.data.data.export)
      {
        walletTransaction.exportSuccess = false
        walletTransaction.data = action.data.data;
        walletTransaction.meta = {
          page: 1 + state.walletTransaction.offset / state.walletTransaction.limit,
          pageSize: state.walletTransaction.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.walletTransaction.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        walletTransaction.exportSuccess = true
      }

      return {
        ...state,
        walletTransaction
      };
    }

    case actionTypes.LOAD_WALLET_TRANSACTION_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_WALLET_ACCOUNT_LIST: {
      let walletAccountList = cloneDeep(state.walletAccountList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        walletAccountList.offset = action.data.offset;
        walletAccountList.limit = action.data.limit;
      }

      return {
        ...state,
        walletAccountList
      };
    }


    case actionTypes.LOAD_WALLET_ACCOUNT_LIST_SUCCESS: {
      let walletAccountList = cloneDeep(state.walletAccountList);
      walletAccountList.data = action.data.data;
      walletAccountList.meta = {
        page: 1 + state.walletAccountList.offset / state.walletAccountList.limit,
        pageSize: state.walletAccountList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.walletAccountList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        walletAccountList
      };
    }

    case actionTypes.LOAD_WALLET_ACCOUNT_LIST_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.LOAD_WALLET_ACCOUNT_COUNT: {
      
      return {
        ...state,
        walletAccountCount: {}
      };
    }

    case actionTypes.LOAD_WALLET_ACCOUNT_COUNT_SUCCESS: {
    
      return {
        ...state,
        walletAccountCount:action.data
      };
    }

    case actionTypes.LOAD_WALLET_ACCOUNT_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.UPDATE_WALLET_VERIFY_STATUS: {
      return {
        ...state,
        isUpdateWalletVerifyStatusSuccess: false,
        isUpdateWalletVerifyStatusFail: false
      };
    }

    case actionTypes.UPDATE_WALLET_VERIFY_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateWalletVerifyStatusSuccess: true
      };
    }

    case actionTypes.UPDATE_WALLET_VERIFY_STATUS_FAIL: {
      return {
        ...state,
        isUpdateWalletVerifyStatusFail: true
      };
    }

    case actionTypes.WALLET_VERIFY_UPLOAD_DOC: {
      return {
        ...state,
        isWalletVerifyUploadDocSuccess: false,
        isWalletVerifyUploadDocFail: false
      };
    }

    case actionTypes.WALLET_VERIFY_UPLOAD_DOC_SUCCESS: {
      return {
        ...state,
        isWalletVerifyUploadDocSuccess: true
      };
    }

    case actionTypes.WALLET_VERIFY_UPLOAD_DOC_FAIL: {
      return {
        ...state,
        isWalletVerifyUploadDocFail: true
      };
    }
    
    case actionTypes.UPDATE_WALLET_ALLOW_CREDITED: {
      return {
        ...state,
        isUpdateWalletAllowCreditedSuccess: false,
        isUpdateWalletAllowCreditedFail: false,
        walletResetConfirmation: null,
      };
    }

    case actionTypes.UPDATE_WALLET_ALLOW_CREDITED_SUCCESS: {
      return {
        ...state,
        isUpdateWalletAllowCreditedSuccess: (action.data) ? false : true,
        walletResetConfirmation: (action.data && action.data.reset_confirmed) ? action.data : null,
      };
    }

    case actionTypes.UPDATE_WALLET_ALLOW_CREDITED_FAIL: {
      return {
        ...state,
        isUpdateWalletAllowCreditedFail: true
      };
    }
    
    /* ===== END WALLET TRANSACTION ===== */


    /* ===== DRIVER PENDING PAYMENT ===== */

    case actionTypes.LOAD_DRIVER_PENDING_PAYMENT: {
      let driverPendingPayment = cloneDeep(state.driverPendingPayment);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        driverPendingPayment.offset = action.data.offset;
        driverPendingPayment.limit = action.data.limit;
      }

      return {
        ...state,
        driverPendingPayment
      };
    }


    case actionTypes.LOAD_DRIVER_PENDING_PAYMENT_SUCCESS: {
      let driverPendingPayment = cloneDeep(state.driverPendingPayment);
      driverPendingPayment.data = action.data.data;
      driverPendingPayment.meta = {
        page: 1 + state.driverPendingPayment.offset / state.driverPendingPayment.limit,
        pageSize: state.driverPendingPayment.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.driverPendingPayment.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        driverPendingPayment
      };
    }

    case actionTypes.LOAD_DRIVER_PENDING_PAYMENT_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.APPROVE_DRIVER_PAYMENT: {
      return {
        ...state,
        isApproveDriverPaymentSuccess: false
      };
    }

    case actionTypes.APPROVE_DRIVER_PAYMENT_SUCCESS: {
      return {
        ...state,
        isApproveDriverPaymentSuccess: true
      };
    }

    case actionTypes.APPROVE_DRIVER_PAYMENT_FAIL: {
      return {
        ...state,
        isApproveDriverPaymentSuccess: false
      };
    }
    /* ===== END DRIVER PENDING PAYMENT ===== */


    default:
      return state;
  }
}
